import React, {useState} from "react";
import {IPositionHistory} from "../interfaces/exportedInterfaces";
import '../css/PositionHistory.css'

interface ExtendableTableRowProps {
    item: IPositionHistory
}

export function ExtendableTableRow({item}: ExtendableTableRowProps) {

    const [isVisible, setIsVisible] = useState<boolean>(false);

    const toggleCollapse = () => {
        setIsVisible(prevState => !prevState);
    };

    const revisionType = () => {
        if (item.revisionType === 'MOD') {
            return 'Изменение'
        } else if (item.revisionType === 'ADD') {
            return 'Добавление'
        } else {
            return 'Удаление'
        }
    }

    return (
        <>
            {/*<tr className={`extendable-table-row`}>*/}
                <tr onClick={() => toggleCollapse()}>
                    <td>{item.id}</td>
                    <td>{item.worker.secondName} {item.worker.firstName} ({item.worker.username})</td>
                    <td>{item.process}</td>
                    <td>{revisionType()}</td>
                    <td>{item.date}</td>
                </tr>
            <div className={`extendable-content ${isVisible ? 'seen' : ''}`} style={{width: '936px'}}>
                <table>
                    <tr>
                        <td><strong>ID</strong></td>
                        <td><span>{item.position.id}</span></td>
                    </tr>
                    <tr>
                        <td><strong>Тип</strong></td>
                        <td><span>{item.position.type}</span></td>
                    </tr>
                    <tr>
                        <td><strong>Марка</strong></td>
                        <td><span>{item.position.mark}</span></td>
                    </tr>
                    <tr>
                        <td><strong>Стандарты</strong></td>
                        <td><span>{item.position.standards.join(', ')}</span></td>
                    </tr>
                    <tr>
                        <td><strong>Диаметр</strong></td>
                        <td><span>{item.position.diameter}</span></td>
                    </tr>
                    <tr>
                        <td><strong>Упаковка</strong></td>
                        <td><span>{item.position.packing}</span></td>
                    </tr>
                    <tr>
                        <td><strong>Плавка</strong></td>
                        <td><span>{item.position.plav}</span></td>
                    </tr>
                    <tr>
                        <td><strong>Партия</strong></td>
                        <td><span>{item.position.part}</span></td>
                    </tr>
                    <tr>
                        <td><strong>Вес</strong></td>
                        <td><span>{item.position.weight}</span></td>
                    </tr>
                    <tr>
                        <td><strong>Производитель</strong></td>
                        <td><span>{item.position.manufacturer}</span></td>
                    </tr>
                    <tr>
                        <td><strong>Локация</strong></td>
                        <td><span>{item.position.location}</span></td>
                    </tr>
                    <tr>
                        <td><strong>Статус</strong></td>
                        <td><span>{item.position.status}</span></td>
                    </tr>
                    <tr>
                        <td><strong>Место на складе</strong></td>
                        <td>
                            <span>{item.position.storageLocation?.storageType} (Р{item.position.storageLocation?.row}, М{item.position.storageLocation?.place})</span>
                        </td>
                    </tr>
                    <tr>
                        <td><strong>Комментарий</strong></td>
                        <td><span>{item.position.comment}</span></td>
                    </tr>
                    <tr>
                        <td><strong>Дата</strong></td>
                        <td><span>{item.position.date}</span></td>
                    </tr>
                </table>
            </div>

            {/*</tr>*/}
        </>
    )
}