import axios from "axios";
import {BACKEND_URL} from "../ConstConfig";
import {ITickets} from "../interfaces/exportedInterfaces";
import {useLoaderData} from "react-router-dom";
import React from "react";

export async function ticketIdLoader({params}: any) {
    const response = await axios.get(BACKEND_URL + '/api/v1/ticket/' + params.id, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    })
    return {ticket: response.status === 200 || response.status === 201 ? response.data : 'Не удалось получить тикет'}
}

interface TicketIdInfoProps {
    ticket: string | ITickets
}

export function TicketIdInfo() {

    const {ticket} = useLoaderData() as TicketIdInfoProps

    return (
        <div className='dispatch-body'>
            {
                typeof ticket === 'string' ?
                    <h1>{ticket}</h1>
                    :
                    <div className='container'>
                        <h1>Тикет №{ticket.id}</h1>
                        <h2 style={{marginTop: '20px', marginBottom: '20px'}}>Информация о тикете</h2>
                        <div className='info-section'>
                            <div><strong>ID тикета</strong><span>{ticket.id}</span></div>
                            <div><strong>ID позиции</strong><span>{ticket.pendingRequest.id}</span></div>
                            <div><strong>Кем
                                создано</strong><span>{ticket.createdByWorker.firstName} {ticket.createdByWorker.secondName}</span>
                            </div>
                            <div>
                                <strong>Ответственный</strong><span>{ticket.approvedByWorker ? `${ticket.approvedByWorker.firstName} ${ticket.approvedByWorker.secondName}` : '-'}</span>
                            </div>
                            <div><strong>Статус</strong><span>{ticket.status}</span></div>
                            <div><strong>Комментарий</strong><span>{ticket.comment}</span></div>
                            <div><strong>Дата создания</strong><span>{ticket.createdAt}</span></div>
                        </div>
                        <div className='double-table-container'>
                            <div className='upper-section' style={{fontSize: '22px'}}>
                                <div><strong>Затронутая позиция</strong></div>
                            </div>
                            <div className='lower-section'>
                                <table className='one-card-table' style={{height: '300px', width: '98%'}}>
                                    <tr>
                                        <td className='normal-header' style={{height: '20%', fontSize: '18px', paddingRight: '10px'}} colSpan={2}>Было</td>
                                        <td className='normal-header' style={{height: '20%', fontSize: '18px'}} colSpan={2}>Станет</td>
                                    </tr>
                                    <tr>
                                        <td className='header'>ID</td>
                                        <td>{ticket.currentPositionInfo.id}</td>
                                        <td className='header'>ID</td>
                                        <td>{ticket.pendingRequest.id}</td>
                                    </tr>
                                    <tr>
                                        <td className='header'>Марка</td>
                                        <td>{ticket.currentPositionInfo.mark}</td>
                                        <td className='header'>Марка</td>
                                        <td>{ticket.pendingRequest.mark}</td>
                                    </tr>
                                    <tr>
                                        <td className='header'>Диаметр</td>
                                        <td>{ticket.currentPositionInfo.diameter}</td>
                                        <td className='header'>Диаметр</td>
                                        <td>{ticket.pendingRequest.diameter}</td>
                                    </tr>
                                    <tr>
                                        <td className='header'>Вес</td>
                                        <td>{ticket.currentPositionInfo.weight}</td>
                                        <td className='header'>Вес</td>
                                        <td>{ticket.pendingRequest.weight}</td>
                                    </tr>
                                    <tr>
                                        <td className='header'>Партия</td>
                                        <td>{ticket.currentPositionInfo.part}</td>
                                        <td className='header'>Партия</td>
                                        <td>{ticket.pendingRequest.part}</td>
                                    </tr>
                                    <tr>
                                        <td className='header'>Плавка</td>
                                        <td>{ticket.currentPositionInfo.plav}</td>
                                        <td className='header'>Плавка</td>
                                        <td>{ticket.pendingRequest.plav}</td>
                                    </tr>
                                    <tr>
                                        <td className='header'>Упаковка</td>
                                        <td>{ticket.currentPositionInfo.packing}</td>
                                        <td className='header'>Упаковка</td>
                                        <td>{ticket.pendingRequest.packing}</td>
                                    </tr>
                                    <tr>
                                        <td className='header'>Стандарты</td>
                                        <td>{ticket.currentPositionInfo.standards.join(', ')}</td>
                                        <td className='header'>Стандарты</td>
                                        <td>{ticket.pendingRequest.standards.join(', ')}</td>
                                    </tr>
                                    <tr>
                                        <td className='header'>Производитель</td>
                                        <td>{ticket.currentPositionInfo.manufacturer}</td>
                                        <td className='header'>Производитель</td>
                                        <td>{ticket.pendingRequest.manufacturer}</td>
                                    </tr>
                                    <tr>
                                        <td className='header'>Локация</td>
                                        <td>{ticket.currentPositionInfo.location}</td>
                                        <td className='header'>Локация</td>
                                        <td>{ticket.pendingRequest.location}</td>
                                    </tr>
                                    <tr>
                                        <td className='header'>Тип</td>
                                        <td>{ticket.currentPositionInfo.type}</td>
                                        <td className='header'>Тип</td>
                                        <td>{ticket.pendingRequest.type}</td>
                                    </tr>
                                    <tr>
                                        <td className='header'>Комментарий</td>
                                        <td>{ticket.currentPositionInfo.comment}</td>
                                        <td className='header'>Комментарий</td>
                                        <td>{ticket.pendingRequest.comment}</td>
                                    </tr>
                                    <tr>
                                        <td className='header'>Дата</td>
                                        <td>{ticket.currentPositionInfo.date}</td>
                                        <td className='header'>Дата</td>
                                        <td>{ticket.pendingRequest.date}</td>
                                    </tr>
                                    <tr>
                                        <td className='header'>Статус</td>
                                        <td>{ticket.currentPositionInfo.status}</td>
                                        <td className='header'>Статус</td>
                                        <td>{ticket.pendingRequest.status}</td>
                                    </tr>
                                </table>
                                {/*<table className='one-card-table' style={{height: '300px', width: '98%'}}>*/}
                                {/*    <tr>*/}
                                {/*        <td className='normal-header' style={{height: '20%', fontSize: '18px'}}*/}
                                {/*            colSpan={14}>Станет*/}
                                {/*        </td>*/}
                                {/*    </tr>*/}
                                {/*    <tr>*/}
                                {/*        <td>ID</td>*/}
                                {/*        <td>Марка</td>*/}
                                {/*        <td>Диаметр</td>*/}
                                {/*        <td>Вес</td>*/}
                                {/*        <td>Партия</td>*/}
                                {/*        <td>Плавка</td>*/}
                                {/*        <td>Упаковка</td>*/}
                                {/*        <td>Стандарты</td>*/}
                                {/*        <td>Производитель</td>*/}
                                {/*        <td>Локация</td>*/}
                                {/*        <td>Тип</td>*/}
                                {/*        <td>Комментарий</td>*/}
                                {/*        <td>Дата</td>*/}
                                {/*        <td>Статус</td>*/}
                                {/*    </tr>*/}
                                {/*    <tr>*/}
                                {/*        <td>{ticket.pendingRequest.id}</td>*/}
                                {/*        <td>{ticket.pendingRequest.mark}</td>*/}
                                {/*        <td>{ticket.pendingRequest.diameter}</td>*/}
                                {/*        <td>{ticket.pendingRequest.weight}</td>*/}
                                {/*        <td>{ticket.pendingRequest.part}</td>*/}
                                {/*        <td>{ticket.pendingRequest.plav}</td>*/}
                                {/*        <td>{ticket.pendingRequest.packing}</td>*/}
                                {/*        <td>{ticket.pendingRequest.standards.join(', ')}</td>*/}
                                {/*        <td>{ticket.pendingRequest.manufacturer}</td>*/}
                                {/*        <td>{ticket.pendingRequest.location}</td>*/}
                                {/*        <td>{ticket.pendingRequest.type}</td>*/}
                                {/*        <td>{ticket.pendingRequest.comment}</td>*/}
                                {/*        <td>{ticket.pendingRequest.date}</td>*/}
                                {/*        <td>{ticket.pendingRequest.status}</td>*/}
                                {/*    </tr>*/}
                                {/*</table>*/}
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}