import React, {useState} from "react";
import '../css/ShowPage.css'
import {Loader} from "../components/Loader";
import {useModels} from "../hooks/useModels";
import {FilterPanel} from "../components/FilterPanel";
import {TableView} from "../components/TableView";
import useToken from "../hooks/useToken";
import {NewCard} from "../components/NewCard";

export function ShowPage() {
    const {token, setToken} = useToken()
    const {loading, error, models, cards, marks, packs, diameter} = useModels(token!!)
    const [cardView, setCardView] = useState(false)
    const [selectedMarks, setSelectedMarks] = useState<string[]>([])
    const [selectedPacks, setSelectedPacks] = useState<string[]>([])
    const [selectedDiameterLeft, setSelectedDiameterLeft] = useState(0.3)
    const [selectedDiameterRight, setSelectedDiameterRight] = useState(20)
    const [selectedSklads, setSelectedSklads] = useState<string[]>([])
    const sklads = ['Производство', 'Белорецк', 'Солнечногорск', 'Матросово']

    function packsHandler(item: string[]) {
        setSelectedPacks(item)
    }

    function marksHandler(item: string[]) {
        setSelectedMarks(item)
    }

    return (
        <>
            {
                localStorage.getItem('actions')?.includes('STOCK_INFO') &&
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{width: '100%'}}>
                        {
                            loading &&
                            <div style={{height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Loader/>
                            </div>
                        }
                        {
                            error &&
                            <div style={{marginTop: '90px'}}>
                                <h2 style={{color: 'red'}}>{error}</h2>
                            </div>
                        }
                        {
                            !loading && !error &&
                            <div>
                                <div className='switchbar'>
                                    <p>Переключить на карточный вид:</p>
                                    <label className='switch'>
                                        <input type='checkbox' onChange={() => setCardView(!cardView)}/>
                                        <span className='slider round'></span>
                                    </label>
                                </div>
                                <FilterPanel marks={marks} packs={packs} selectedMarks={selectedMarks} selectedPacks={selectedPacks}
                                             marksHandler={marksHandler} packsHandler={packsHandler} leftDiameter={diameter.min}
                                             rightDiameter={diameter.max}
                                             selectedDiameterLeft={selectedDiameterLeft}
                                             selectedDiameterRight={selectedDiameterRight}
                                             leftDiameterHandler={setSelectedDiameterLeft}
                                             rightDiameterHandler={setSelectedDiameterRight}
                                             sklads={sklads}
                                             selectedSklads={selectedSklads}
                                             skladsHandler={setSelectedSklads}
                                />
                                {
                                    !cardView &&
                                    <TableView models={models} selectedMarks={selectedMarks} selectedPacks={selectedPacks}
                                               selectedDiameterLeft={selectedDiameterLeft}
                                               selectedDiameterRight={selectedDiameterRight}
                                               selectedSklads={selectedSklads}
                                    />
                                }
                                {
                                    cardView &&
                                    <div className='new-card-container' style={{width: '80%'}}>
                                        {/*{cards.map((card, index) => <Card card={card} key={index}/>)}*/}
                                        {cards.map((card, index) => <NewCard card={card} key={index}/>)}
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    )
}