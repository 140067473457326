import React, {useState} from "react";
import {useShippingHistory} from "../hooks/useShippingHistory";
import {Loader} from "../components/Loader";
import {ShipmentHistoryTableRow} from "../components/ShipmentHistoryTableRow";

export function DispatchHistoryPage() {

    const [pageNumber, setPageNumber] = useState(0)
    const [from, setFrom] = useState<string>('')
    const [to, setTo] = useState<string>('')
    const [customer, setCustomer] = useState<string>('')

    const {shippingHistory, error, loading, totalPages} = useShippingHistory({pageNumber: pageNumber, from: from, to: to, customer: customer})

    return (
        <>
            {
                localStorage.getItem('actions')?.includes('DISPATCH_HISTORY') &&
                <div className='dispatch-body'>
                    {loading &&
                        <div style={{height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Loader/>
                        </div>}
                    {error &&
                        <h1 style={{color: 'red', marginBottom: '0', marginTop: '90px'}}>Ошибка загрузки: {error}</h1>}
                    <div className='dispatch-history-inputs' style={{display: 'flex', alignItems: 'center', justifyContent: 'left', marginTop: '10px'}}>
                        <span style={{marginRight: '5px'}}>С:</span>
                        <input type="date" className='planner-date' value={from} style={{boxSizing: "border-box", width: '200px', marginRight: '10px'}}
                               onChange={(event) => {
                                   setFrom(event.target.value)
                                   setPageNumber(0)
                               }}/>
                        <span style={{marginRight: '5px'}}>До:</span>
                        <input type="date" className='planner-date' value={to} style={{boxSizing: "border-box", width: '200px', marginRight: '10px'}}
                               onChange={(event) => {
                                   setTo(event.target.value)
                                   setPageNumber(0)
                               }} placeholder='Конечная дата'/>
                        <input type="text" className='planner-date' value={customer} style={{boxSizing: "border-box", width: '200px'}}
                               onChange={(event) => {
                                   setCustomer(event.target.value)
                                   setPageNumber(0)
                               }} placeholder='Покупатель'/>
                    </div>
                    {!loading &&
                        <table style={{margin: '10px 0', width: '98%'}}>
                            <thead>
                            <tr>
                                <th style={{width: '12%'}}>ID</th>
                                <th style={{width: '16%'}}>Вес</th>
                                <th style={{width: '32%'}}>Покупатель</th>
                                <th style={{width: '20%'}}>Счёт</th>
                                <th style={{width: '20%'}}>Дата</th>
                            </tr>
                            </thead>
                            <tbody>
                            {shippingHistory.map((ship, index) => <ShipmentHistoryTableRow item={ship}
                                                                                           key={index}/>)}
                            </tbody>
                        </table>
                    }
                    <div className='plav-page-table-page-container'>
                        <button type='button' onClick={() => {
                            if (pageNumber > 0) {
                                setPageNumber(pageNumber - 1)
                            } else {
                                return
                            }
                        }}>{'<'}</button>
                        <span>стр: {pageNumber + 1}</span>
                        <button type='button' onClick={() => {
                            if (pageNumber + 1 < totalPages) {
                                setPageNumber(pageNumber + 1)
                            } else {
                                return
                            }
                        }}>{'>'}</button>
                    </div>
                </div>
            }
        </>
    )
}