import {useEffect, useState} from "react";
import {ICart, IModelsCardForPackage} from "../interfaces/exportedInterfaces";
import axios from "axios";
import {BACKEND_URL} from "../ConstConfig";

export function useCart() {

    const [cart, setCart] = useState<ICart>({
        id: 0,
        positions: [],
        createdAt: ''
    })
    const [error, setError] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)

    async function fetchCart() {
        setError('')
        setLoading(true)
        try {
            const response = await axios.get(BACKEND_URL + '/api/v1/mobile-cart', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            setCart(response.data)
            // let temp: string[] = []
            // response.data.positions.forEach((position: IModelsCardForPackage) => temp = [...temp, String(position.id)])
            // localStorage.setItem('cart', JSON.stringify(temp))
            setLoading(false)
        } catch (e: unknown) {
            setLoading(false)
            setError('Ошибка загрузки корзины')
        }
    }

    useEffect(() => {
        fetchCart()
    }, [])


    return {error, loading, cart}
}