import {NavLink} from "react-router-dom";

interface ReserveNavigationProps {
    setExtendModal: (prop: boolean) => void,
    setCancelModal: (prop: boolean) => void
}

export function ReserveNavigation({setExtendModal, setCancelModal}: ReserveNavigationProps) {
    return (
        <nav>
            <span className='block'>
                {
                    localStorage.getItem('actions')?.includes('RESERVE_INFO_BY_ID') &&
                    <NavLink className='sidebar-btn' to='info'>Информация</NavLink>
                }
                {
                    localStorage.getItem('actions')?.includes('DISPATCH_RESERVE') &&
                    <NavLink className='sidebar-btn' to='dispatch' >Отгрузка</NavLink>
                }
                {
                    localStorage.getItem('actions')?.includes('CONFIRM_RESERVE') &&
                    <NavLink className='sidebar-btn' to='confirm'>Подтвердить</NavLink>
                }
                {
                    localStorage.getItem('actions')?.includes('PROLONGATE_RESERVE') &&
                    <div className='sidebar-btn' style={{cursor: 'pointer'}} onClick={() => setExtendModal(true)}>Продлить</div>
                }
                {
                    localStorage.getItem('actions')?.includes('CANCEL_RESERVE') &&
                    <div className='sidebar-btn' style={{cursor: 'pointer'}} onClick={() => setCancelModal(true)}>Отменить</div>
                }
            </span>
        </nav>
    )
}