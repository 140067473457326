import {toast, ToastContainer} from "react-toastify";
import React, {useState} from "react";
import {Loader} from "../components/Loader";
import axios from "axios";
import {BACKEND_URL} from "../ConstConfig";
import {useActionsDictionary} from "../hooks/useActionsDictionary";

export function ActionsDirectoryPage() {

    const [newAction, setNewAction] = useState<string>('')
    const [refetchFlag, setRefetchFlag] = useState<boolean>(false)

    const {actions, loading, error} = useActionsDictionary(refetchFlag)

    async function saveAction(event: { preventDefault: () => void; }) {
        event.preventDefault()
        try {
            const response = await toast.promise(axios.post(BACKEND_URL + '/api/v1/action', {
                action: newAction
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }), {
                pending: 'Сохраняется экшен..',
                success: 'Экшен добавлен!',
                error: 'Ошибка добавления экшена'
            })
            if (response.status === 200 || response.status === 201) {
                setRefetchFlag(!refetchFlag)
            }
        } catch (e) {
            return
        }
    }

    async function deleteAction(action: string) {
        try {
            const response = await toast.promise(axios.delete(BACKEND_URL + '/api/v1/action', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                data: {
                    action: action
                }
            }), {
                pending: 'Удаление экшена..',
                success: 'Экшен удалён',
                error: 'Не удалось удалить экшен'
            })
            if (response.status === 200 || response.status === 201 || response.status === 204) {
                setRefetchFlag(!refetchFlag)
            }
        } catch (e) {
            return
        }
    }

    return (
        <>
            {
                localStorage.getItem('actions')?.includes('DIRECTORIES_ACTIONS') &&
                !loading ?
                    <div className='dispatch-body'>
                        {
                            error && <h1 style={{color: 'crimson'}}>{error}</h1>
                        }
                        {
                            !error &&
                            <form className='container' onSubmit={saveAction}>
                                <table style={{marginBottom: '20px'}}>
                                    <thead>
                                    <tr>
                                        <th style={{width: '85%'}}>Экшены</th>
                                        {
                                            localStorage.getItem('actions')?.includes('EDIT_ACTIONS') &&
                                            <th style={{width: '15%'}}>Действия</th>
                                        }
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        actions.map((action, index) => <tr key={index}>
                                            <td>{action.action}</td>
                                            {
                                                localStorage.getItem('actions')?.includes('EDIT_ACTIONS') &&
                                                <td>
                                                    <button type='button' className='remove-requirements-field-btn'
                                                            onClick={() => deleteAction(action.action)}>X
                                                    </button>
                                                </td>
                                            }
                                        </tr>)
                                    }
                                    {
                                        localStorage.getItem('actions')?.includes('EDIT_ACTIONS') &&
                                            <tr>
                                                <td>
                                                    <input type="text" className='requirements-input'
                                                           style={{width: '100%'}}
                                                           name='newPacking' value={newAction} required
                                                           onChange={(event) => setNewAction(event.target.value)}/>
                                                </td>
                                                <td>
                                                    -
                                                </td>
                                            </tr>
                                    }
                                    </tbody>
                                </table>
                                {
                                    localStorage.getItem('actions')?.includes('EDIT_ACTIONS') &&
                                    <div className='cancel-changedate-container' style={{margin: '0'}}>
                                        <button type='submit' className='save-btn'>Добавить экшен</button>
                                    </div>
                                }
                            </form>
                        }
                    </div>
                    :
                    <div style={{height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Loader/>
                    </div>
            }
            <ToastContainer/>
        </>
    )
}