import '../css/CartPage.css'
import {NewCard} from "../components/NewCard";
import {useCart} from "../hooks/useCart";
import {Loader} from "../components/Loader";

export function CartPage() {

    const {error, loading, cart} = useCart()

    return (
        <>
            {
                localStorage.getItem('actions')?.includes('VIEW_CART') &&
                <div className='cart-mainbar'>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '95%'
                        }}>
                            <h1 style={{color: '#555'}}>Позиции в корзине</h1>
                        </div>
                    </div>
                    {
                        loading && <Loader/>
                    }
                    {
                        error &&
                        <h3 style={{color: 'crimson'}}>{error}</h3>
                    }
                    {
                        !loading && !error &&
                        <div className='new-card-container' style={{width: '100%'}}>
                            {cart.positions.map((position, index) => <NewCard card={position}/>)}
                        </div>
                    }

                </div>
            }
        </>
    )
}