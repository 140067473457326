import {Loader} from "../components/Loader";
import React, {useState} from "react";
import {toast, ToastContainer} from "react-toastify";
import {useMarksDictionary} from "../hooks/useMarksDictionary";
import axios from "axios";
import {BACKEND_URL} from "../ConstConfig";
import {MarksDirectoryTableRow} from "../components/MarksDirectoryTableRow";
import '../css/MarksDirectoryPage.css'
import {useStandardsDictionary} from "../hooks/useStandardsDictionary";
import {SelectFields} from "./RegisterPage";
import Select from "react-select";
import makeAnimated from "react-select/animated";

export function MarksDirectoryPage() {

    const [refetchFlag, setRefetchFlag] = useState<boolean>(false);
    const [newMark, setNewMark] = useState<string>('')
    const [newStandards, setNewStandards] = useState<SelectFields[]>([])
    const [changeMarkMode, setChangeMarkMode] = useState<boolean>(false)
    const [miniFlag, setMiniFlag] = useState<boolean>(false)
    const [chosenId, setChosenId] = useState<string>('')

    const {marks, loading, error} = useMarksDictionary(refetchFlag)
    const {standards} = useStandardsDictionary(refetchFlag)

    const animatedComponents = makeAnimated();

    const standardsOptions = () => {
        let temp: SelectFields[] = []
        standards.map(std => temp = [...temp, {value: std.standard, label: std.standard}])
        return temp
    }

    async function saveMark(event: { preventDefault: () => void; }) {
        event.preventDefault()
        try {
            const response = await toast.promise(axios.post(BACKEND_URL + '/api/v1/grades', {
                grade: newMark,
                standards: []
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }), {
                pending: 'Добавление марки..',
                success: 'Марка добавлена!',
                error: 'Не удалось добавить марку'
            })
            if (response.status === 200 || response.status === 201) {
                setRefetchFlag(!refetchFlag)
            }
        } catch (e) {
            return
        }
    }

    async function updateMark(event: { preventDefault: () => void; }) {
        event.preventDefault()
        let temp: string[] = []
        newStandards.map(std => temp = [...temp, std.value])
        try {
            const response = await toast.promise(axios.put(BACKEND_URL + '/api/v1/grades/' + chosenId, {
                grade: newMark,
                standards: temp
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }), {
                pending: 'Обновляем марку..',
                success: 'Марка обновлена!',
                error: 'Не удалось обновить марку'
            })
            if (response.status === 200 || response.status === 201 || response.status === 204) {
                window.location.reload()
            }
        } catch (e) {
            return
        }
    }

    return (
        <>
            {
                localStorage.getItem('actions')?.includes('VIEW_GRADES') &&
                !loading ?
                    <div className='dispatch-body'>
                        {
                            error && <h1 style={{color: 'crimson'}}>{error}</h1>
                        }
                        {
                            !error &&
                            <form className='container' onSubmit={saveMark}>
                                <table style={{marginBottom: '20px'}}>
                                    <thead>
                                    <tr>
                                        <th style={{width: '10%'}}>ID</th>
                                        <th style={{width: '30%'}}>Марка</th>
                                        <th style={{width: '50%'}}>Стандарты</th>
                                        {
                                            localStorage.getItem('actions')?.includes('EDIT_GRADES') &&
                                            <th style={{width: '10%'}}>Действия</th>
                                        }
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        marks.map((mark, index) => <MarksDirectoryTableRow
                                            index={index} mark={mark} refetchFlag={refetchFlag} setRefetchFlag={setRefetchFlag}
                                            markInput={newMark} setMarkInput={setNewMark} standardInput={newStandards}
                                            setStandardInput={setNewStandards} setOverallFlag={setChangeMarkMode}
                                            miniFlag={miniFlag} setChosenId={setChosenId} standardsOptions={standardsOptions()}/>)
                                    }
                                    {
                                        localStorage.getItem('actions')?.includes('EDIT_GRADES') &&
                                        <tr>
                                            <td>
                                                -
                                            </td>
                                            <td>
                                                <input type="text" className='requirements-input'
                                                       style={{width: '100%'}}
                                                       name='newMark' value={newMark} required
                                                       onChange={(event) => setNewMark(event.target.value)}/>
                                            </td>
                                            <td>
                                                -
                                            </td>
                                            <td>
                                                -
                                            </td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>
                                {/*{*/}
                                {/*    changeMarkMode && localStorage.getItem('actions')?.includes('EDIT_GRADES') &&*/}
                                {/*    <div className='cancel-changedate-container' style={{margin: '0'}}>*/}
                                {/*        <button type='submit' className='save-btn' onMouseOver={() => setMiniFlag(true)} onMouseOut={() => setMiniFlag(false)}>Изменить марку</button>*/}
                                {/*        <button type='button' style={{marginLeft: '10px'}} onClick={() => setChangeMarkMode(false)}>Добавить новую марку</button>*/}
                                {/*    </div>*/}
                                {/*}*/}
                                {
                                    localStorage.getItem('actions')?.includes('EDIT_GRADES') &&
                                    <div className='cancel-changedate-container' style={{margin: '0'}}>
                                        <button type='submit' className='save-btn'>Добавить марку</button>
                                    </div>
                                }
                            </form>
                        }
                    </div>
                    :
                    <div style={{height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Loader/>
                    </div>
            }
            <ToastContainer/>
        </>
    )
}