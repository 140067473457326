import {useEffect, useState} from "react";
import {IReserve} from "../interfaces/exportedInterfaces";
import axios, {AxiosError} from "axios";
import {BACKEND_URL} from "../ConstConfig";

interface UseReservesProps {
    pageNumber: number,
    mark?: string,
    diameter?: string,
    packing?: string
}

export function useReserves({pageNumber, mark, diameter, packing}: UseReservesProps) {
    const [reserves, setReserves] = useState<IReserve[]>([])
    const [error, setError] = useState('')
    const [loader, setLoader] = useState(false)
    const query = () => {
        if (mark && diameter && packing) {
            return `&mark=${mark}&diameter=${diameter}&packing=${packing}`
        } else {
            return ''
        }
    }

    async function fetchReserves() {
        try {
            setLoader(true)
            setError('')
            const response = await axios.get(BACKEND_URL + '/api/v1/reserve?page=' + pageNumber + '&size=25' + query(), {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            setReserves(response.data.content)
            setLoader(false)
        } catch (e: unknown) {
            setLoader(false)
            const error = e as AxiosError
            setError(error.message)
        }
    }

    useEffect(() => {
        fetchReserves()
    }, [pageNumber])

    return {reserves, error, loader}
}