import ReactDom from "react-dom";
import {toast, ToastContainer} from "react-toastify";
import {SelectFields} from "../pages/RegisterPage";
import React, {useState} from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {useMarksDictionary} from "../hooks/useMarksDictionary";
import {Loader} from "./Loader";
import {IStandardIdInfo} from "../interfaces/exportedInterfaces";
import axios from "axios";
import {BACKEND_URL} from "../ConstConfig";

interface ModalWindowAddNewGradeProps {
    openModal: (prop: boolean) => void,
    boundMarks: string[],
    standard: IStandardIdInfo | string
}

export function ModalWindowAddNewGrade({openModal, boundMarks, standard}: ModalWindowAddNewGradeProps) {

    const portalElement: HTMLElement = document.getElementById('portal')!

    const animatedComponents = makeAnimated();

    const refetchFlag = true

    const {marks, error, loading} = useMarksDictionary(refetchFlag)

    const [selectedMarks, setSelectedMarks] = useState<SelectFields[]>(() => {
        let temp: SelectFields[] = []
        boundMarks.map(mark => temp = [...temp, {value: mark, label: mark}])
        return temp
    })

    const realMarks = () => {
        let temp: SelectFields[] = []
        marks.map(mark => temp = [...temp, {value: mark.grade, label: mark.grade}])
        return temp
    }

    async function sendRequest(event: { preventDefault: () => void; }) {
        event.preventDefault()
        if (typeof standard !== 'string') {
            try {
                let temp: string[] = []
                selectedMarks.map(mark => temp = [...temp, mark.value])
                const response = await toast.promise(axios.put(BACKEND_URL + '/api/v1/standards/' + standard.id, {
                    standard: standard.name,
                    grades: temp
                }, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                }), {
                    pending: 'Добавляем марку..',
                    success: 'Марка успешно добавлена',
                    error: 'Не удалось добавить марку'
                })
                // if (response.status === 200) {
                //     window.location.reload()
                // }
            } catch (e: unknown) {
                return
            }
        } else {
            return
        }
    }

    return ReactDom.createPortal(
        <form className='modalWindow' onSubmit={sendRequest}>
            <div className='modalBackground' id='bg' onClick={() => openModal(false)}/>
            {
                loading && <Loader color={true}/>
            }
            {
                error &&
                <div className='modalContainer' id='container'>
                    <div className='title'>
                        <h1>Не удалось загрузить марки</h1>
                    </div>
                </div>
            }
            {
                !loading && !error &&
                <div className='modalContainer' id='container' style={{height: '45%'}}>
                    <div className='titleCloseBtn'>
                        <button type='button' id='closeBtn' onClick={() => openModal(false)}>X</button>
                    </div>
                    <div className='title'>
                        <h1 style={{color: '#555', marginTop: '0'}}>Добавление марки</h1>
                    </div>
                    <div className='body'>
                        <div className='modalInput'>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <label htmlFor="mark" style={{color: '#555'}}>Выбранные марки:</label>
                            </div>
                            <Select placeholder='Выберите марки..' isMulti id='mark' value={selectedMarks} name='mark'
                                    className='basic-multi-select'
                                    classNamePrefix='select' options={realMarks()} closeMenuOnSelect={false}
                                    components={animatedComponents} defaultValue={selectedMarks} required
                                    noOptionsMessage={() => 'Нет подходящих марок'}
                                    isClearable //@ts-ignore
                                    onChange={setSelectedMarks}/>
                        </div>
                    </div>
                    <div className='footer'>
                        <div className='cancel-changedate-container'>
                            <button type='button' className='new-design-btn'
                                    style={{marginRight: '10px', width: '170px', fontSize: '20px', height: '40px'}}
                                    onClick={() => openModal(false)}>Отменить
                            </button>
                            <button type='submit' className='save-btn new-design-btn' style={{
                                marginLeft: '10px',
                                width: '170px',
                                fontSize: '20px',
                                height: '40px'
                            }}>Добавить
                            </button>
                        </div>
                    </div>
                </div>
            }
            <ToastContainer/>
        </form>,
        portalElement
    )
}