import React, {useState} from "react";
import {IGradeStandard} from "../interfaces/exportedInterfaces";
import {toast} from "react-toastify";
import axios from "axios";
import {BACKEND_URL} from "../ConstConfig";
import {SelectFields} from "../pages/RegisterPage";

interface StandardsDirectoryTableRowProps {
    index: number;
    standard: IGradeStandard,
    refetchFlag: boolean,
    setRefetchFlag: (prop: boolean) => void,
    markInput: SelectFields[],
    setMarkInput: (prop: SelectFields[]) => void,
    standardInput: string,
    setStandardInput: (prop: string) => void,
    setOverallFlag: (prop: boolean) => void,
    miniFlag: boolean,
    setChosenId: (prop: string) => void,
    marksOptions: SelectFields[]
}

export function StandardsDirectoryTableRow({index, standard, refetchFlag, setRefetchFlag, markInput, setMarkInput, standardInput, setStandardInput, setOverallFlag, miniFlag, setChosenId, marksOptions}: StandardsDirectoryTableRowProps): JSX.Element {

    // const [flag, setFlag] = useState<boolean>(false);
    // const [ulFocus, setUlFocus] = useState<boolean>(false)

    // const animatedComponents = makeAnimated()

    async function deleteStandard(id: number) {
        try {
            const response = await toast.promise(axios.delete(BACKEND_URL + '/api/v1/grades/' + id, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }), {
                pending: 'Удаление стандарта..',
                success: 'Стандарт удален',
                error: 'Не удалось удалить стандарт'
            })
            if (response.status === 200 || response.status === 201 || response.status === 204) {
                setRefetchFlag(!refetchFlag)
            }
        } catch (e) {
            return
        }
    }

    return (
        <tr className='hoverable-tr' key={index} onClick={() => window.open(`/standard/${standard.id}/info`, '_blank')}
            // onClick={() => {
            // setStandardInput(standard.standard)
            // if (!ulFocus) {
            //     let temp: SelectFields[] = []
            //     standard.grades.map(std => temp = [...temp, {value: std, label: std}])
            //     setMarkInput(temp)
            // }
            // setOverallFlag(true)
            // setFlag(true)
            // setChosenId(String(standard.id))
            // tabIndex={0}
            // onBlur={() => {
            // if (!ulFocus) {
            //     setFlag(false)
        >
            <td>{standard.id}</td>
            {/*{*/}
            {/*    flag && localStorage.getItem('actions')?.includes('EDIT_STANDARDS') ?*/}
            {/*        <td>*/}
            {/*            <input className='requirements-input' style={{width: '100%'}} type="text" value={standardInput}*/}
            {/*                   onChange={(event) => setStandardInput(event.target.value)} onMouseOver={() => setUlFocus(true)} onMouseOut={() => setUlFocus(false)}/>*/}
            {/*        </td>*/}
            {/*        :*/}
                    <td>{standard.standard}</td>
            {/*}*/}
            {/*{*/}
            {/*    flag && localStorage.getItem('actions')?.includes('EDIT_STANDARDS') ?*/}
            {/*        <td>*/}
            {/*            <div onMouseOver={() => setUlFocus(true)} onMouseOut={() => setUlFocus(false)}>*/}
            {/*                <Select placeholder='Выберите марки' isMulti id='gost' value={markInput} name='gost'*/}
            {/*                        className='basic-multi-select'*/}
            {/*                        classNamePrefix='select' options={marksOptions} closeMenuOnSelect={false}*/}
            {/*                        components={animatedComponents} defaultValue={markInput} required*/}
            {/*                        noOptionsMessage={() => 'Нет подходящих марок'}*/}
            {/*                        isClearable //@ts-ignore*/}
            {/*                        onChange={setMarkInput}/>*/}
            {/*            </div>*/}
            {/*        </td>*/}
            {/*        :*/}
                    <td>{standard.grades.join(', ')}</td>
            {/*}*/}
            {
                localStorage.getItem('actions')?.includes('EDIT_STANDARDS') &&
                <td>
                    <button type='button' className='remove-requirements-field-btn'
                            onClick={() => deleteStandard(standard.id)}>X
                    </button>
                </td>
            }
        </tr>
    )
}