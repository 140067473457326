import React, {useState} from "react";
import {Loader} from "../components/Loader";
import {useParts} from "../hooks/useParts";
import {ModalWindowAddPart} from "../components/ModalWindowAddPart";
import {Link} from "react-router-dom";
import {IPlav} from "../interfaces/exportedInterfaces";

export function PartPage() {

    const [pageNumber, setPageNumber] = useState(0)
    const [modal, setModal] = useState<boolean>(false)
    const [userInput, setUserInput] = useState<string>('')

    const {error, loading, parts} = useParts({pageNumber: pageNumber, userInput: userInput})

    return (
        <>
            {
                localStorage.getItem('actions')?.includes('BATCH_INFO') &&
                <div className='plav-page-container'>
                    {modal && <ModalWindowAddPart openModal={setModal}/>}
                    <div className='upper-plav-page-container'>
                        <div className='search-bar-container'>
                            <input placeholder='Начните ввод для поиска..' type="text" value={userInput} onChange={event => setUserInput(event.target.value)}/>
                        </div>
                        {
                            localStorage.getItem('actions')?.includes('ADD_BATCH') &&
                            <button className='add-plav-button' type='button' onClick={() => setModal(true)}>Добавить партию</button>
                        }
                    </div>
                    <div className='plav-page-table-container'>
                        {loading && <Loader/>}
                        {error && <h2 style={{color: 'red'}}>{error}</h2>}
                        {!error && !loading &&
                            <table className='plav-page-table'>
                                <thead>
                                    <tr>
                                        <th style={{width: '20%'}}>Партия</th>
                                        <th style={{width: '9%'}}>Диаметр</th>
                                        <th style={{width: '13%'}}>Плавка</th>
                                        <th style={{width: '28%'}}>Производитель</th>
                                        <th style={{width: '15%'}}>Упаковка</th>
                                        <th style={{width: '15%'}}>Стандарты</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {parts.map((part, index) =>
                                    <tr>
                                        <td><Link to={'/part/' + part.batch.replace('/', '+') + '/info'}>{part.batch}</Link></td>
                                        <td>{part.diameter}</td>
                                        <td>{part.melt}</td>
                                        <td>{part.manufacturer}</td>
                                        <td>{part.packing}</td>
                                        <td>{part.standard === null ? '-' : part.standard.join(', ')}</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        }
                    </div>
                    <div className='plav-page-table-page-container'>
                        <button type='button' onClick={() => {
                            if (pageNumber > 0) {
                                setPageNumber(pageNumber - 1)
                            } else {
                                return
                            }
                        }}>{'<'}</button>
                        <span>стр: {pageNumber + 1}</span>
                        <button type='button' onClick={() => {
                            if (parts.length === 20) {
                                setPageNumber(pageNumber + 1)
                            } else {
                                return
                            }
                        }}>{'>'}</button>
                    </div>
                </div>
            }
        </>
    )
}