import {BACKEND_URL} from "../ConstConfig";
import axios from "axios";
import {IDispatchHistoryId, IPositionsResponse} from "../interfaces/exportedInterfaces";
import {useLoaderData} from "react-router-dom";
import {CardView} from "./CardView";
import React, {useState} from "react";
import {NewCard} from "./NewCard";
import {CustomFileInput} from "./CustomFileInput";

//@ts-ignore
export async function dispatchIdLoader({params}) {
    if (!localStorage.getItem('token')) {
        const card: IDispatchHistoryId = {id: 0, customer: '', bill: '', weight: 0, date: '', positions: [], files: []}
        return {card}
    } else {
        const responseDispatches = await axios.get(BACKEND_URL + '/api/v1/dispatch/' + params.id, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token")
            }
        })
        const responseMarkable = await axios.get(BACKEND_URL + '/api/v1/dispatch/' + params.id + '/certificate/check', {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token")
            }
        })
        const dispatches: IDispatchHistoryId = responseDispatches.data
        const markable: {key: string, value: boolean} = responseMarkable.data
        return {dispatches, markable}
    }
}

interface DispatchIdInfoProps {
    dispatches: IDispatchHistoryId,
    markable: {key: string, value: boolean}
}

export function DispatchIdInfo() {

    const {dispatches, markable}= useLoaderData() as DispatchIdInfoProps
    const [error, setError] = useState<string>('')

    async function fetchCertificate() {
        setError('')
        try {
            const response = await axios.get(BACKEND_URL + '/api/v1/dispatch/' + dispatches.id + '/certificate', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            if (response.status === 200) {
                window.open(response.data.url, '_blank')
            }
        } catch (e: unknown) {
            setError('Произошла ошибка при создании сертификата')
        }
    }

    return (
        <>
            {
                localStorage.getItem('actions')?.includes('DISPATCH_INFO_BY_ID') &&
                <div className='dispatch-body'>
                    <div className='container'>
                        <div className='info-section' style={{marginBottom: '15px'}}>
                            <div><strong>Id</strong><span>{dispatches.id}</span></div>
                            <div><strong>Вес</strong><span>{dispatches.weight}</span></div>
                            <div><strong>Клиент</strong><span>{dispatches.customer}</span></div>
                            <div><strong>Номер счёта</strong><span>{dispatches.bill}</span></div>
                            <div><strong>Дата
                                отгрузки</strong><span>{dispatches.date.replace('T', ' ').split('.')[0]}</span></div>
                        </div>
                        {/*<div className='mainbar-container'>*/}
                        {/*<table>*/}
                        {/*    <tr>*/}
                        {/*        <th style={{fontSize: '18px', borderTopLeftRadius: '6px', borderBottomLeftRadius: '6px'}}>Id</th>*/}
                        {/*        <td style={{fontSize: '18px', borderTopRightRadius: '6px', borderBottomRightRadius: '6px'}}>{dispatches.id}</td>*/}
                        {/*    </tr>*/}
                        {/*    <tr>*/}
                        {/*        <th style={{fontSize: '18px', borderTopLeftRadius: '6px', borderBottomLeftRadius: '6px'}}>Вес</th>*/}
                        {/*        <td style={{fontSize: '18px', borderTopRightRadius: '6px', borderBottomRightRadius: '6px'}}>{dispatches.weight}</td>*/}
                        {/*    </tr>*/}
                        {/*    <tr>*/}
                        {/*        <th style={{fontSize: '18px', borderTopLeftRadius: '6px', borderBottomLeftRadius: '6px'}}>Клиент</th>*/}
                        {/*        <td style={{fontSize: '18px', borderTopRightRadius: '6px', borderBottomRightRadius: '6px'}}>{dispatches.customer}</td>*/}
                        {/*    </tr>*/}
                        {/*    <tr>*/}
                        {/*        <th style={{fontSize: '18px', borderTopLeftRadius: '6px', borderBottomLeftRadius: '6px'}}>Номер счета</th>*/}
                        {/*        <td style={{fontSize: '18px', borderTopRightRadius: '6px', borderBottomRightRadius: '6px'}}>{dispatches.bill}</td>*/}
                        {/*    </tr>*/}
                        {/*    <tr>*/}
                        {/*        <th style={{fontSize: '18px', borderTopLeftRadius: '6px', borderBottomLeftRadius: '6px'}}>Дата отгрузки</th>*/}
                        {/*        <td style={{fontSize: '18px', borderTopRightRadius: '6px', borderBottomRightRadius: '6px'}}>{dispatches.date.replace('T', ' ').split('.')[0]}</td>*/}
                        {/*    </tr>*/}
                        {/*</table>*/}
                        {/*</div>*/}
                        {error && <h2 style={{color: 'crimson', marginBottom: '0'}}>{error}</h2>}
                        {
                            markable.value &&
                            <button className='confirm-dispatch-btn' style={{margin: '0 0 10px 0'}}
                                    onClick={fetchCertificate}>Сертификат</button>
                        }
                        <h2 className='testing' style={{marginBottom: '-5px', marginTop: '5px'}}>Файлы</h2>
                        <div className="file-section" style={{marginTop: '5px', marginBottom: '15px'}}>
                            <div className='file-item'>
                                <CustomFileInput id={dispatches.id} url={'upload-bill'}
                                                 requiredType={'PLANNED_DISPATCH_BILL'} files={dispatches.files}
                                                 header={'Счёт'} path={'dispatch'}
                                />
                            </div>
                            <div className='file-item'>
                                <CustomFileInput id={dispatches.id} url={'upload-grant'}
                                                 requiredType={'PLANNED_DISPATCH_GRANT'} files={dispatches.files}
                                                 header={'Доверенность'} path={'dispatch'}
                                />
                            </div>
                            <div className='file-item'>
                                <CustomFileInput id={dispatches.id} multiple={true} url={'upload-other'}
                                                 requiredType={'PLANNED_DISPATCH_OTHER'} files={dispatches.files}
                                                 header={'Прочее'} path={'dispatch'}
                                />
                            </div>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <div className='search-cards'
                                 style={{width: '97%'}}>
                                <h2 style={{marginTop: '0'}}>Были отгружены:</h2>
                                <div className='new-card-container'>
                                    {dispatches.positions.map((card, index) => <NewCard card={card} key={index}/>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}