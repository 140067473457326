import {
    ICertificate,
    IChemicals, IPackingDictionary,
    IUnknownObjectNumber,
    IUnknownObjectString
} from "../interfaces/exportedInterfaces";
import Select from "react-select";
import React, {useState} from "react";
import makeAnimated from "react-select/animated";
import {SelectFields} from "../pages/RegisterPage";
import axios from "axios";
import {BACKEND_URL} from "../ConstConfig";
import '../css/CertificatesStepTwo.css'
import {useRegisterAutocomplete} from "../hooks/useRegisterAutocomplete";

interface CertificatesStepTwoProps {
    fetchedDto: ICertificate,
    packings: IPackingDictionary[],
    packError: string
}

export function CertificatesStepTwo({fetchedDto, packings, packError}: CertificatesStepTwoProps) {

    const [mark, setMark] = useState<string>(fetchedDto.meltDto.mark)
    const [batch, setBatch] = useState<string>(fetchedDto.batchDto.batch)
    const [melt, setMelt] = useState<string>(fetchedDto.meltDto.melt)
    const [manufacturer, setManufacturer] = useState<string>(fetchedDto.batchDto.manufacturer)
    const [packing, setPacking] = useState<string>(packings.filter(packing => packing.name === fetchedDto.batchDto.packing).length > 0 ? fetchedDto.batchDto.packing : '')
    //no packing fetch is present here therefore it is impossible to control required fields
    const [diameter, setDiameter] = useState<string>(fetchedDto.batchDto.diameter)
    const [chemicals, setChemicals] = useState<IUnknownObjectString>(fetchedDto.meltDto.chemicals)
    const [mechanicalProperties, setMechanicalProperties] = useState<IUnknownObjectString>(fetchedDto.batchDto.mechanicalProperties)
    const [standard, setStandard] = useState<SelectFields[]>([])
    const [tempStandards, setTempStandards] = useState<SelectFields[]>(() => {
        let temp: SelectFields[] = []
        fetchedDto.batchDto.standard.map(standard => temp = [...temp, {label: standard, value: standard}])
        return temp
    })
    const [error, setError] = useState<string>('')
    const [successMelt, setSuccessMelt] = useState<string>('')
    const [successBatch, setSuccessBatch] = useState<string>('')

    const mechs = Object.keys(mechanicalProperties).map(key => <div className='chems-mechs-container'>
        <label htmlFor={`mech${key}`}>{key}</label>
        <input className='mech-input' type="text" id={`mech${key}`} name={`mech${key}`} value={mechanicalProperties[key]} onChange={event => {
            setMechanicalProperties({...mechanicalProperties, [key]: event.target.value})
        }} required/>
    </div>)

    const chems = Object.keys(chemicals).map(key => <div className='chems-mechs-container'>
        <label htmlFor={`chem${key}`}>{key}</label>
        <input className='chem-input' type="text" id={`chem${key}`} name={`chem${key}`} value={chemicals[key]} onChange={event => {
            setChemicals({...chemicals, [key]: event.target.value})
        }} required/>
    </div>)

    const animatedComponents = makeAnimated();

    async function submitHandler(event: { preventDefault: () => void; }) {
        event.preventDefault()
        setError('')
        setSuccessMelt('')
        setSuccessBatch('')
        let temp: string[] = []
        standard.map(std => temp = [...temp, std.value])
        try {
            const responseMelt = await axios.put(BACKEND_URL + '/api/v1/melt', {
                melt: melt,
                mark: mark,
                chemicals: chemicals
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            if (responseMelt.status === 200) {
                setSuccessMelt('Данные по плавке успешно сохранены')
            }
            const responseBatch = await axios.put(BACKEND_URL + '/api/v1/batch', {
                batch: batch,
                diameter: diameter,
                melt: melt,
                manufacturer: manufacturer,
                packing: packing,
                mechanicalProperties: mechanicalProperties,
                standard: temp
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            if (responseBatch.status === 200) {
                setSuccessBatch('Данные по партии успешно сохранены')
            }
        } catch (e: unknown) {
            setError('Произошла ошибка с одним или более запросами')
        }
    }

    return (
        <>
            <form className='cert-form' onSubmit={submitHandler} autoComplete='off'>
                <h2 style={{marginTop: '25px', marginBottom: '5px'}}>Полученные данные</h2>
                {
                    error && <h3 style={{color: 'crimson'}}>{error}</h3>
                }
                {
                    successMelt && <h3 style={{color: 'green'}}>{successMelt}</h3>
                }
                {
                    successBatch && <h3 style={{color: 'green'}}>{successBatch}</h3>
                }
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    <div className='cert-input'>
                        <label htmlFor="mark">Марка</label>
                        <input type="text" id='mark' value={mark} onChange={event => setMark(event.target.value)} required/>
                    </div>
                    <div className='cert-input'>
                        <Select placeholder='Выберите ГОСТ' isMulti id='standard' value={standard} name='standard'
                                className='basic-multi-select'
                                classNamePrefix='select' options={tempStandards} closeMenuOnSelect={false}
                                components={animatedComponents} defaultValue={standard} required
                                noOptionsMessage={() => 'Нет подходящих ГОСТов'} isClearable //@ts-ignore
                                onChange={setStandard}/>
                    </div>
                </div>
                <div className='cert-input-container'>
                    <div className='cert-input'>
                        <label htmlFor="melt">Плавка</label>
                        <input type="text" id='melt' value={melt} onChange={event => setMelt(event.target.value)} required/>
                    </div>
                    <div className='cert-input'>
                        <label htmlFor="batch">Партия</label>
                        <input type="text" id='batch' value={batch} onChange={event => setBatch(event.target.value)} required/>
                    </div>
                </div>
                <div className='cert-input-container'>
                    <div className='cert-input'>
                        <label htmlFor="diameter">Диаметр</label>
                        <input type="text" id='diameter' value={diameter} onChange={event => setDiameter(event.target.value.replace(/[^.1234567890]+/g, ''))} required/>
                    </div>
                    <div className='cert-input'>
                        {
                            packError && <h2 style={{color: 'crimson'}}>Ошибка загрузки упаковок</h2>
                        }
                        <label htmlFor='packing'>Упаковка: </label>
                        <select name="packing" id="packing" required
                                onChange={event => setPacking(event.target.value)}>
                            <option selected disabled value=''>Выбрать..</option>
                            {packings.map(pack => <option value={pack.name}>{pack.name}</option>)}
                        </select>
                    </div>
                </div>
                <div className='cert-input-container'>
                    <div className='cert-input'
                         style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <label htmlFor="manu">Производитель</label>
                        <input type="text" id='manu' value={manufacturer}
                               onChange={event => setManufacturer(event.target.value)} required/>
                    </div>
                </div>
                <div className='mechs-container'>
                    <h3 style={{margin: '15px'}}>Механические свойства</h3>
                    {mechs}
                </div>
                <h3 style={{marginBottom: '0', marginTop: '20px'}}>Химические свойства</h3>
                <div className='grid-container'>
                    {chems}
                </div>
                <div>
                    <button className='send-button' type='submit'>Сохранить</button>
                </div>
            </form>
        </>
    )
}