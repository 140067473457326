import React, { useState } from "react";
import '../css/WelcomePage.css';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import {
    MainContainer,
    ChatContainer,
    MessageList,
    Message,
    MessageInput,
    TypingIndicator,
} from '@chatscope/chat-ui-kit-react';

const PROXY_API_KEY = "sk-zUMUzSdEK4FS5TNYePBmapqmJEPIEEFY";
const API_KEY = "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";

type MessageType = {
    message: string;
    sender: string;
    direction: "incoming" | "outgoing";
    position: "single" | "first" | "normal" | "last"; // Поля из MessageModel
    sentTime?: string;
};

export function WelcomePage() {
    const [messages, setMessages] = useState<MessageType[]>([
        {
            message: "Добрый день! Чем вам помочь?",
            sentTime: "just now",
            sender: "ChatGPT",
            direction: "incoming",
            position: "single"
        },
    ]);
    const [isTyping, setIsTyping] = useState(false);

    const handleSendRequest = async (message: string) => {
        const newMessage: MessageType = {
            message,
            sender: "user",
            direction: "outgoing",
            position: "single",
        };

        setMessages((prevMessages) => [...prevMessages, newMessage]);
        setIsTyping(true);

        try {
            const response = await processMessageToChatGPT([...messages, newMessage]);
            const content = response.choices[0]?.message?.content;
            if (content) {
                const chatGPTResponse: MessageType = {
                    message: content,
                    sender: "ChatGPT",
                    direction: "incoming",
                    position: 'single'
                };
                setMessages((prevMessages) => [...prevMessages, chatGPTResponse]);
            }
        } catch (error) {
            console.error("Error processing message:", error);
        } finally {
            setIsTyping(false);
        }
    };

    async function processMessageToChatGPT(chatMessages: any) {
        const apiMessages = chatMessages.map((messageObject: any) => {
            const role = messageObject.sender === "ChatGPT" ? "assistant" : "user";
            return { role, content: messageObject.message };
        });

        const apiRequestBody = {
            model: "gpt-4o-mini",
            messages: [
                { role: "system", content: "Я работник компании по продаже и производству проволоки. Не используй при ответах markdown." },
                ...apiMessages,
            ],
        };

        const response = await fetch("https://api.proxyapi.ru/openai/v1/chat/completions", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + PROXY_API_KEY,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(apiRequestBody),
        });

        return response.json();
    }

    return (
        <div className="centered-content-box">
            <h1 style={{ marginBottom: '10px' }}>Добро пожаловать,</h1>
            <h1 style={{ fontStyle: 'italic', margin: '0' }}>{localStorage.getItem('worker')}</h1>

            {
                localStorage.getItem('actions')?.includes('VIEW_CHAT_GPT') &&
                <div style={{position: "relative", height: "100%", width: "60%", marginTop: "50px"}}>
                    <MainContainer className='my-chat-container'>
                        <ChatContainer className='chat-container'>
                            <MessageList
                                className='chat-message'
                                scrollBehavior="smooth"
                                typingIndicator={isTyping ?
                                    <TypingIndicator content="ChatGPT работает во славу России..."/> : null}
                            >
                                {messages.map((message, i) => (
                                    <Message key={i} model={message}/>
                                ))}
                            </MessageList>
                            <MessageInput attachButton={false} placeholder="Отправить" onSend={handleSendRequest}
                                          className='chat-input'
                                          style={{height: "50px", width: "100%", marginTop: "20px"}}/>
                        </ChatContainer>
                    </MainContainer>
                </div>
            }
        </div>
    );
}
