import {NavLink} from "react-router-dom";
import "../css/Navigation.css"

export function PositionNavigation() {
    return (
        <nav>
            <span className='block'>
                {
                    localStorage.getItem('actions')?.includes('POSITION_INFO_BY_ID') &&
                    <NavLink className='sidebar-btn' to='info'>Информация</NavLink>
                }
                {
                    localStorage.getItem('actions')?.includes('DISPATCH') &&
                    <NavLink className='sidebar-btn' to='dispatch' >Отгрузка</NavLink>
                }
                {
                    localStorage.getItem('actions')?.includes('PRINT') &&
                    <NavLink className='sidebar-btn' to='print'>Печать</NavLink>
                }
                {
                    localStorage.getItem('actions')?.includes('PRINT_CUSTOM_WEIGHT') &&
                    <NavLink className='sidebar-btn' to='partial-print'>Печать по весу</NavLink>
                }
                {
                    localStorage.getItem('actions')?.includes('SPLIT') &&
                    <NavLink className='sidebar-btn' to='split'>Разделить</NavLink>
                }
                {
                    localStorage.getItem('actions')?.includes('POSITION_HISTORY') &&
                    <NavLink className='sidebar-btn' to='history'>История позиции</NavLink>
                }
                {/*<NavLink className='sidebar-btn' to='history'>История</NavLink>*/}
            </span>
        </nav>
    )
}