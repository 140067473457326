import React from "react";
import {IModels} from "../interfaces/exportedInterfaces";
import {Link} from "react-router-dom";

export interface ModelsProps {
    model: IModels
}

export function TableRow({model}: ModelsProps) {
    return (
        <tr>
            {
                localStorage.getItem('actions')?.includes('SEARCH') ?
                <Link target="_blank" to={`/melt-search/${model.mark.replace('/', '+')}`}><td>{model.mark}</td></Link> : <td>{model.mark}</td>
            }
            <td>{model.diameter}</td>
            <td>{model.packing}</td>
            <td>{model.solnechnogorsk} /
                {
                    localStorage.getItem('actions')?.includes('RESERVES_INFO') ?
                        <Link target='_blank' to={`/reserve/${model.mark.replace('/', '+')}/${model.diameter}/${model.packing}`}><span style={{fontWeight: model.solnechnogorskReserve === '0' ? 'normal' : "bold"}}> {model.solnechnogorskReserve}</span></Link>
                        :
                        <span style={{fontWeight: model.solnechnogorskReserve === '0' ? 'normal' : "bold"}}> {model.solnechnogorskReserve}</span>
                }
                </td>
            <td>{model.belSklad} /
                {
                    localStorage.getItem('actions')?.includes('RESERVES_INFO') ?
                        <Link target='_blank' to={`/reserve/${model.mark.replace('/', '+')}/${model.diameter}/${model.packing}`}><span style={{fontWeight: model.belSkladReserve === '0' ? 'normal' : "bold"}}> {model.belSkladReserve}</span></Link>
                        :
                        <span style={{fontWeight: model.belSkladReserve === '0' ? 'normal' : "bold"}}> {model.belSkladReserve}</span>
                }
            </td>
            <td>{model.manufacture} /
                {
                    localStorage.getItem('actions')?.includes('RESERVES_INFO') ?
                        <Link target='_blank' to={`/reserve/${model.mark.replace('/', '+')}/${model.diameter}/${model.packing}`}><span style={{fontWeight: model.manufactureReserve === '0' ? 'normal' : "bold"}}> {model.manufactureReserve}</span></Link>
                        :
                        <span style={{fontWeight: model.manufactureReserve === '0' ? 'normal' : "bold"}}> {model.manufactureReserve}</span>
                }
            </td>
            <td>{model.matrosovo} /
                {
                    localStorage.getItem('actions')?.includes('RESERVES_INFO') ?
                        <Link target='_blank' to={`/reserve/${model.mark.replace('/', '+')}/${model.diameter}/${model.packing}`}><span style={{fontWeight: model.matrosovoReserve === '0' ? 'normal' : "bold"}}> {model.matrosovoReserve}</span></Link>
                        :
                        <span style={{fontWeight: model.matrosovoReserve === '0' ? 'normal' : "bold"}}> {model.matrosovoReserve}</span>
                }
            </td>
        </tr>
    )
}