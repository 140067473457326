import {Link} from "react-router-dom";
import axios, {AxiosError} from "axios";
import '../css/CardIdViewShow.css'
import {CardView} from "./CardView";
import {BACKEND_URL} from "../ConstConfig";
import React, {useState} from "react";
import {useCard} from "../pages/PositionPage";
import {ModalWindowAddPlav} from "./ModalWindowAddPlav";
import {ModalWindowAddPart} from "./ModalWindowAddPart";
import svg from '../assets/edit-comment.svg';
import {Bounce, ToastContainer, toast} from "react-toastify";
import {useMarksDictionary} from "../hooks/useMarksDictionary";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {SelectFields} from "../pages/RegisterPage";
import {NewCard} from "./NewCard";
import {usePackingsDictionary} from "../hooks/usePackingsDictionary";



export function PositionDetails() {

    const {card, places} = useCard()

    const animatedComponents = makeAnimated();

    const refetchFlag = true
    const {marks, error: ticketMarksError} = useMarksDictionary(refetchFlag)
    const {packings, error: ticketPackingsError} = usePackingsDictionary(refetchFlag)

    const [editMode, setEditMode] = useState(false)
    const [changeCommentMode, setChangeCommentMode] = useState<boolean>(false)
    const [changePlaceMode, setChangePlaceMode] = useState<boolean>(false)
    const [status, setStatus] = useState<string>(() => {
        if (card.status === 'В наличии') {
            return 'In_stock'
        } else if (card.status === 'Резерв') {
            return 'Reserve'
        } else if (card.status === 'В пути на склад') {
            return 'Arriving'
        } else {
            return 'Departured'
        }
    })
    const [type] = useState<string>(() => {
        if (card.type === 'Позиция') {
            return 'POSITION'
        } else {
            return 'PACKAGE'
        }
    })
    const [mark, setMark] = useState<string>(card.mark)
    const [diameter, setDiameter] = useState<string>(card.diameter)
    const [part, setPart] = useState<string>(card.part)
    const [plav, setPlav] = useState<string>(card.plav)
    const [packing, setPacking] = useState<string>(card.packing)
    const [weight, setWeight] = useState<string>(card.weight)
    const [manufacturer, setManufacturer] = useState<string>(card.manufacturer)
    const [location, setLocation] = useState<string>(card.location)
    const [comment, setComment] = useState<string>(card.comment)

    const [error, setError] = useState<string>('')
    const [commentError, setCommentError] = useState<string>('')

    const [placeError, setPlaceError] = useState<string>('')
    const [place, setPlace] = useState<string>('')

    const [plavModal, setPlavModal] = useState<boolean>(false)
    const [partModal, setPartModal] = useState<boolean>(false)

    const [checkedMark, setCheckedMark] = useState<boolean>(true)

    const [createTicketMode, setCreateTicketMode] = useState<boolean>(false)

    const [ticketComment, setTicketComment] = useState<string>('')
    const [ticketStandards, setTicketStandards] = useState<SelectFields[]>([])
    const [ticketMark, setTicketMark] = useState<string>('')
    const [ticketDiameter, setTicketDiameter] = useState<string>(card.diameter)
    const [positionComment, setPositionComment] = useState<string>(card.comment)
    const [ticketPart, setTicketPart] = useState<string>(card.part)
    const [ticketPlav, setTicketPlav] = useState<string>(card.plav)
    const [ticketManufacturer, setTicketManufacturer] = useState<string>(card.manufacturer)
    const [ticketWeight, setTicketWeight] = useState<string>(card.weight)
    const [ticketLocation, setTicketLocation] = useState<string>(card.location)
    const [ticketPacking, setTicketPacking] = useState<string>('')
    const [ticketStatus, setTicketStatus] = useState<string>(() => {
        if (card.status === 'В наличии') {
            return 'In_stock'
        } else if (card.status === 'Резерв') {
            return 'Reserve'
        } else if (card.status === 'В пути на склад') {
            return 'Arriving'
        } else if (card.status === 'Брак') {
            return 'Defect'
        } else if (card.status === 'Списано') {
            return 'Scrapped'
        } else if (card.status === 'Подготовлено к отгрузке') {
            return 'Prepared_for_dispatch'
        } else {
            return 'Departured'
        }
    })

    const standardOptions = () => {
        let temp: SelectFields[] = []
        marks.find(mark => mark.grade === ticketMark)?.standards.forEach(std => temp = [...temp, {value: std, label: std}])
        return temp
    }

    async function sendTicket() {
        setError('')
        if ((packings.find(packing => packing.name === ticketPacking)?.requiredFields.includes('diameter') && ticketDiameter === '') ||
            (packings.find(packing => packing.name === ticketPacking)?.requiredFields.includes('weight') && ticketWeight === '') ||
            (packings.find(packing => packing.name === ticketPacking)?.requiredFields.includes('part') && ticketPart === '') ||
            (packings.find(packing => packing.name === ticketPacking)?.requiredFields.includes('plav') && ticketPlav === '') ||
            (packings.find(packing => packing.name === ticketPacking)?.requiredFields.includes('manufacturer') && ticketManufacturer === '') ||
            ticketMark === '' || ticketStandards.length === 0) {
            toast.error('Заполните обязательные поля', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
        } else {
            const loc = () => {
                if (location === 'Белорецк(Склад)') {
                    return 'BelSklad'
                } else if (location === 'Белорецк(Производство)') {
                    return 'Manufacture'
                } else if (location === 'Солнечногорск') {
                    return 'Solnechnogorsk'
                } else {
                    return 'Matrosovo'
                }
            }
            let temp: string[] = []
            ticketStandards.forEach(std => temp = [...temp, std.value])
            try {
                const response = await axios.post(BACKEND_URL + '/api/v1/ticket', {
                    comment: ticketComment,
                    pendingRequest: {
                        type: type,
                        id: card.id,
                        mark: ticketMark,
                        diameter: Number(ticketDiameter),
                        packing: ticketPacking,
                        comment: positionComment,
                        part: ticketPart,
                        plav: ticketPlav,
                        manufacturer: ticketManufacturer,
                        weight: Number(ticketWeight),
                        status: ticketStatus,
                        location: loc(),
                        standards: temp
                    }
                }, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                })
                if (response.status === 200) {
                    toast.success('Тикет успешно создан', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                    })
                }
            } catch (e: unknown) {
                setError('Не удалось отправить тикет')
                toast.error('Не удалось отправить тикет', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                })
            }
        }

    }

    async function sendRequest() {
        setError('')
        try {
            const loc = () => {
                if (location === 'Белорецк(Склад)') {
                    return 'BelSklad'
                } else if (location === 'Белорецк(Производство)') {
                    return 'Manufacture'
                } else if (location === 'Солнечногорск') {
                    return 'Solnechnogorsk'
                } else {
                    return 'Matrosovo'
                }
            }
            const response = await axios.post(BACKEND_URL + '/api/v1/admin/edit', {
                type: type,
                id: card.id,
                mark: mark,
                diameter: Number(diameter),
                packing: packing,
                comment: comment,
                part: part,
                plav: plav,
                manufacturer: manufacturer,
                weight: Number(weight),
                status: status,
                location: loc(),
                standards: card.standards
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            if (response.status === 200) {
                window.location.reload()
            }
        } catch (e: unknown) {
            setError('Не удалось изменить позицию')
        }
    }

    async function sendCommentRequest() {
        setCommentError('')
        try {
            const response = await axios.post(BACKEND_URL + '/api/v1/position/' + card.id, {
                comment: comment
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            if (response.status === 200) {
                window.location.reload()
            }
        } catch (e) {
            setCommentError('Не удалось изменить комментарий')
        }
    }

    async function sendPlaceRequest() {
        setPlaceError('')
        if (place === '') {
            setPlaceError('Вы не выбрали место')
        } else {
            try {
                const response = await axios.post(BACKEND_URL + '/api/v1/storage-location/position/' + card.id + '?storageLocation=' + place, {}, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                })
                if (response.status === 200) {
                    window.location.reload()
                }
            } catch (e) {
                setPlaceError('Не удалось присвоить место позиции')
            }
        }
    }

    function checkMark(selectedId: string) {
        places.find(plc => plc.storageLocation.id === selectedId)?.storageLocation.positions.map(pos => {
            if (pos.mark !== card.mark) {
                setCheckedMark(false)
            }
        })
    }

    return (
        <>
            {
                localStorage.getItem('actions')?.includes('POSITION_INFO_BY_ID') &&
                <>
                    {plavModal && <ModalWindowAddPlav openModal={setPlavModal}
                                                      predefinedMark={card.mark}
                                                      predefinedPlav={card.plav}
                    />}
                    {partModal && <ModalWindowAddPart openModal={setPartModal}
                                                      predefinedPart={card.part} predefinedPlav={card.plav}
                                                      predefinedDiameter={card.diameter}
                                                      predefinedManufacturer={card.manufacturer}
                                                      predefinedPacking={card.packing}
                                                      predefinedStandards={card.standards}
                    />}
                    <div className='dispatch-body'>
                        <div className='container'>
                            {
                                createTicketMode && ticketMarksError &&
                                <h2 style={{color: 'crimson'}}>Селект для марок недоступен</h2>
                            }
                            {
                                createTicketMode && ticketPackingsError &&
                                <h2 style={{color: 'crimson'}}>Селект для упаковок недоступен</h2>
                            }
                            <table className='one-card-table' style={{marginBottom: '20px'}}>
                                <tr>
                                    <td className='header'>Тип</td>
                                    <td>{card.type}</td>
                                </tr>
                                <tr>
                                    <td className='header'>Марка</td>
                                    {
                                        !editMode && !createTicketMode &&
                                        <td>{card.mark}</td>
                                    }
                                    {
                                        editMode &&
                                        <td>
                                            <input className='edit-mode-input' type="text" value={mark}
                                                   onChange={event => setMark(event.target.value)}/>
                                        </td>
                                    }
                                    {
                                        createTicketMode &&
                                        <td>
                                            <select name="" id="" value={ticketMark}
                                                    onChange={event => setTicketMark(event.target.value)} required>
                                                <option selected disabled value="">Выберите марку</option>
                                                {marks.map((mark, index) => <option key={index} value={mark.grade}>{mark.grade}</option>)}
                                            </select>
                                        </td>
                                    }
                                </tr>
                                {
                                    createTicketMode &&
                                    <tr>
                                        <td className='header'>Стандарты</td>
                                        <td>
                                            <Select placeholder='Выберите стандарты..' isMulti id='standards' value={ticketStandards} name='standards'
                                                    className='basic-multi-select'
                                                    classNamePrefix='select' options={standardOptions()} closeMenuOnSelect={false}
                                                    components={animatedComponents} defaultValue={ticketStandards} required
                                                    noOptionsMessage={() => 'Нет подходящих стандартов'}
                                                    isClearable //@ts-ignore
                                                    onChange={setTicketStandards}/>
                                        </td>
                                    </tr>
                                }
                                <tr>
                                    <td className='header'>Диаметр</td>
                                    {
                                        !editMode && !createTicketMode &&
                                        <td>{card.diameter}</td>
                                    }
                                    {
                                        editMode &&
                                        <td>
                                            <input className='edit-mode-input' type="text" value={diameter}
                                                   onChange={event => setDiameter(event.target.value.replace(/[^.1234567890]+/g, ''))}/>
                                        </td>
                                    }
                                    {
                                        createTicketMode &&
                                        <td>
                                            <input className='edit-mode-input' type="text" value={ticketDiameter}
                                                   onChange={event => setTicketDiameter(event.target.value)}/>
                                        </td>
                                    }
                                </tr>
                                <tr>
                                    <td className='header'>Упаковка</td>
                                    {
                                        !editMode && !createTicketMode &&
                                        <td>{card.packing}</td>
                                    }
                                    {
                                        editMode &&
                                        <td>
                                            <input className='edit-mode-input' type="text" value={packing}
                                                   onChange={event => setPacking(event.target.value)}/>
                                        </td>
                                    }
                                    {
                                        createTicketMode &&
                                        <select name="" id="" value={ticketPacking}
                                                onChange={event => setTicketMark(event.target.value)} required>
                                            <option selected disabled value="">Выберите упаковку</option>
                                            {packings.map((packing, index) => <option key={index}
                                                                                value={packing.name}>{packing.name}</option>)}
                                        </select>
                                    }
                                </tr>
                                <tr>
                                    <td className='header'>Партия</td>
                                    {
                                        !editMode && !createTicketMode &&
                                        <td>{card.part}</td>
                                    }
                                    {
                                    editMode &&
                                        <td>
                                            <input className='edit-mode-input' type="text" value={part}
                                                   onChange={event => setPart(event.target.value)}/>
                                        </td>
                                    }
                                    {
                                        createTicketMode &&
                                        <td>
                                            <input className='edit-mode-input' type="text" value={ticketPart}
                                                   onChange={event => setTicketPart(event.target.value)}/>
                                        </td>
                                    }
                                </tr>
                                <tr>
                                    <td className='header'>Плавка</td>
                                    {
                                        !editMode && !createTicketMode &&
                                        <td>{card.plav}</td>
                                    }
                                    {
                                        editMode &&
                                        <td>
                                            <input className='edit-mode-input' type="text" value={plav}
                                                   onChange={event => setPlav(event.target.value)}/>
                                        </td>
                                    }
                                    {
                                        createTicketMode &&
                                        <td>
                                            <input className='edit-mode-input' type="text" value={ticketPlav}
                                                   onChange={event => setTicketPlav(event.target.value)}/>
                                        </td>
                                    }
                                </tr>
                                <tr>
                                    <td className='header'>Вес</td>
                                    {
                                        !editMode && !createTicketMode &&
                                        <td>{card.weight}</td>
                                    }
                                    {
                                        editMode &&
                                        <td>
                                            <input className='edit-mode-input' type="text" value={weight}
                                                   onChange={event => setWeight(event.target.value.replace(/[^.1234567890]+/g, ''))}/>
                                        </td>
                                    }
                                    {
                                        createTicketMode &&
                                        <td>
                                            <input className='edit-mode-input' type="text" value={ticketWeight}
                                                   onChange={event => setTicketWeight(event.target.value)}/>
                                        </td>
                                    }
                                </tr>
                                <tr>
                                    <td className='header'>Статус</td>
                                    {
                                        !editMode && !createTicketMode &&
                                        <td>{card.status}</td>
                                    }
                                    {
                                        editMode &&
                                        <td>
                                            <select name="" id="" value={status}
                                                    onChange={event => setStatus(event.target.value)} required>
                                                <option value="Arriving">В пути на склад</option>
                                                <option value="In_stock">В наличии</option>
                                                <option value="Reserve">Резерв</option>
                                                <option value="Departured">Отгружено</option>
                                                <option value="Prepared_for_dispatch">Подготовлено к отгрузке</option>
                                                <option value="Defect">Брак</option>
                                                <option value="Scrapped">Списано</option>
                                            </select>
                                        </td>
                                    }
                                    {
                                        createTicketMode &&
                                        <td>
                                            <select name="" id="" value={ticketStatus}
                                                    onChange={event => setTicketStatus(event.target.value)} required>
                                                <option value="Arriving">В пути на склад</option>
                                                <option value="In_stock">В наличии</option>
                                                <option value="Reserve">Резерв</option>
                                                <option value="Departured">Отгружено</option>
                                                <option value="Prepared_for_dispatch">Подготовлено к отгрузке</option>
                                                <option value="Defect">Брак</option>
                                                <option value="Scrapped">Списано</option>
                                            </select>
                                        </td>
                                    }
                                </tr>
                                <tr>
                                    <td className='header'>Производитель</td>
                                    {
                                        !editMode && !createTicketMode &&
                                        <td>{card.manufacturer}</td>
                                    }
                                    {
                                        editMode &&
                                        <td>
                                            <input className='edit-mode-input' type="text" value={manufacturer}
                                                   onChange={event => setManufacturer(event.target.value)}/>
                                        </td>
                                    }
                                    {
                                        createTicketMode &&
                                        <td>
                                            <input className='edit-mode-input' type="text" value={ticketManufacturer}
                                                   onChange={event => setTicketManufacturer(event.target.value)}/>
                                        </td>
                                    }
                                </tr>
                                <tr>
                                    <td style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                                        className='header'>
                                        {
                                            !editMode && !changePlaceMode && !createTicketMode && localStorage.getItem('actions')?.includes('COMMENT_EDIT') &&
                                            <img className='edit-comment-btn' width='24px' src={svg} alt=""
                                                 onClick={() => setChangeCommentMode(true)}/>
                                        }
                                        Комментарий
                                    </td>
                                    {
                                        !editMode && !changeCommentMode && card.comment.length === 0 && !createTicketMode &&
                                        <td>-</td>
                                    }
                                    {
                                        !editMode && !changeCommentMode && !createTicketMode && card.comment.length > 0 &&
                                        <td>{card.comment}</td>
                                    }
                                    {
                                        editMode && !changeCommentMode && !createTicketMode &&
                                        <td>
                                            <input className='edit-mode-input' type="text" value={comment}
                                                   onChange={event => setComment(event.target.value)}/>
                                        </td>
                                    }
                                    {
                                        changeCommentMode && !editMode && !createTicketMode &&
                                        <td>
                                            <input className='edit-mode-input' type="text" value={comment}
                                                   onChange={event => setComment(event.target.value)}/>
                                        </td>
                                    }
                                    {
                                        createTicketMode && !changeCommentMode && !editMode &&
                                        <td>
                                            <input className='edit-mode-input' type="text" value={positionComment}
                                                   onChange={event => setPositionComment(event.target.value)}/>
                                        </td>
                                    }
                                </tr>
                                <tr>
                                    <td className='header'>Локация</td>
                                    {
                                        !editMode && !createTicketMode &&
                                        <td>{card.location}</td>
                                    }
                                    {
                                        editMode &&
                                        <td>
                                            <select name="" id="" value={location}
                                                    onChange={event => setLocation(event.target.value)} required>
                                                <option value="Белорецк(Производство)">Белорецк(Производство)</option>
                                                <option value="Белорецк(Склад)">Белорецк(Склад)</option>
                                                <option value="Солнечногорск">Солнечногорск</option>
                                                <option value="Матросово">Матросово</option>
                                            </select>
                                        </td>
                                    }
                                    {
                                        createTicketMode &&
                                        <td>
                                            <select name="" id="" value={ticketLocation}
                                                    onChange={event => setTicketLocation(event.target.value)} required>
                                                <option value="Белорецк(Производство)">Белорецк(Производство)</option>
                                                <option value="Белорецк(Склад)">Белорецк(Склад)</option>
                                                <option value="Солнечногорск">Солнечногорск</option>
                                                <option value="Матросово">Матросово</option>
                                            </select>
                                        </td>
                                    }
                                </tr>
                                <tr>
                                    <td className='header'>Место на складе</td>
                                    {
                                        !changePlaceMode &&
                                        <td>Ряд {card.storageLocation !== null ? card.storageLocation.row : '-'},
                                            место {card.storageLocation !== null ? card.storageLocation.place : '-'},
                                            тип {card.storageLocation !== null ? card.storageLocation.storageType : '-'}</td>
                                    }
                                    {
                                        changePlaceMode &&
                                        <td>
                                            <select required name="place" placeholder='Место' value={place}
                                                    onChange={event => {
                                                        setPlace(event.target.value)
                                                        checkMark(event.target.value)
                                                    }}>
                                                <option selected disabled value=''>Выберите место</option>
                                                {places.sort((a, b) => a.positionCount - b.positionCount).map((unit, index) =>
                                                    <option value={unit.storageLocation.id}
                                                            key={index}>Ряд {unit.storageLocation.row},
                                                        место {unit.storageLocation.place},
                                                        тип {unit.storageLocation.storageType} {unit.positionCount > 0 ? `(Позиций: ${unit.positionCount})` : '(Пусто)'}</option>)}
                                            </select>
                                        </td>
                                    }
                                </tr>
                                <tr>
                                    <td className='header'>ID места</td>
                                    <td>{card.storageLocation !== null ? card.storageLocation.id : '-'}</td>
                                </tr>
                                <tr>
                                    <td className='header'>Дата</td>
                                    <td>{card.date}</td>
                                </tr>
                                {
                                    card.type === 'Позиция' && card.pack !== null &&
                                    <tr>
                                        <td className='header'>Поддон</td>
                                        <td><Link to={'/position/' + card.pack + '/info'}>{card.pack}</Link></td>
                                    </tr>
                                }
                                {
                                    createTicketMode &&
                                    <tr>
                                        <td className='header'>Комментарий к тикету</td>
                                        <td>
                                            <input className='edit-mode-input' type="text" value={ticketComment}
                                                   onChange={event => setTicketComment(event.target.value)}/>
                                        </td>
                                    </tr>
                                }
                            </table>
                            {checkedMark && place &&
                                <h2 style={{color: '#68af68'}}>Место доступно и не содержит других марок</h2>}
                            {!checkedMark && place &&
                                <h2 style={{color: 'rgb(253, 185, 0)'}}>Место содержит другие марки</h2>}
                            {error && <h2 style={{color: 'red', marginTop: '-15px'}}>{error}</h2>}
                            {commentError && <h2 style={{color: 'red', marginTop: '-15px'}}>{commentError}</h2>}
                            {placeError && <h2 style={{color: 'red', marginTop: '-15px'}}>{placeError}</h2>}
                            <div className='cancel-changedate-container' style={{marginTop: '0'}}>
                                {
                                    !createTicketMode && !editMode && !changeCommentMode && !changePlaceMode && card.location === 'Матросово' && localStorage.getItem('actions')?.includes('ASSIGN_STORAGE_LOCATION') &&
                                    <button type='button' className='btn-position-edit' style={{marginRight: '15px'}}
                                            onClick={() => setChangePlaceMode(true)}>Назначить место</button>
                                }
                                {
                                    !createTicketMode && !editMode && !changeCommentMode && !changePlaceMode && localStorage.getItem('actions')?.includes('EDIT_POSITION') &&
                                    <button type='button' className='btn-position-edit' style={{marginRight: '15px'}}
                                            onClick={() => setEditMode(true)}>Редактировать поля</button>
                                }
                                {
                                    !createTicketMode && !editMode && !changeCommentMode && !changePlaceMode && localStorage.getItem('actions')?.includes('CREATE_TICKETS') &&
                                    <button type='button' className='btn-position-edit'
                                            onClick={() => setCreateTicketMode(true)}>Запрос на изменение</button>
                                }
                            </div>
                            {
                                createTicketMode && !editMode && !changeCommentMode && !changePlaceMode && localStorage.getItem('actions')?.includes('CREATE_TICKETS') &&
                                <div className='cancel-changedate-container' style={{marginTop: '0'}}>
                                    <button type='button' className='save-btn' style={{marginRight: '15px'}}
                                            onClick={() => sendTicket()}>Сохранить
                                    </button>
                                    <button type='button'
                                            onClick={() => setCreateTicketMode(false)}>Отмена
                                    </button>
                                </div>
                            }
                            {
                                !createTicketMode && changePlaceMode && !changeCommentMode && !editMode &&
                                <div className='cancel-changedate-container' style={{marginTop: '0'}}>
                                    <button type='button' style={{marginRight: '15px'}} className='save-btn' onClick={() => {
                                        setChangePlaceMode(false)
                                        sendPlaceRequest()
                                    }}>Подтвердить место
                                    </button>
                                    <button type='button'
                                            onClick={() => setChangePlaceMode(false)}>Отменить назначение
                                    </button>
                                </div>
                            }
                            {
                                editMode && !changeCommentMode && !changePlaceMode && !createTicketMode &&
                                <div className='cancel-changedate-container' style={{marginTop: '0'}}>
                                    <button type='button' style={{marginRight: '15px'}} className='save-btn' onClick={() => {
                                        setEditMode(false)
                                        sendRequest()
                                    }}>Подтвердить поля
                                    </button>
                                    <button type='button'
                                            onClick={() => setEditMode(false)}>Отменить редактирование
                                    </button>
                                </div>
                            }
                            {
                                changeCommentMode && !editMode && !changePlaceMode && !createTicketMode &&
                                <div className='cancel-changedate-container' style={{marginTop: '0'}}>
                                    <button type='button' style={{marginRight: '15px'}} className='save-btn' onClick={() => {
                                        setChangeCommentMode(false)
                                        sendCommentRequest()
                                    }}>Сохранить
                                    </button>
                                    <button type='button'
                                            onClick={() => setChangeCommentMode(false)}>Отменить редактирование
                                    </button>
                                </div>
                            }

                            {
                                'chemicals' in card && localStorage.getItem('actions')?.includes('MELT_INFO') &&
                                <div className='mainbar-container'>
                                    <table className='one-card-table-chems' style={{marginTop: '30px', marginBottom: '0'}}>
                                        <tr>
                                            <td className='normal-header'
                                                colSpan={Object.keys(card.chemicals).length}>Химические свойства
                                            </td>
                                        </tr>
                                        <tr className='row-chem'>
                                            {Object.keys(card.chemicals).map(chem => <td
                                                className='header-chem'>{chem}</td>)}
                                        </tr>
                                        <tr className='row-chem'>
                                            {Object.keys(card.chemicals).map(chem => <td
                                                className='value-chem'>{card.chemicals[chem]}</td>)}
                                        </tr>
                                    </table>
                                </div>
                            }

                            {
                                'mechanicalProperties' in card && localStorage.getItem('actions')?.includes('BATCH_INFO') &&
                                <div className='mainbar-container'>
                                    <table className='one-card-table'
                                           style={{marginTop: '30px', width: '94%', height: '250px'}}>
                                        <tr>
                                            <td className='normal-header'
                                                colSpan={Object.keys(card.mechanicalProperties).length}>Механические
                                                свойства
                                            </td>
                                        </tr>
                                        <tr>
                                            {Object.keys(card.mechanicalProperties).map(mech => <td
                                                className='header-mechs'>{mech}</td>)}
                                        </tr>
                                        <tr>
                                            {Object.keys(card.mechanicalProperties).map(mech => <td
                                                className='value-mechs'>{card.mechanicalProperties[mech]}</td>)}
                                        </tr>
                                    </table>
                                </div>
                            }

                            {
                                !('chemicals' in card) && localStorage.getItem('actions')?.includes('MELT_INFO') && localStorage.getItem('actions')?.includes('ADD_MELT') &&
                                <div style={{marginBottom: '30px'}}>
                                    <h2 style={{marginTop: '25px'}}>Отсутствует информация о плавке</h2>
                                    <button className='position-split-confirm-btn' type='button'
                                            onClick={() => setPlavModal(true)}>Добавить плавку
                                    </button>
                                </div>
                            }

                            {
                                !('mechanicalProperties' in card) && ('chemicals' in card) && localStorage.getItem('actions')?.includes('BATCH_INFO') && localStorage.getItem('actions')?.includes('ADD_BATCH') &&
                                <div style={{marginBottom: '30px'}}>
                                    <h2 style={{marginTop: '25px'}}>Отсутствует информация о партии</h2>
                                    <button className='position-split-confirm-btn' type='button'
                                            onClick={() => setPartModal(true)}>Добавить партию
                                    </button>
                                </div>
                            }

                            <div className='search-cards'>
                                {
                                    card.type === 'Поддон' && card.positions.length > 0 &&
                                    // <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                                    //     <div style={{width: '97%'}}>
                                    //         <h2>Содержит:</h2>
                                    //         <CardView cards={card.positions}/>
                                    //     </div>
                                    // </div>
                                    <>
                                        <h2 style={{marginTop: '0'}}>Содержит:</h2>
                                        <div className='new-card-container'>
                                            {card.positions.map((position, index) => <NewCard card={card}
                                                                                              key={index}/>)}
                                        </div>
                                    </>

                                }
                            </div>
                        </div>
                    </div>
                    <ToastContainer/>
                </>
            }
        </>
    )
}