import ReactDom from "react-dom";
import React, {ChangeEvent, useState} from "react";
import axios, {AxiosError} from "axios";
import {BACKEND_URL} from "../ConstConfig";
import {useRegisterAutocomplete} from "../hooks/useRegisterAutocomplete";
import {SelectFields} from "../pages/RegisterPage";
import Select from "react-select";
import makeAnimated from "react-select/animated";

interface InputFieldsPartProps {
    property: string,
    content: string
}

interface ModalWindowAddPartProps {
    openModal: (prop: boolean) => void,
    predefinedPart?: string,
    predefinedPlav?: string,
    predefinedManufacturer?: string,
    predefinedDiameter?: string,
    predefinedPacking?: string,
    predefinedStandards?: string[]
}

export function ModalWindowAddPart({openModal, predefinedPart, predefinedPlav,
                                       predefinedManufacturer, predefinedDiameter,
                                       predefinedPacking, predefinedStandards}: ModalWindowAddPartProps) {

    const portalElement: HTMLElement = document.getElementById('portal')!

    const {standards, packings, error} = useRegisterAutocomplete()

    const [errorRequest, setErrorRequest] = useState<string>('')
    const [errorMark, setErrorMark] = useState<string>('')
    const [part, setPart] = useState<string>(() => {
        return predefinedPart ? predefinedPart : ''
    })
    const [diameter, setDiameter] = useState<string>(() => {
        return predefinedDiameter ? predefinedDiameter : ''
    })
    const [plav, setPlav] = useState<string>(() => {
        return predefinedPlav ? predefinedPlav : ''
    })
    const [manufacturer, setManufacturer] = useState<string>(() => {
        return predefinedManufacturer ? predefinedManufacturer : ''
    })
    const [chosenMark, setChosenMark] = useState<string>('')
    const [initialLength, setInitialLength] = useState<number>(0)
    const [packing, setPacking] = useState<string>(() => {
        return predefinedPacking ? predefinedPacking : ''
    })
    const [standard, setStandard] = useState<SelectFields[]>([])
    const [tempStandards, setTempStandards] = useState<SelectFields[]>(() => {
        if (predefinedStandards) {
            let temp: SelectFields[] = []
            predefinedStandards.map(standard => temp = [...temp, {label: standard, value: standard}])
            return temp
        } else {
            return []
        }
    })
    const [inputFields, setInputFields] = useState<InputFieldsPartProps[]>([
        {property: '', content: ''}
    ])
    const options = ['Вр. сопр. р-ву (МПа)', 'Вр. сопр. р-ву (кгс/мм2)', 'Сост. пов-сти', 'Сод. феррит. фазы', 'Отн. удлинение', 'Удел. эл. сопр', 'Живучесть']
    const disabled = [false, false, false, false, false, false, false]
    const [disabledFields, setDisabledFields] = useState(disabled)

    const animatedComponents = makeAnimated();

    const handleOnChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>, index: number) => {
        let data: InputFieldsPartProps[]  = [...inputFields]
        data[index][event.target.name as keyof InputFieldsPartProps] = event.target.value.replace(/[^.1234567890-]+/g, '')
        setInputFields(data)
    }

    const handleOnChangeSurface = (event: ChangeEvent<HTMLSelectElement>, index: number) => {
        let data: InputFieldsPartProps[]  = [...inputFields]
        data[index][event.target.name as keyof InputFieldsPartProps] = event.target.value
        setInputFields(data)
    }

    const handleOnChangeSelect = (event: ChangeEvent<HTMLSelectElement>, index: number) => {
        let data: InputFieldsPartProps[]  = [...inputFields]
        let temp = [...disabledFields]

        if (data[index].property === '') {
            temp[options.indexOf(event.target.value)] = true
            setDisabledFields(temp)
        } else {
            temp[options.indexOf(inputFields[index].property)] = false
            temp[options.indexOf(event.target.value)] = true
            setDisabledFields(temp)
        }

        data[index][event.target.name as keyof InputFieldsPartProps] = event.target.value
        setInputFields(data)
    }

    const addFields = () => {
        let newField = {property: '', content: ''}
        setInputFields([...inputFields, newField])
    }

    const removeFields = (index: number) => {
        let data = [...inputFields]

        if (data[index].property !== '') {
            let temp = [...disabledFields]
            temp[options.indexOf(inputFields[index].property)] = false
            setDisabledFields(temp)
        }

        data.splice(index, 1)
        setInputFields(data)
    }

    function findStandardOptions(input: string) {
        const temp = standards.find(element => element.mark.toLowerCase() === input.toLowerCase())
        if (temp !== undefined) {
            let appropriateOptions: SelectFields[] = []
            temp.standards.map((string) => appropriateOptions = [...appropriateOptions, {
                value: string,
                label: string
            }])
            setTempStandards(appropriateOptions)
        } else {
            setTempStandards([])
        }
    }

    async function sendRequest(event: { preventDefault: () => void; }) {
        event.preventDefault()
        setErrorRequest('')
        try {
            let standardArray: string[] = []
            standard.map(element => standardArray = [...standardArray, element.value])
            const response = await axios.put(BACKEND_URL + '/api/v1/batch', {
                batch: part.trim(),
                diameter: diameter.trim(),
                melt: plav.trim(),
                packing: packing.trim(),
                manufacturer: manufacturer.trim(),
                standard: standardArray,
                mechanicalProperties: {
                    'Временное сопротивление разрыву, кгс/мм2': inputFields.find((el) => el.property === 'Вр. сопр. р-ву (кгс/мм2)')?.content.trim(),
                    'Временное сопротивление разрыву, МПа': inputFields.find((el) => el.property === 'Вр. сопр. р-ву (МПа)')?.content.trim(),
                    'Состояние поверхности': inputFields.find((el) => el.property === 'Сост. пов-сти')?.content.trim(),
                    'Содержание ферритной фазы, %': inputFields.find((el) => el.property === 'Сод. феррит. фазы')?.content.trim(),
                    'Удельное электрическое сопротивление, мкОм*м': inputFields.find((el) => el.property === 'Удел. эл. сопр')?.content.trim(),
                    'Относительное удлинение, %': inputFields.find((el) => el.property === 'Отн. удлинение')?.content.trim(),
                    'Живучесть, ч/°С': inputFields.find((el) => el.property === 'Живучесть')?.content.trim()
                }
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            if (response.status === 200) {
                window.location.reload()
            }
        } catch (e: unknown) {
            const error = e as AxiosError
            setErrorRequest(error.message)
        }
    }

    async function getMark() {
        setErrorMark('')
        try {
            const response = await axios.post(BACKEND_URL + '/api/v1/melt', {
                name: plav
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            if (response.data.mark !== undefined) {
                setChosenMark(response.data.mark)
                setInitialLength(response.data.mark.length)
                findStandardOptions(response.data.mark)
            } else {
                setChosenMark('')
                setErrorMark('Не удалось установить марку')
            }
        } catch (e) {
            setErrorMark('Ошибка запроса')
        }
    }

    // function showAutocomplete(input: string, items: string[], setState: (prop: string) => void,
    //                           setItemAutocomplete: (prop: boolean) => void,
    //                           ulClass: string, liClass: string, emClass: string) {
    //     if (items.filter((item) => item.toLowerCase().includes(input.toLowerCase())).length) {
    //         return (
    //             <ul className={ulClass}>
    //                 {items.filter((item) => item.toLowerCase().includes(input.toLowerCase()))
    //                     .map((item, index) =>
    //                         <li className={liClass} key={index} onMouseOver={() => setUlFocus(true)}
    //                             onMouseOut={() => setUlFocus(false)}
    //                             onClick={event => {
    //                                 setManufacturer(event.currentTarget.innerText)
    //                                 setManufacturerAutocomplete(false)
    //                                 setUlFocus(false)
    //                             }}>{item}</li>
    //                     )}
    //             </ul>
    //         )
    //     } else {
    //         return (
    //             <div className={emClass}>
    //                 <em>Нет элементов</em>
    //             </div>
    //         )
    //     }
    // }

    return ReactDom.createPortal(
        <form className='modalWindow' onSubmit={sendRequest} autoComplete='off'>
            <div className='modalBackground' id='bg' onClick={() => openModal(false)}/>
            <div className='modalContainer' id='container'>
                <div className='titleCloseBtn'>
                    <button type='button' id='closeBtn' onClick={() => openModal(false)}>X</button>
                </div>
                <div className='title-plavs'>
                    <h1>Добавление партии</h1>
                    {errorRequest && <h3 style={{color: 'red'}}>Ошибка добавления партии: {errorRequest}</h3>}
                    {error && <h3 style={{color: 'red'}}>Марки и/или упаковки не загрузились</h3>}
                </div>
                <div className='body'>
                    <div className='modalInput' style={{marginBottom: '-5px'}}>
                        <label htmlFor="part">Название партии:</label>
                        <input type="text" id='part' placeholder='Введите партию' value={part}
                               onChange={(event) => setPart(event.target.value)} required/>
                    </div>
                    <div className='modalInput' style={{marginTop: '-15px'}}>
                        <label htmlFor="plav">Плавка:</label>
                        {errorMark && <span className='chosen-mark-span' style={{color: 'crimson'}}>{errorMark}</span>}
                        <input type="text" id='plav' placeholder='Введите плавку' value={plav}
                               onChange={(event) => setPlav(event.target.value)} required
                               onFocus={() => setChosenMark('')}
                               onBlur={() => {
                                   if (plav.length === 0) {
                                       return
                                   } else {
                                       getMark()
                                   }
                               }}
                        />
                    </div>
                    <div className='modalInput' style={{marginTop: '-15px'}}>
                        <label htmlFor="plav">Производитель:</label>
                        <input type="text" id='manufacturer' placeholder='Введите производителя' value={manufacturer}
                               onChange={(event) => setManufacturer(event.target.value)} required
                        />
                        {/*{manufacturer !== '' && !error && manufacturerAutocomplete && showAutocomplete(manufacturer,*/}
                        {/*    manufacturers, setManufacturer, setManufacturerAutocomplete,*/}
                        {/*    'suggestions-reg', 'suggestion-hoverable-reg', 'no-suggestions-reg')}*/}
                    </div>
                    {
                        !errorMark && chosenMark && <div className='modalInput'>
                            <label htmlFor="chosen-mark">Марка:</label>
                            <input type="text" value={chosenMark} onChange={event => {
                                if (event.target.value.length < initialLength) {
                                    return
                                } else {
                                    setChosenMark(event.target.value)
                                    findStandardOptions(event.target.value)
                                }
                            }}/>
                        </div>

                    }
                        {/*<input className='mark-input-additional' type="text" placeholder='Приписка к марке' value={additionalMark}*/}
                        {/*       onChange={event => {*/}
                        {/*           setAdditionalMark(event.target.value.toUpperCase().replace(/ /g,''))*/}
                        {/*           findStandardOptions(chosenMark + event.target.value.toUpperCase().replace(/ /g,''))*/}
                        {/*       }}/>*/}

                        {/*<span className='chosen-mark-span-good' style={{color: 'grey'}}>найденная марка: {chosenMark + ','}*/}
                        {/*    &nbsp;*/}
                        {/*    <input className='exception' type="text" placeholder='Приписка к марке' value={additionalMark}*/}
                        {/*           onChange={event => {*/}
                        {/*               setAdditionalMark(event.target.value.toUpperCase().replace(/ /g,''))*/}
                        {/*               findStandardOptions(chosenMark + event.target.value.toUpperCase().replace(/ /g,''))*/}
                        {/*           }}/>*/}
                        {/*    </span>*/}


                    <div className='modalInputDouble' style={{marginBottom: '15px'}}>
                        <div className='modalInputHalf'>
                            <label htmlFor="diameter">Диаметр:</label>
                            <input type="text" id='diameter' placeholder='Введите диаметр' value={diameter}
                                   onChange={(event) => setDiameter(event.target.value.replace(/[^.1234567890]+/g, ''))} required/>
                        </div>
                        <div className='modalInputHalf'>
                            <label htmlFor='packing'>Упаковка: </label>
                            <select name="packing" id="packing" required
                                    onChange={event => setPacking(event.target.value)}>
                                <option selected disabled value=''>Выбрать..</option>
                                {packings.map(pack => <option value={pack.name}>{pack.name}</option>)}
                            </select>
                            {/*<label htmlFor="packing">Упаковка:</label>*/}
                            {/*<select required name="packing" id='packing' placeholder='Выберите упаковку' value={packing} onChange={event => setPacking(event.target.value)}>*/}
                            {/*    <option selected disabled value=''>Выберите упаковку</option>*/}
                            {/*    <option value="Пруток(1000мм)">Пруток(1000мм)</option>*/}
                            {/*    <option value="Пруток(600мм)">Пруток(600мм)</option>*/}
                            {/*    <option value="Катушка">Катушка</option>*/}
                            {/*    <option value="Электроды">Электроды</option>*/}
                            {/*    <option value="Квадрат">Квадрат</option>*/}
                            {/*    <option value="КНБ">КНБ</option>*/}
                            {/*    <option value="Катанка">Катанка</option>*/}
                            {/*    <option value="К415">К415</option>*/}
                            {/*    <option value="К300">К300</option>*/}
                            {/*    <option value="К200">К200</option>*/}
                            {/*</select>*/}
                        </div>
                    </div>
                    <div className='modalInput'>
                        <Select placeholder='Выберите ГОСТ' isMulti id='standard' value={standard} name='standard'
                                className='basic-multi-select'
                                classNamePrefix='select' options={tempStandards} closeMenuOnSelect={false}
                                components={animatedComponents} defaultValue={standard} required
                                noOptionsMessage={() => 'Нет подходящих ГОСТов'} isClearable //@ts-ignore
                                onChange={setStandard}/>
                    </div>
                    <h4 style={{margin: '0'}}>Мех. свойства:</h4>
                    {inputFields.map((field, index) => <div key={index} className='modalInputPlav'>
                        <select name="property" placeholder='Мех. свойство' value={field.property}
                                onChange={event => handleOnChangeSelect(event, index)} required>
                            <option selected disabled value=''>Свойство</option>
                            {options.map((option, newIndex) => <option disabled={disabledFields[newIndex]} value={option} key={newIndex}>{option}</option>)}
                        </select>
                        {field.property === 'Вр. сопр. р-ву (МПа)' &&
                            <input required name='content' type="text" placeholder='МПа' value={field.content} onChange={event => handleOnChange(event, index)}/>
                        }
                        {field.property === 'Вр. сопр. р-ву (кгс/мм2)' &&
                            <input required name='content' type="text" placeholder='кгс/мм2' value={field.content} onChange={event => handleOnChange(event, index)}/>
                        }
                        {field.property === 'Сост. пов-сти' &&
                            <select required name="content" placeholder='Поверхность' value={field.content} onChange={event => handleOnChangeSurface(event, index)}>
                                <option selected disabled value=''>Выберите тип</option>
                                <option value="Омедненная">Омедненная</option>
                                <option value="Неомедненная">Неомедненная</option>
                                <option value="Травленая, отбеленная">Травл. отбел.</option>
                            </select>
                        }
                        {(field.property === 'Сод. феррит. фазы' || field.property === 'Отн. удлинение') &&
                            <input required name='content' type="text" placeholder='%' value={field.content} onChange={event => handleOnChange(event, index)}/>
                        }
                        {field.property === 'Удел. эл. сопр' &&
                            <input required name='content' type="text" placeholder='мкОм*м' value={field.content} onChange={event => handleOnChange(event, index)}/>
                        }
                        {field.property === 'Живучесть' &&
                            <input required name='content' type="text" placeholder='ч/градус С' value={field.content} onChange={event => handleOnChange(event, index)}/>
                        }
                        <button type='button' className='remove-plav-field-btn' onClick={() => {
                            if (inputFields.length > 1) {
                                removeFields(index)
                            }}
                        }>X</button>
                    </div>)}
                    <button type='button' className='add-plav-button-small' onClick={addFields}>Добавить поле</button>
                </div>
                <div className='footer'>
                    <button type='button' id='cancelBtn' onClick={() => openModal(false)}>Отменить</button>
                    <button type='submit' id='confirmBtn'>Добавить</button>
                </div>
            </div>
        </form>,
        portalElement
    )
}