import {useEffect, useState} from "react";
import axios from "axios";
import {BACKEND_URL} from "../ConstConfig";
import {IAction, IActionGroup, IUser} from "../interfaces/exportedInterfaces";

export function useActions() {

    const [availableActions, setAvailableActions] = useState<IAction[]>([])
    const [availableActionGroups, setAvailableActionGroups] = useState<IActionGroup[]>([])
    const [availableUsers, setAvailableUsers] = useState<IUser[]>([])
    const [fetchError, setFetchError] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)

    async function fetchActions() {
        try {
            setLoading(true)
            setFetchError('')
            const responseGroups = await axios.get(BACKEND_URL + '/api/v1/action-group', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            const responseActions = await axios.get(BACKEND_URL + '/api/v1/action', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            const responseUsers = await axios.get(BACKEND_URL + '/api/v1/worker', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            setAvailableActionGroups(responseGroups.data)
            setAvailableActions(responseActions.data)
            setAvailableUsers(responseUsers.data)
            setLoading(false)
        } catch (e: unknown) {
            setLoading(false)
            setFetchError('Не удалось получить список групп и/или действий')
        }
    }

    useEffect(() => {
        fetchActions()
    }, [])

    return {availableActions, availableActionGroups, availableUsers, fetchError, loading}
}