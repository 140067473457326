import {IShippingHistory} from "../interfaces/exportedInterfaces";
import {Link, useNavigate} from "react-router-dom";
import React from "react";

interface ShipmentHistoryTableRowProps {
    item: IShippingHistory
}

export function ShipmentHistoryTableRow({item}: ShipmentHistoryTableRowProps) {

    const navigate = useNavigate();

    return localStorage.getItem('actions')?.includes('DISPATCH_INFO_BY_ID') ?
        <tr className='hoverable-tr' onClick={() => window.open(`/dispatch-history/${item.id}/info`, '_blank')}>
            <td>{item.id}</td>
            <td>{item.weight}</td>
            <td>{item.customer}</td>
            <td>{item.bill}</td>
            <td>{item.date.replace('T', ' ').split('.')[0]}</td>
        </tr>
        :
        <tr>
            <td>{item.id}</td>
            <td>{item.weight}</td>
            <td>{item.customer}</td>
            <td>{item.bill}</td>
            <td>{item.date.replace('T', ' ').split('.')[0]}</td>
        </tr>
}

// {
//     localStorage.getItem('actions')?.includes('DISPATCH_INFO_BY_ID') ?
//         <td><Link to={'/dispatch-history/' + item.id + '/info'}>{item.id}</Link></td>
//         :
//         <td>{item.id}</td>
// }