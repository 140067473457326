import {ISendHistoryFields} from "../interfaces/exportedInterfaces";
import {Link} from "react-router-dom";

interface SendHistoryTableRowProps {
    item: ISendHistoryFields
}

export function SendHistoryTableRow({item}: SendHistoryTableRowProps) {
    return (
        <tr>
            {
                localStorage.getItem('actions')?.includes('TRANSFER_INFO_BY_ID') ?
                    <Link to={'/send-details/' + item.id}><td>{item.id}</td></Link>
                    :
                    <td>{item.id}</td>
            }
            <td>{item.source} &#8594; {item.destination}</td>
            <td>{item.createdDate}</td>
            <td>{item.creator}</td>
            <td>{item.carPlate.length > 0 ? item.carPlate : 'Не указан'}</td>
            <td>{item.status}</td>
        </tr>
    )
}