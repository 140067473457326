import {TableRow} from "./TableRow";
import React from "react";
import {IModels} from "../interfaces/exportedInterfaces";
import '../css/TableView.css'

interface TableViewProps {
    models: IModels[]
    selectedMarks: string[],
    selectedPacks: string[],
    selectedSklads: string[],
    selectedDiameterLeft: number,
    selectedDiameterRight: number
}

export function TableView({
                              models,
                              selectedMarks,
                              selectedPacks,
                              selectedDiameterLeft,
                              selectedDiameterRight,
                              selectedSklads
                          }: TableViewProps) {
    return (
        <div id='table-wrap'>
            <table>
                <thead>
                <tr>
                    <th style={{width: '15%'}}>Марка</th>
                    <th style={{width: '9%'}}>Диаметр</th>
                    <th style={{width: '14%'}}>Упаковка</th>
                    <th style={{width: '15%'}}>Солнечногорск</th>
                    <th style={{width: '17%'}}>Белорецк(Склад)</th>
                    <th style={{width: '18%'}}>Белорецк(Пр-во)</th>
                    <th style={{width: '12%'}}>Матросово</th>
                </tr>
                </thead>
                <tbody>
                {models.filter((model) => {
                    return (selectedMarks.length > 0) ? selectedMarks.includes(model.mark) : model
                }).filter((model) => {
                    return (Number(model.diameter) >= selectedDiameterLeft && Number(model.diameter) <= selectedDiameterRight)
                }).filter((model) => {
                    return (selectedPacks.length > 0) ? selectedPacks.includes(model.packing) : model
                }).filter((model) => {
                    // if (selectedSklads.length > 0) {
                    //     for (let sklad of selectedSklads) {
                    //         if (sklad === 'Матросово') {
                    //             return (Number(model.matrosovo) > 0 || Number(model.matrosovoReserve) > 0)
                    //         }
                    //         if (sklad === 'Солнечногорск') {
                    //             return (Number(model.solnechnogorsk) > 0 || Number(model.solnechnogorskReserve) > 0)
                    //         }
                    //         if (sklad === 'Белорецк') {
                    //             return (Number(model.belSklad) > 0 || Number(model.belSkladReserve) > 0)
                    //         }
                    //         if (sklad === 'Производство') {
                    //             return (Number(model.manufacture) > 0 || Number(model.manufactureReserve) > 0)
                    //         }
                    //     }
                    // } else {
                    //     return model
                    // }
                    return (selectedSklads.length > 0) ? ((selectedSklads.includes('Матросово') && (Number(model.matrosovo) > 0 || Number(model.matrosovoReserve) > 0)) ||
                        (selectedSklads.includes('Солнечногорск') && (Number(model.solnechnogorsk) > 0 || Number(model.solnechnogorskReserve) > 0)) ||
                        (selectedSklads.includes('Белорецк') && (Number(model.belSklad) > 0 || Number(model.belSkladReserve) > 0)) ||
                        (selectedSklads.includes('Производство') && (Number(model.manufacture) > 0 || Number(model.manufactureReserve) > 0))) : model
                })
                    .map((model, i) => <TableRow model={model} key={i}/>)
                }
                </tbody>
            </table>
        </div>
    )
}





    // .filter((model) => {
    //     return (selectedSklads.includes('Матросово')) ? Number(model.matrosovo) > 0 || Number(model.matrosovoReserve) > 0 : model
    // }).filter((model) => {
    //     return (selectedSklads.includes('Солнечногорск')) ? Number(model.solnechnogorsk) > 0 || Number(model.solnechnogorskReserve) > 0 : model
    // }).filter((model) => {
    //     return (selectedSklads.includes('Белорецк')) ? Number(model.belSklad) > 0 || Number(model.belSkladReserve) > 0 : model
    // }).filter((model) => {
    //     return (selectedSklads.includes('Производство')) ? Number(model.manufacture) > 0 || Number(model.manufactureReserve) > 0 : model
    // })