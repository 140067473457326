import {ModalWindowProps} from "./ModalWindowAddStandard";
import ReactDom from "react-dom";
import React, {useState} from "react";
import '../css/ModalWindowAddUser.css'
import axios from "axios";
import {BACKEND_URL} from "../ConstConfig";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {SelectFields} from "../pages/RegisterPage";
import {useActions} from "../hooks/useActions";
import {Bounce, toast, ToastContainer} from "react-toastify";
import {Loader} from "./Loader";

export function ModalWindowAddUser({openModal}: ModalWindowProps) {

    const portalElement: HTMLElement = document.getElementById('portal')!

    const {availableActions, availableActionGroups, availableUsers, fetchError, loading} = useActions()

    const [fetched, setFetched] = useState<boolean>(false)
    const [id, setId] = useState<string>('')
    const [name, setName] = useState<string>('')
    const [surname, setSurname] = useState<string>('')
    const [middleName, setMiddleName] = useState<string>('')
    const [warehouse, setWarehouse] = useState<SelectFields[]>([])
    const [mainWarehouse, setMainWarehouse] = useState<string>('')
    const [actionGroups, setActionGroups] = useState<SelectFields[]>([])
    const [actions, setActions] = useState<SelectFields[]>([])
    const [login, setLogin] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [error, setError] = useState<string>('')

    const [functionSwitch, setFunctionSwitch] = useState<number>(1)

    const warehouseOptions: SelectFields[] = [
        {value: 'Solnechnogorsk', label: 'Солнечногорск'},
        {value: 'Matrosovo', label: 'Матросово'},
        {value: 'BelSklad', label: 'Белорецк'},
        {value: 'Manufacture', label: 'Производство'},
    ]

    const actionOptions = () => {
        let temp: SelectFields[] = []
        availableActions.map(action => temp = [...temp, {value: action.action, label: action.action}])
        return temp
    }

    const groupOptions = () => {
        let temp: SelectFields[] = []
        availableActionGroups.map(group => temp = [...temp, {value: group.group, label: group.group}])
        return temp
    }

    const animatedComponents = makeAnimated();

    async function addUser(event: { preventDefault: () => void; }) {
        setError('')
        if (login === '' || password === '' || name === '' || surname === '') {
            toast.error('Заполните обязательные поля: логин, пароль, имя, фамилия', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
        } else {
            let loc: string[] = []
            let act: string[] = []
            let grp: string[] = []
            warehouse.forEach(sklad => loc = [...loc, sklad.value])
            actions.forEach(a => act = [...act, a.value])
            actionGroups.forEach(ag => grp = [...grp, ag.value])
            try {
                const response = await axios.post(BACKEND_URL + '/api/v1/worker', {
                    authName: login,
                    password: password,
                    mainLocation: mainWarehouse === '' ? null : mainWarehouse,
                    firstName: name,
                    secondName: surname,
                    middleName: middleName === '' ? null : middleName,
                    locations: loc.length === 0 ? null : loc,
                    actions: act.length === 0 ? null : act,
                    actionGroups: grp.length === 0 ? null : grp
                }, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                })
                if (response.status === 200) {
                    window.location.reload()
                }
            } catch (e: unknown) {
                setError('Не удалось добавить пользователя')
            }
        }
    }

    async function getUser() {
        setError('')
        const el1 = document.getElementById('span-one')!
        const el2 = document.getElementById('span-two')!
        const el3 = document.getElementById('span-three')!
        const el4 = document.getElementById('span-four')!
        el1.classList.remove('item-clicked')
        el2.classList.remove('item-clicked')
        el3.classList.remove('item-clicked')
        el4.classList.remove('item-clicked')
        try {
            const response = await axios.get(BACKEND_URL + '/api/v1/worker/' + id, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            setName(response.data.firstName)
            setSurname(response.data.secondName)
            setMiddleName(response.data.middleName)
            setLogin(response.data.authName)
            setMainWarehouse(response.data.mainLocation)
            let tempLocs: SelectFields[] = []
            response.data.locations.forEach((loc: string) => tempLocs = [...tempLocs, {value: loc, label: loc}])
            setWarehouse(tempLocs)
            let tempActions: SelectFields[] = []
            response.data.actions.forEach((action: string) => tempActions = [...tempActions, {value: action, label: action}])
            setActions(tempActions)
            let tempGroups: SelectFields[] = []
            response.data.actionGroups.forEach((group: string) => tempGroups = [...tempGroups, {value: group, label: group}])
            setActionGroups(tempGroups)
            setFetched(true)
            const el1 = document.getElementById('span-one')!
            const el2 = document.getElementById('span-two')!
            const el3 = document.getElementById('span-three')!
            const el4 = document.getElementById('span-four')!
            if (response.data.mainLocation === 'BelSklad') {
                el1.classList.add('item-clicked')
            } else if (response.data.mainLocation === 'Solnechnogorsk') {
                el2.classList.add('item-clicked')
            } else if (response.data.mainLocation === 'Manufacture') {
                el3.classList.add('item-clicked')
            } else if (response.data.mainLocation === 'Matrosovo') {
                el4.classList.add('item-clicked')
            }
        } catch (e: unknown) {
            setError('Не удалось получить данные о пользователе')
        }
    }

    async function editUser() {
        setError('')
        let loc: string[] = []
        let act: string[] = []
        let grp: string[] = []
        warehouse.forEach(sklad => loc = [...loc, sklad.value])
        actions.forEach(a => act = [...act, a.value])
        actionGroups.forEach(ag => grp = [...grp, ag.value])
        try {
            const response = await axios.put(BACKEND_URL + '/api/v1/worker/' + id, {
                authName: login,
                password: password === '' ? null : password,
                mainLocation: loc.includes(mainWarehouse) ? mainWarehouse : null,
                firstName: name,
                secondName: surname,
                middleName: middleName,
                locations: loc,
                actions: act,
                actionGroups: grp
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            if (response.status === 200) {
                window.location.reload()
            }
        } catch (e: unknown) {
            setError('Не удалось изменить пользователя')
        }
    }

    async function deleteUser() {
        setError('')
        try {
            const response = await axios.delete(BACKEND_URL + '/api/v1/worker/' + id, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            if (response.status === 200) {
                window.location.reload()
            }
        } catch (e: unknown) {
            setError('Не удалось удалить пользователя')
        }
    }

    return ReactDom.createPortal(
        <form className='modalWindow'>
            <div className='modalBackground' onClick={() => openModal(false)}/>
            {
                loading && <Loader color={true}/>
            }
            {
                !loading && fetchError.length > 0 && toast.error(fetchError, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
            })
            }
            {
                !loading && !fetchError &&
                <>
                    <div className='modalContainerReserve' style={{width: '52%', height: '60%'}}>
                        <div className='titleCloseBtn'>
                            <button type='button' id='closeBtn' onClick={() => openModal(false)}>X</button>
                        </div>
                        <div className='body'>
                            <div className='triple-switch-container'>
                                <div className='triple-switch' id='location' style={{marginTop: '8px', marginBottom: '15px'}}>
                                    <span id='span-one-one' className='item-notclicked item-clicked' onClick={() => {
                                        setFunctionSwitch(1)
                                        setName('')
                                        setSurname('')
                                        setMiddleName('')
                                        setLogin('')
                                        setPassword('')
                                        setMainWarehouse('')
                                        setWarehouse([])
                                        setActions([])
                                        setActionGroups([])
                                        setId('')
                                        setError('')
                                        setFetched(false)
                                        const element1 = document.getElementById('span-one-one')!
                                        const element2 = document.getElementById('span-two-two')!
                                        const element3 = document.getElementById('span-three-three')!
                                        const element4 = document.getElementById('span-four-four')!
                                        element1.classList.add('item-clicked')
                                        element2.classList.remove('item-clicked')
                                        element3.classList.remove('item-clicked')
                                        element4.classList.remove('item-clicked')
                                    }}><label>Добавление</label></span>
                                    <span id='span-two-two' className='item-notclicked' onClick={() => {
                                        setFunctionSwitch(2)
                                        setName('')
                                        setSurname('')
                                        setMiddleName('')
                                        setLogin('')
                                        setPassword('')
                                        setMainWarehouse('')
                                        setWarehouse([])
                                        setActions([])
                                        setActionGroups([])
                                        setId('')
                                        setError('')
                                        setFetched(false)
                                        const element1 = document.getElementById('span-one-one')!
                                        const element2 = document.getElementById('span-two-two')!
                                        const element3 = document.getElementById('span-three-three')!
                                        const element4 = document.getElementById('span-four-four')!
                                        element2.classList.add('item-clicked')
                                        element1.classList.remove('item-clicked')
                                        element3.classList.remove('item-clicked')
                                        element4.classList.remove('item-clicked')
                                    }}><label>Изменение</label></span>
                                    <span id='span-three-three' className='item-notclicked' onClick={() => {
                                        setFunctionSwitch(3)
                                        setName('')
                                        setSurname('')
                                        setMiddleName('')
                                        setLogin('')
                                        setPassword('')
                                        setMainWarehouse('')
                                        setWarehouse([])
                                        setActions([])
                                        setActionGroups([])
                                        setId('')
                                        setError('')
                                        setFetched(false)
                                        const element1 = document.getElementById('span-one-one')!
                                        const element2 = document.getElementById('span-two-two')!
                                        const element3 = document.getElementById('span-three-three')!
                                        const element4 = document.getElementById('span-four-four')!
                                        element3.classList.add('item-clicked')
                                        element2.classList.remove('item-clicked')
                                        element1.classList.remove('item-clicked')
                                        element4.classList.remove('item-clicked')
                                    }}><label>Удаление</label></span>
                                    <span id='span-four-four' className='item-notclicked' onClick={() => {
                                        setFunctionSwitch(4)
                                        setName('')
                                        setSurname('')
                                        setMiddleName('')
                                        setLogin('')
                                        setPassword('')
                                        setMainWarehouse('')
                                        setWarehouse([])
                                        setActions([])
                                        setActionGroups([])
                                        setId('')
                                        setError('')
                                        setFetched(false)
                                        const element1 = document.getElementById('span-one-one')!
                                        const element2 = document.getElementById('span-two-two')!
                                        const element3 = document.getElementById('span-three-three')!
                                        const element4 = document.getElementById('span-four-four')!
                                        element4.classList.add('item-clicked')
                                        element2.classList.remove('item-clicked')
                                        element3.classList.remove('item-clicked')
                                        element1.classList.remove('item-clicked')
                                    }}><label>Просмотр</label></span>
                                </div>
                            </div>
                        </div>
                        {
                            functionSwitch === 1 &&
                            <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
                                <div className='titleReserve'>
                                    <h1>Добавление пользователя</h1>
                                </div>

                                <div className='body'>
                                    {
                                        error !== '' && <span style={{display: 'none'}}>
                                        {toast.error(error, {
                                            position: "top-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                            theme: "light",
                                            transition: Bounce,
                                        })}
                                        </span>
                                    }
                                    <div className='reserve-input-container'>
                                        <div className='modalInputReserve'>
                                            <label htmlFor="" className='reserve-label'>Логин</label>
                                            <input type="text" value={login} className='modal-window-add-user-input'
                                                   onChange={event => setLogin(event.target.value)} required/>
                                        </div>
                                        <div className='modalInputReserve'>
                                            <label htmlFor="" className='reserve-label'>Пароль</label>
                                            <input type="text" value={password} className='modal-window-add-user-input'
                                                   onChange={event => setPassword(event.target.value)} required/>
                                        </div>
                                    </div>
                                    <div className='reserve-input-container'>
                                        <div className='modalInputReserve'>
                                            <label htmlFor="" className='reserve-label'>Имя</label>
                                            <input type="text" value={name} className='modal-window-add-user-input'
                                                   onChange={event => setName(event.target.value)} required/>
                                        </div>
                                        <div className='modalInputReserve'>
                                            <label htmlFor="" className='reserve-label'>Фамилия</label>
                                            <input type="text" value={surname} className='modal-window-add-user-input'
                                                   onChange={event => setSurname(event.target.value)} required/>
                                        </div>
                                    </div>
                                    <div className='reserve-input-container'>
                                        <div className='modalInputReserve'>
                                            <label htmlFor="" className='reserve-label'>Отчество (при наличии)</label>
                                            <input type="text" value={middleName} className='modal-window-add-user-input'
                                                   onChange={event => setMiddleName(event.target.value)}/>
                                        </div>
                                    </div>
                                    <div className='reserve-input-container'>
                                        <div className='modalInputReserve'>
                                            <Select placeholder='ACTION GROUPS' isMulti id='action-groups' value={actionGroups} name='actionGroups'
                                                    className='basic-multi-select'
                                                    classNamePrefix='select' options={groupOptions()} closeMenuOnSelect={false}
                                                    components={animatedComponents} defaultValue={actionGroups}
                                                    noOptionsMessage={() => 'Такой группы нет'} isClearable //@ts-ignore
                                                    onChange={setActionGroups}/>
                                        </div>
                                        <div className='modalInputReserve'>
                                            <Select placeholder='ACTIONS' isMulti id='actions' value={actions} name='actions'
                                                    className='basic-multi-select'
                                                    classNamePrefix='select' options={actionOptions()} closeMenuOnSelect={false}
                                                    components={animatedComponents} defaultValue={actions}
                                                    noOptionsMessage={() => 'Такого действия нет'} isClearable //@ts-ignore
                                                    onChange={setActions}/>
                                        </div>
                                    </div>
                                    <div className='reserve-input-container'>
                                        <div className='modalInputReserve'>
                                            <Select placeholder='Склады' isMulti id='sklads' value={warehouse} name='sklads'
                                                    className='basic-multi-select'
                                                    classNamePrefix='select' options={warehouseOptions} closeMenuOnSelect={false}
                                                    components={animatedComponents} defaultValue={actions}
                                                    noOptionsMessage={() => 'Такого действия нет'} isClearable //@ts-ignore
                                                    onChange={setWarehouse}/>
                                        </div>
                                    </div>
                                    <div className='reserve-input-container'>
                                        <div className='modalInputReserveFull'>
                                            <label htmlFor="" className='reserve-label'>Основной склад</label>
                                            <div className='triple-switch-container'>
                                                <div className='triple-switch' id='location' style={{marginTop: '8px'}}>
                                                    <span id='span-one' className='item-notclicked' onClick={() => {
                                                        if (warehouse.some(sklad => sklad.value === 'BelSklad')) {
                                                            setMainWarehouse('BelSklad')
                                                            const element1 = document.getElementById('span-one')!
                                                            const element2 = document.getElementById('span-two')!
                                                            const element3 = document.getElementById('span-three')!
                                                            const element4 = document.getElementById('span-four')!
                                                            element1.classList.add('item-clicked')
                                                            element2.classList.remove('item-clicked')
                                                            element3.classList.remove('item-clicked')
                                                            element4.classList.remove('item-clicked')
                                                        } else {
                                                            toast.error('Белорецк не добавлен в список складов', {
                                                                position: "top-right",
                                                                autoClose: 5000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: true,
                                                                progress: undefined,
                                                                theme: "light",
                                                                transition: Bounce,
                                                            })
                                                        }
                                                    }}><label>Белорецк</label></span>
                                                    <span id='span-two' className='item-notclicked' onClick={() => {
                                                        if (warehouse.some(sklad => sklad.value === 'Solnechnogorsk')) {
                                                            setMainWarehouse('Solnechnogorsk')
                                                            const element1 = document.getElementById('span-one')!
                                                            const element2 = document.getElementById('span-two')!
                                                            const element3 = document.getElementById('span-three')!
                                                            const element4 = document.getElementById('span-four')!
                                                            element2.classList.add('item-clicked')
                                                            element1.classList.remove('item-clicked')
                                                            element3.classList.remove('item-clicked')
                                                            element4.classList.remove('item-clicked')
                                                        } else {
                                                            toast.error('Cолнечногорск не добавлен в список складов', {
                                                                position: "top-right",
                                                                autoClose: 5000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: true,
                                                                progress: undefined,
                                                                theme: "light",
                                                                transition: Bounce,
                                                            })
                                                        }
                                                    }}><label>Солнечногорск</label></span>
                                                    <span id='span-three' className='item-notclicked' onClick={() => {
                                                        if (warehouse.some(sklad => sklad.value === 'Manufacture')) {
                                                            setMainWarehouse('Manufacture')
                                                            const element1 = document.getElementById('span-one')!
                                                            const element2 = document.getElementById('span-two')!
                                                            const element3 = document.getElementById('span-three')!
                                                            const element4 = document.getElementById('span-four')!
                                                            element3.classList.add('item-clicked')
                                                            element2.classList.remove('item-clicked')
                                                            element1.classList.remove('item-clicked')
                                                            element4.classList.remove('item-clicked')
                                                        } else {
                                                            toast.error('Производство не добавлено в список складов', {
                                                                position: "top-right",
                                                                autoClose: 5000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: true,
                                                                progress: undefined,
                                                                theme: "light",
                                                                transition: Bounce,
                                                            })
                                                        }
                                                    }}><label>Производство</label></span>
                                                    <span id='span-four' className='item-notclicked' onClick={() => {
                                                        if (warehouse.some(sklad => sklad.value === 'Matrosovo')) {
                                                            setMainWarehouse('Matrosovo')
                                                            const element1 = document.getElementById('span-one')!
                                                            const element2 = document.getElementById('span-two')!
                                                            const element3 = document.getElementById('span-three')!
                                                            const element4 = document.getElementById('span-four')!
                                                            element4.classList.add('item-clicked')
                                                            element3.classList.remove('item-clicked')
                                                            element2.classList.remove('item-clicked')
                                                            element1.classList.remove('item-clicked')
                                                        } else {
                                                            toast.error('Матросово не добавлено в список складов', {
                                                                position: "top-right",
                                                                autoClose: 5000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: true,
                                                                progress: undefined,
                                                                theme: "light",
                                                                transition: Bounce,
                                                            })
                                                        }
                                                    }}><label>Матросово</label></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='footer'>
                                    <button type='button' id='cancelBtn' onClick={() => openModal(false)}>Отменить</button>
                                    <button type='button' id='confirmBtn' onClick={addUser}>Добавить</button>
                                </div>
                            </div>
                        }
                        {
                            functionSwitch === 2 &&
                            <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
                                <div className='titleReserve'>
                                    <h1>Изменение пользователя</h1>
                                </div>
                                <div className='body'>
                                    {
                                        error !== '' && <span style={{display: 'none'}}>
                                        {toast.error(error, {
                                            position: "top-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                            theme: "light",
                                            transition: Bounce,
                                        })}
                                        </span>
                                    }
                                    <div className='reserve-input-container'>
                                        <div className='modalInputReserve'>
                                            <label htmlFor="" className='reserve-label'>ID пользователя</label>
                                            <input type="text" value={id} className='modal-window-add-user-input'
                                                   onChange={event => setId(event.target.value)} onBlur={(event) => {
                                                       if (event.target.value === '') {
                                                           return
                                                       } else {
                                                           getUser()
                                                       }
                                                   }}
                                            />
                                        </div>
                                        <div className='modalInputReserve'>
                                            <label htmlFor="" className='reserve-label'>Имя</label>
                                            <input type="text" value={name} className='modal-window-add-user-input' disabled={!fetched}
                                                   onChange={event => setName(event.target.value)} required/>
                                        </div>
                                    </div>
                                    <div className='reserve-input-container'>
                                        <div className='modalInputReserve'>
                                            <label htmlFor="" className='reserve-label'>Фамилия</label>
                                            <input type="text" value={surname} className='modal-window-add-user-input' disabled={!fetched}
                                                   onChange={event => setSurname(event.target.value)} required/>
                                        </div>
                                        <div className='modalInputReserve'>
                                            <label htmlFor="" className='reserve-label'>Отчество (при наличии)</label>
                                            <input type="text" value={middleName} className='modal-window-add-user-input' disabled={!fetched}
                                                   onChange={event => setMiddleName(event.target.value)}/>
                                        </div>
                                    </div>
                                    <div className='reserve-input-container'>
                                        <div className='modalInputReserve'>
                                            <label htmlFor="" className='reserve-label'>Логин</label>
                                            <input type="text" value={login} className='modal-window-add-user-input' disabled={!fetched}
                                                   onChange={event => setLogin(event.target.value)} required/>
                                        </div>
                                        <div className='modalInputReserve'>
                                            <label htmlFor="" className='reserve-label'>Пароль</label>
                                            <input type="text" value={password} className='modal-window-add-user-input' disabled={!fetched}
                                                   onChange={event => setPassword(event.target.value)} required/>
                                        </div>
                                    </div>
                                    <div className='reserve-input-container'>
                                        <div className='modalInputReserve'>
                                            <Select placeholder='ACTION GROUPS' isMulti id='action-groups' value={actionGroups} name='actionGroups'
                                                    className='basic-multi-select'
                                                    classNamePrefix='select' options={groupOptions()} closeMenuOnSelect={false}
                                                    components={animatedComponents} defaultValue={actionGroups}
                                                    noOptionsMessage={() => 'Такой группы нет'} isClearable //@ts-ignore
                                                    onChange={setActionGroups} isDisabled={!fetched}/>
                                        </div>
                                        <div className='modalInputReserve'>
                                            <Select placeholder='ACTIONS' isMulti id='actions' value={actions} name='actions'
                                                    className='basic-multi-select'
                                                    classNamePrefix='select' options={actionOptions()} closeMenuOnSelect={false}
                                                    components={animatedComponents} defaultValue={actions}
                                                    noOptionsMessage={() => 'Такого действия нет'} isClearable //@ts-ignore
                                                    onChange={setActions} isDisabled={!fetched}/>
                                        </div>
                                    </div>
                                    <div className='reserve-input-container'>
                                        <div className='modalInputReserve'>
                                            <Select placeholder='Склады' isMulti id='sklads' value={warehouse} name='sklads'
                                                    className='basic-multi-select'
                                                    classNamePrefix='select' options={warehouseOptions} closeMenuOnSelect={false}
                                                    components={animatedComponents} defaultValue={actions}
                                                    noOptionsMessage={() => 'Такого действия нет'} isClearable //@ts-ignore
                                                    onChange={setWarehouse} isDisabled={!fetched}/>
                                        </div>
                                    </div>
                                    <div className='reserve-input-container'>
                                        <div className='modalInputReserveFull'>
                                            <label htmlFor="" className='reserve-label'>Основной склад</label>
                                            <div className='triple-switch-container'>
                                                <div className='triple-switch' id='location' style={{marginTop: '8px'}}>
                                                    <span id='span-one' className='item-notclicked' onClick={() => {
                                                        if (warehouse.some(sklad => sklad.value === 'BelSklad')) {
                                                            setMainWarehouse('BelSklad')
                                                            const element1 = document.getElementById('span-one')!
                                                            const element2 = document.getElementById('span-two')!
                                                            const element3 = document.getElementById('span-three')!
                                                            const element4 = document.getElementById('span-four')!
                                                            element1.classList.add('item-clicked')
                                                            element2.classList.remove('item-clicked')
                                                            element3.classList.remove('item-clicked')
                                                            element4.classList.remove('item-clicked')
                                                        } else {
                                                            toast.error('Белорецк не добавлен в список складов', {
                                                                position: "top-right",
                                                                autoClose: 5000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: true,
                                                                progress: undefined,
                                                                theme: "light",
                                                                transition: Bounce,
                                                            })
                                                        }
                                                    }}><label>Белорецк</label></span>
                                                    <span id='span-two' className='item-notclicked' onClick={() => {
                                                        if (warehouse.some(sklad => sklad.value === 'Solnechnogorsk')) {
                                                            setMainWarehouse('Solnechnogorsk')
                                                            const element1 = document.getElementById('span-one')!
                                                            const element2 = document.getElementById('span-two')!
                                                            const element3 = document.getElementById('span-three')!
                                                            const element4 = document.getElementById('span-four')!
                                                            element2.classList.add('item-clicked')
                                                            element1.classList.remove('item-clicked')
                                                            element3.classList.remove('item-clicked')
                                                            element4.classList.remove('item-clicked')
                                                        } else {
                                                            toast.error('Cолнечногорск не добавлен в список складов', {
                                                                position: "top-right",
                                                                autoClose: 5000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: true,
                                                                progress: undefined,
                                                                theme: "light",
                                                                transition: Bounce,
                                                            })
                                                        }
                                                    }}><label>Солнечногорск</label></span>
                                                    <span id='span-three' className='item-notclicked' onClick={() => {
                                                        if (warehouse.some(sklad => sklad.value === 'Manufacture')) {
                                                            setMainWarehouse('Manufacture')
                                                            const element1 = document.getElementById('span-one')!
                                                            const element2 = document.getElementById('span-two')!
                                                            const element3 = document.getElementById('span-three')!
                                                            const element4 = document.getElementById('span-four')!
                                                            element3.classList.add('item-clicked')
                                                            element2.classList.remove('item-clicked')
                                                            element1.classList.remove('item-clicked')
                                                            element4.classList.remove('item-clicked')
                                                        } else {
                                                            toast.error('Производство не добавлено в список складов', {
                                                                position: "top-right",
                                                                autoClose: 5000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: true,
                                                                progress: undefined,
                                                                theme: "light",
                                                                transition: Bounce,
                                                            })
                                                        }
                                                    }}><label>Производство</label></span>
                                                    <span id='span-four' className='item-notclicked' onClick={() => {
                                                        if (warehouse.some(sklad => sklad.value === 'Matrosovo')) {
                                                            setMainWarehouse('Matrosovo')
                                                            const element1 = document.getElementById('span-one')!
                                                            const element2 = document.getElementById('span-two')!
                                                            const element3 = document.getElementById('span-three')!
                                                            const element4 = document.getElementById('span-four')!
                                                            element4.classList.add('item-clicked')
                                                            element3.classList.remove('item-clicked')
                                                            element2.classList.remove('item-clicked')
                                                            element1.classList.remove('item-clicked')
                                                        } else {
                                                            toast.error('Матросово не добавлено в список складов', {
                                                                position: "top-right",
                                                                autoClose: 5000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: true,
                                                                progress: undefined,
                                                                theme: "light",
                                                                transition: Bounce,
                                                            })
                                                        }
                                                    }}><label>Матросово</label></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='footer'>
                                    <button type='button' id='cancelBtn' onClick={() => openModal(false)}>Отменить</button>
                                    <button type='button' id='confirmBtn' onClick={editUser}>Сохранить</button>
                                </div>
                            </div>
                        }
                        {
                            functionSwitch === 3 &&
                            <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
                                <div className='titleReserve'>
                                    <h1>Удаление пользователя</h1>
                                </div>
                                <div className='body'>
                                    {
                                        error !== '' && <span style={{display: 'none'}}>
                                        {toast.error(error, {
                                            position: "top-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                            theme: "light",
                                            transition: Bounce,
                                        })}
                                        </span>
                                    }
                                    <div className='reserve-input-container'>
                                        <div className='modalInputReserve'>
                                            <label htmlFor="" className='reserve-label'>ID пользователя</label>
                                            <input type="text" value={id} className='modal-window-add-user-input'
                                                   onChange={event => setId(event.target.value)}/>
                                        </div>
                                    </div>
                                </div>
                                <div className='footer'>
                                    <button type='button' id='cancelBtn' onClick={() => openModal(false)}>Отменить</button>
                                    <button type='button' id='confirmBtn' disabled={id === ''} onClick={deleteUser}>Удалить</button>
                                </div>
                            </div>
                        }
                        {
                            functionSwitch === 4 &&
                            <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
                                <div className='titleReserve'>
                                    <h1>Таблица пользователей</h1>
                                </div>
                                <div className='body' style={{marginTop: '8px', marginBottom: '8px'}}>
                                    <table style={{width: '100%'}}>
                                        <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Логин</th>
                                            <th>Имя</th>
                                            <th>Фамилия</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                availableUsers.map((user, index) => <tr>
                                                    <td>{user.id}</td>
                                                    <td>{user.authName}</td>
                                                    <td>{user.firstName}</td>
                                                    <td>{user.secondName}</td>
                                                </tr>)
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className='footer'>
                                    <button type='button' id='cancelBtn' onClick={() => openModal(false)}>Закрыть</button>
                                </div>
                            </div>
                        }
                    </div>
                </>
            }
            <ToastContainer/>
        </form>, portalElement
    )
}