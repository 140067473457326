import axios from "axios";
import {BACKEND_URL} from "../ConstConfig";
import {useLoaderData} from "react-router-dom";
import {IStandardIdInfo} from "../interfaces/exportedInterfaces";
import React, {useState} from "react";
import {CustomFileInput} from "./CustomFileInput";
import {ModalWindowAddNewGrade} from "./ModalWindowAddNewGrade";
import {ModalWindowAddChemicalComposition} from "./ModalWindowAddChemicalComposition";
import {ModalWindowAddMechanicalProperties} from "./ModalWindowAddMechanicalProperties";

interface StandardLoaderProps {
    standard: IStandardIdInfo | string
}

export async function standardIdLoader({params}: any) {
    const response = await axios.get(BACKEND_URL + '/api/v1/standards/' + params.id, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    })
    return {standard: response.status === 200 || response.status === 201 ? response.data : 'Не удалось получить стандарт'}
}

export function StandardIdInfo() {

    const {standard} = useLoaderData() as StandardLoaderProps

    const [addMarkModal, setAddMarkModal] = useState<boolean>(false)
    const [addChemModal, setAddChemModal] = useState<boolean>(false)
    const [addMechModal, setAddMechModal] = useState<boolean>(false)

    const [selectedGrade, setSelectedGrade] = useState<string>('')

    const [boundMarks] = useState(() => {
        if (typeof standard !== "string") {
            let temp: string[] = []
            standard.relatedGrades.map(grade => temp = [...temp, grade.name])
            return temp
        } else {
            return []
        }
    })

    return (
        <div className='dispatch-body'>
            {
                addMarkModal && <ModalWindowAddNewGrade openModal={setAddMarkModal} boundMarks={boundMarks} standard={standard}/>
            }
            {
                addChemModal && <ModalWindowAddChemicalComposition openModal={setAddChemModal} selectedGrade={selectedGrade} standard={standard}/>
            }
            {
                addMechModal && <ModalWindowAddMechanicalProperties openModal={setAddMechModal} selectedGrade={selectedGrade} standard={standard}/>
            }
            {
                typeof standard === 'string' ?
                    <h1>{standard}</h1>
                    :
                    <div className='container'>
                        <h1>{standard.name}</h1>
                        <h2 style={{marginTop: '20px', marginBottom: '20px'}}>Информация о стандарте</h2>
                        <div className='info-section'>
                            <div><strong>ID стандарта</strong><span>{standard.id}</span></div>
                        </div>
                        <div className="file-section">
                            <div className='file-item'>
                                <CustomFileInput id={standard.id} multiple={true} url={'files'}
                                                 requiredType={'STANDARD_SPECIFICATION'} files={standard.files}
                                                 header={'Сертификат'} path={'standards'}
                                />
                            </div>
                        </div>
                        <div className='cancel-changedate-container'>
                            <button type='button' onClick={() => setAddMarkModal(true)}>Добавить марку</button>
                        </div>
                        {standard.relatedGrades.map((grade, index) =>
                            <div className='double-table-container' onMouseOver={() => setSelectedGrade(String(grade.id))}>
                                <div className='upper-section' style={{fontSize: '22px'}}>
                                    <div key={index}><strong>Марка &nbsp;</strong><span>{grade.name}</span></div>
                                </div>
                                <div className='lower-section'>
                                    {
                                        grade.chemicalComposition !== null &&
                                        <table className='one-card-table-chems' style={{width: '98%'}}>
                                            <tr>
                                                <td className='normal-header' style={{fontSize: '18px'}}
                                                    colSpan={grade.chemicalComposition.length}>Химические свойства
                                                </td>
                                            </tr>
                                            <tr className='row-chem'>
                                                {grade.chemicalComposition.map(chem => <td
                                                    className='header-chem'>{chem.name}</td>)}
                                            </tr>
                                            <tr className='row-chem'>
                                                {grade.chemicalComposition.map(chem => <td
                                                    className='value-chem'>
                                                    {chem.type === 'RANGE' && `${chem.min} - ${chem.max}`}
                                                    {chem.type === 'MAIN' && 'Осн.'}
                                                    {chem.type === 'REST' && 'Ост.'}
                                                    {chem.type === 'MIN' && `Не менее ${chem.min}`}
                                                    {chem.type === 'MAX' && `Не более ${chem.max}`}
                                                </td>)}
                                            </tr>
                                        </table>
                                    }
                                    {
                                        grade.chemicalComposition === null &&
                                        <div className='cancel-changedate-container' style={{marginBottom: '15px'}}>
                                            <button type='button' onClick={() => setAddChemModal(true)}>Добавить хим.
                                                состав
                                            </button>
                                        </div>
                                    }
                                    {
                                        grade.mechanicalProperties !== null &&
                                        <table className='one-card-table' style={{height: '300px', width: '98%'}}>
                                            <tr>
                                                <td className='normal-header' style={{height: '20%', fontSize: '18px'}}
                                                    colSpan={grade.mechanicalProperties.length}>Механические свойства
                                                </td>
                                            </tr>
                                            <tr>
                                                {grade.mechanicalProperties.map(mech => <td
                                                    className='header-mechs' style={{height: '40%'}}>{mech.name}</td>)}
                                            </tr>
                                            <tr>
                                                {grade.mechanicalProperties.map(mech => <td
                                                    className='value-mechs' style={{height: '40%'}}>
                                                    {mech.type === 'MIN' && `Не менее ${mech.min}`}
                                                    {mech.type === 'MAX' && `Не более ${mech.max}`}
                                                    {mech.type === 'TEXT' && mech.value}
                                                    {mech.type === 'RANGE' && `${mech.min} - ${mech.max}`}
                                                    {mech.type === 'SELECT' && mech.value}
                                                </td>)}
                                            </tr>
                                        </table>
                                    }
                                    {
                                        grade.mechanicalProperties === null &&
                                        <div className='cancel-changedate-container' style={{marginBottom: '15px'}}>
                                            <button type='button' onClick={() => setAddMechModal(true)}>Добавить мех.
                                                свойства
                                            </button>
                                        </div>
                                    }
                                </div>
                            </div>
                        )}
                    </div>
            }
        </div>
    )
}