import '../css/PlannedDispatchPage.css'
import {CustomFileInput} from "../components/CustomFileInput";
import axios from "axios";
import {BACKEND_URL} from "../ConstConfig";
import {
    IPlannedDispatch,
    IPlannedDispatchComment,
    IPositionDetails,
    ITruePositionDetails
} from "../interfaces/exportedInterfaces";
import {useLoaderData, useNavigate} from "react-router-dom";
import React, {ChangeEvent, useEffect, useState} from "react";
import {Bounce, toast, ToastContainer} from "react-toastify";
import {NewCard} from "../components/NewCard";
import {useRegisterAutocomplete} from "../hooks/useRegisterAutocomplete";
import useCustomContextMenu from "../hooks/useCustomContextMenu";
import {CustomContextMenu} from "../components/CustomContextMenu";

export interface ReturnValue {
    dispatch: IPlannedDispatch | string,
    comments: IPlannedDispatchComment[] | string
}


export async function plannedDispatchLoader({params}: any) {
    // try {
    //
    //     return returnValue
    // } catch (e: unknown) {
    //     return 'Не удалось получить отгрузки за эту неделю'
    // }

    const responseDispatch = await axios.get(BACKEND_URL + '/api/v1/planned-dispatch/' + params.id, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    })
    // const responseComment = await axios.get(BACKEND_URL + '/api/v1/planned-dispatch/' + params.id + '/comments', {
    //     headers: {
    //         Authorization: 'Bearer ' + localStorage.getItem('token')
    //     }
    // })

        // , comments: responseComment.status === 200 || responseComment.status === 201 ? responseComment.data : 'Не удалось получить комментарии'

    return {dispatch: responseDispatch.status === 200 || responseDispatch.status === 201 ? responseDispatch.data : 'Не удалось получить отгрузку'}
}

export function PlannedDispatchPage() {

    const {dispatch} = useLoaderData() as ReturnValue

    const {packings, error} = useRegisterAutocomplete()

    const {menuPosition, menuVisible, showMenu, hideMenu} = useCustomContextMenu()

    const navigate = useNavigate()

    const [changeDateMode, setChangeDateMode] = useState<boolean>(false)
    const [cancelMode, setCancelMode] = useState<boolean>(false)
    const [tkMode, setTkMode] = useState<boolean>(false)
    const [changeRequirementsMode, setChangeRequirementsMode] = useState<boolean>(false)
    const [date, setDate] = useState<string>('')
    const [partOfDay, setPartOfDay] = useState<string>('')
    const [positionDetails, setPositionDetails] = useState<IPositionDetails[]>([{
        mark: '',
        diameter: '',
        packing: '',
        melt: '',
        batch: '',
        totalWeight: '',
        preparedWeight: '',
        from: '',
        to: ''
    }])
    const [ids, setIds] = useState<string>('')
    const [comments, setComments] = useState<IPlannedDispatchComment[] | null>(null)
    const [comment, setComment] = useState<string | null>('')

    const [addPositionsMode, setAddPositionsMode] = useState<boolean>(false)

    const [flag, setFlag] = useState<boolean>(false)
    const [ulFocus, setUlFocus] = useState<boolean>(false)
    // const [ulFocusTwo, setUlFocusTwo] = useState<boolean>(false)

    const [name, setName] = useState<string>(typeof dispatch !== 'string' && dispatch.cargoDetails !== null && dispatch.cargoDetails.receiver.name !== null ? dispatch.cargoDetails.receiver.name : (typeof dispatch !== 'string' ? dispatch.customer : ''))
    const [inn, setInn] = useState<string>(typeof dispatch !== 'string' && dispatch.cargoDetails !== null && dispatch.cargoDetails.receiver.inn !== null ? dispatch.cargoDetails.receiver.inn : '')
    const [city, setCity] = useState<string>(typeof dispatch !== 'string' && dispatch.cargoDetails !== null && dispatch.cargoDetails.receiver.city !== null ? dispatch.cargoDetails.receiver.city : '')
    const [phone, setPhone] = useState<string>(typeof dispatch !== 'string' && dispatch.cargoDetails !== null && dispatch.cargoDetails.receiver.phone !== null ? dispatch.cargoDetails.receiver.phone : '')
    const [payer, setPayer] = useState<string>(typeof dispatch !== 'string' && dispatch.cargoDetails !== null && dispatch.cargoDetails.receiver.payer !== null ? dispatch.cargoDetails.receiver.payer : '')
    const [conditions, setConditions] = useState<string>(typeof dispatch !== 'string' && dispatch.cargoDetails !== null && dispatch.cargoDetails.delivery.conditions !== null ? dispatch.cargoDetails.delivery.conditions : '')
    const [equipmentDetails, setEquipmentDetails] = useState<string>(typeof dispatch !== 'string' && dispatch.cargoDetails !== null && dispatch.cargoDetails.delivery.equipmentDetails !== null ? dispatch.cargoDetails.delivery.equipmentDetails : '')
    const [documentsWithCargo, setDocumentsWithCargo] = useState<boolean>(typeof dispatch !== 'string' && dispatch.cargoDetails !== null && dispatch.cargoDetails.delivery.documentsWithCargo !== null ? dispatch.cargoDetails.delivery.documentsWithCargo : false)

    async function fetchComents() {
        if (typeof dispatch !== 'string') {
            try {
                const responseComment = await axios.get(BACKEND_URL + '/api/v1/planned-dispatch/' + dispatch.id + '/comments', {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                })
                if (responseComment.status === 200 || responseComment.status === 201) {
                    setComments(responseComment.data)
                }
            } catch (e) {
                toast.error('Не удалось загрузить комментарии', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                })
                setComments(null)
            }
        }
    }

    useEffect(() => {
        fetchComents()
    }, [flag]);

    async function cancelDispatch() {
        if (typeof dispatch !== 'string') {
            try {
                const response = await toast.promise(axios.delete(BACKEND_URL + '/api/v1/planned-dispatch/' + dispatch.id + '/cancel', {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                }), {
                    pending: 'Отменяем отгрузку..',
                    success: 'Отгрузка успешно отменена',
                    error: 'Не удалось отменить отгрузку'
                })
                if (response.status === 200) {
                    navigate('/dispatch-calendar')
                }
            } catch (e) {
                return
            }
        }
    }

    async function editDate() {
        if (typeof dispatch !== 'string') {
            if (partOfDay === '' || (date === '' && partOfDay !== 'Не указано')) {
                toast.error('Заполните все поля!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                })
            } else {
                try {
                    const response = await toast.promise(axios.patch(BACKEND_URL + '/api/v1/planned-dispatch/' + dispatch.id, {}, {
                        params: {
                            date: partOfDay === 'Не указано' ? null : date,
                            partOfDay: partOfDay === 'Не указано' ? null : partOfDay
                        },
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('token')
                        }
                    }), {
                        pending: 'Меняем дату..',
                        success: 'Дата успешно изменена',
                        error: 'Не удалось изменить дату'
                    })
                    if (response.status === 200) {
                        window.location.reload()
                    }
                } catch (e) {
                    return
                }
            }
        }
    }

    async function editRequirements() {
        if (typeof dispatch !== 'string') {
            let temp: ITruePositionDetails[] = []
            positionDetails.forEach(pos => temp = [...temp, {mark: pos.mark, diameter: Number(pos.diameter), packing: pos.packing, melt: pos.melt ? pos.melt : null, batch: pos.batch ? pos.batch : null, tolerance: {from: Number(pos.from), to: Number(pos.to)}, totalWeight: Number(pos.totalWeight), preparedWeight: Number(pos.preparedWeight)}])
            try {
                const response = await toast.promise(axios.post(BACKEND_URL + '/api/v1/planned-dispatch/' + dispatch.id, {
                    positionsDetails: temp
                }, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                }), {
                    pending: 'Сохраняем требования..',
                    error: 'Ошибка сохранения требований',
                    success: 'Требования успешно сохранены'
                })
                if (response.status === 200) {
                    window.location.reload()
                }
            } catch (e) {
                return
            }
        }
    }

    function addRow() {
        let data = [...positionDetails, {
            mark: '',
            diameter: '',
            packing: '',
            melt: '',
            batch: '',
            totalWeight: '',
            preparedWeight: '',
            from: '',
            to: ''
        }]
        setPositionDetails(data)
    }

    function removeRow(index: number) {
        let data: IPositionDetails[] = [...positionDetails]
        data.splice(index, 1)
        setPositionDetails(data)
    }

    function triggerEdit() {
        setChangeRequirementsMode(true)
        if (typeof dispatch !== 'string') {
            let temp: IPositionDetails[] = []
            dispatch.positionsDetails.forEach(pos => temp = [...temp, {
                mark: pos.mark,
                diameter: String(pos.diameter),
                packing: pos.packing,
                melt: pos.melt === null ? '' : pos.melt,
                batch: pos.batch === null ? '' : pos.batch,
                totalWeight: String(pos.totalWeight),
                preparedWeight: String(pos.preparedWeight),
                from: String(pos.tolerance.from),
                to: String(pos.tolerance.to)
            }])
            console.log(temp)
            setPositionDetails(temp)
        }
    }

    function onChangeHandle(index: number, event: ChangeEvent<HTMLInputElement | HTMLSelectElement>, flag?: string) {
        let data: IPositionDetails[] = [...positionDetails]
        switch (flag) {
            case 'number':
                data[index][event.target.name as keyof IPositionDetails] = event.target.value.replace(/[^-.1234567890]+/g, '')
                break
            case 'positiveNumber':
                data[index][event.target.name as keyof IPositionDetails] = event.target.value.replace(/[^.1234567890]+/g, '')
                break
            default:
                data[index][event.target.name as keyof IPositionDetails] = event.target.value
        }
        setPositionDetails(data)
    }

    async function addPositions() {
        if (typeof dispatch !== 'string') {
            let stringIds: string[] = []
            if (!Array.isArray(ids)) {
                stringIds = ids.split(',')
            } else {
                stringIds = ids
            }
            let numberIds: number[] = []
            stringIds.forEach(id => numberIds = [...numberIds, Number(id)])
            try {
                const response = await toast.promise(axios.post(BACKEND_URL + '/api/v1/planned-dispatch/' + dispatch.id + '/positions', {
                    positionIds: numberIds
                }, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                }), {
                    pending: 'Добавляем позиции..',
                    error: 'Ошибка добавления позиций',
                    success: 'Позиции успешно добавлены!'
                })
                if (response.status === 200) {
                    window.location.reload()
                }
            } catch (e) {
                return
            }
        }
    }

    async function removePositions(idToDelete: number) {
        if (typeof dispatch !== 'string') {
            try {
                const response = await toast.promise(axios.post(BACKEND_URL + '/api/v1/planned-dispatch/' + dispatch.id + '/positions', {
                    deletedPositionIds: [idToDelete]
                }, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                }), {
                    pending: 'Удаляю позицию..',
                    error: 'Ошибка удаления позиции',
                    success: 'Позиции успешно удалены!'
                })
                if (response.status === 200) {
                    window.location.reload()
                }
            } catch (e) {
                return
            }
        }
    }

    async function postComment() {
        if (typeof dispatch !== 'string') {
            if (comment && comment.length > 500) {
                toast.error('Комментарий не может быть длиннее 500 символов', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                })
            } else if (!comment || comment.length === 0) {
                toast.error('Комментарий не может быть пустым', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                })
            } else {
                try {
                    const response = await toast.promise(axios.post(BACKEND_URL + '/api/v1/planned-dispatch/' + dispatch.id + '/comments', {
                        comment: comment
                    }, {
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('token')
                        }
                    }), {
                        pending: 'Отправка комментария..',
                        error: 'Ошибка отправки комментария',
                        success: 'Комментарий успешно отправлен!'
                    })
                    if (response.status === 200 || response.status === 201) {
                        setComment('')
                        document.getElementById('editable-span')!.textContent = '';
                        setFlag(!flag)
                    }
                } catch (e) {
                    return
                }
            }
        }
    }

    async function submitDispatch() {
        if (typeof dispatch !== 'string') {
            try {
                const response = await toast.promise(axios.post(BACKEND_URL + '/api/v1/planned-dispatch/' + dispatch.id + '/dispatch', {}, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                }), {
                    pending: 'Отгрузка в процессе..',
                    error: 'Произошла ошибка при отгрузке',
                    success: 'Отгрузка успешно прошла!'
                })
                if (response.status === 200 || response.status === 201) {
                    window.location.reload()
                }
            } catch (e) {
                return
            }
        }
    }

    async function updateCargoDetails() {
        if (typeof dispatch !== 'string') {
            try {
                const response = await toast.promise(axios.put(BACKEND_URL + '/api/v1/planned-dispatch/' + dispatch.id, {
                    receiver: {
                        name: name ? name : null,
                        inn: inn ? inn : null,
                        city: city ? city : null,
                        phone: phone ? phone : null,
                        payer: payer ? payer : null
                    },
                    delivery: {
                        conditions: conditions ? conditions : null,
                        equipmentDetails: equipmentDetails ? equipmentDetails : null,
                        documentsWithCargo: documentsWithCargo,
                    }
                }, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                }), {
                    pending: 'Сохранение..',
                    error: 'Произошла ошибка при сохранении',
                    success: 'Данные успешно обновлены!'
                })
                if (response.status === 200 || response.status === 201) {
                    window.location.reload()
                }
            } catch (e) {
                return
            }
        }
    }

    return (
        <>
            {
                localStorage.getItem('actions')?.includes('VIEW_PLANNED_DISPATCH') &&
                <div className='dispatch-body'>

                    {
                        typeof dispatch === 'string' ?
                            <h2 style={{color: 'crimson'}}>{dispatch}</h2>
                            :
                            <div className="container">
                                <h1>Отгрузка №{dispatch.id}</h1>
                                <h2 style={{marginTop: '20px', marginBottom: '26px'}}>Информация об отгрузке</h2>
                                {
                                    error && <h3 style={{color: 'crimson'}}>Список упаковок недоступен!</h3>
                                }
                                <div className="info-section">
                                    <div><strong>Клиент</strong><span>{dispatch.customer}</span></div>
                                    <div><strong>Счет</strong><span>{dispatch.bill}</span></div>
                                    <div><strong>Локация</strong><span>{dispatch.location}</span></div>
                                    <div>
                                        <strong>Дата</strong>
                                        {
                                            changeDateMode ?
                                                <div className='inputs-container'>
                                                    <select className='change-date-select' name="partOfDay"
                                                            id="partOfDay" value={partOfDay}
                                                            onChange={(event) => setPartOfDay(event.target.value)}
                                                            required>
                                                        <option selected disabled value=''>Время суток</option>
                                                        <option value="Утро">Утро</option>
                                                        <option value="День">День</option>
                                                        <option value="Вечер">Вечер</option>
                                                        <option value="Не указано">Не указано</option>
                                                    </select>
                                                    <input type="date" className='change-date-input' value={date}
                                                           min={new Date().toISOString().split('T')[0]}
                                                           onChange={(event) => setDate(event.target.value)}/>
                                                </div>
                                                :
                                                <span>{dispatch.partOfDay !== null ? dispatch.partOfDay : 'Не указано'} {dispatch.date !== null ? dispatch.date : ''}</span>
                                        }
                                    </div>
                                    <div><strong>Статус</strong><span>{dispatch.status}</span></div>
                                    <div>
                                        <strong>Создано</strong><span>{dispatch.createdBy.firstName} {dispatch.createdBy.secondName}, {dispatch.createdAt}</span>
                                    </div>
                                    <div>
                                        <strong>Перевозка ТК</strong><span>{dispatch.byCarrier ? 'Да' : 'Нет'}</span>
                                    </div>
                                </div>
                                {
                                    dispatch.byCarrier &&
                                    <>
                                        <h2 style={{marginTop: '20px', marginBottom: '26px'}}>Информация о ТК</h2>
                                        <div className='info-section'>
                                            <div>
                                                <strong>Получатель</strong>
                                                {
                                                    tkMode ?
                                                        <div className='inputs-container'>
                                                            <input type="text" style={{width: '250px'}} value={name} onChange={(event) => setName(event.target.value)} />
                                                        </div>
                                                        :
                                                        <span>{dispatch.cargoDetails !== null ? (dispatch.cargoDetails.receiver.name !== null ? dispatch.cargoDetails.receiver.name : '-') : '-'}</span>
                                                }
                                            </div>
                                            <div>
                                                <strong>ИНН</strong>
                                                {
                                                    tkMode ?
                                                        <div className='inputs-container'>
                                                            <input type="text" value={inn} onChange={(event) => setInn(event.target.value.replace(/[^1234567890]+/g, ''))} />
                                                        </div>
                                                        :
                                                        <span>{dispatch.cargoDetails !== null ? (dispatch.cargoDetails.receiver.inn !== null ? dispatch.cargoDetails.receiver.inn : '-') : '-'}</span>
                                                }
                                            </div>
                                            <div>
                                                <strong>Город</strong>
                                                {
                                                    tkMode ?
                                                        <div className='inputs-container'>
                                                            <input type="text" value={city} onChange={(event) => setCity(event.target.value)} />
                                                        </div>
                                                        :
                                                        <span>{dispatch.cargoDetails !== null ? (dispatch.cargoDetails.receiver.city !== null ? dispatch.cargoDetails.receiver.city : '-') : '-'}</span>
                                                }
                                            </div>
                                            <div>
                                                <strong>Телефон</strong>
                                                {
                                                    tkMode ?
                                                        <div className='inputs-container'>
                                                            <input type="text" value={phone} onChange={(event) => setPhone(event.target.value.replace(/[^+()1234567890-]+/g, ''))} />
                                                        </div>
                                                        :
                                                        <span>{dispatch.cargoDetails !== null ? (dispatch.cargoDetails.receiver.phone !== null ? dispatch.cargoDetails.receiver.phone : '-') : '-'}</span>
                                                }
                                            </div>
                                            <div>
                                                <strong>Плательщик</strong>
                                                {
                                                    tkMode ?
                                                        <div className='inputs-container'>
                                                            <select className='change-date-select' name="payer" style={{margin: '0', width: '150px'}}
                                                                    id="payer" value={payer}
                                                                    onChange={(event) => setPayer(event.target.value)}>
                                                                <option selected disabled value=''>Плательщик</option>
                                                                <option value="Покупатель">Покупатель</option>
                                                                <option value={`ООО ТД "Арсенал-Метиз"`}>ООО ТД "Арсенал-Метиз"</option>
                                                            </select>
                                                        </div>
                                                        :
                                                        <span>{dispatch.cargoDetails !== null ? (dispatch.cargoDetails.receiver.payer !== null ? dispatch.cargoDetails.receiver.payer : '-') : '-'}</span>
                                                }
                                            </div>
                                            <div>
                                                <strong>Условия доставки (примечания)</strong>
                                                {
                                                    tkMode ?
                                                        <div className='inputs-container'>
                                                            <textarea style={{width: '300px', boxSizing: 'border-box'}} rows={3} value={conditions}
                                                                   onChange={(event) => setConditions(event.target.value)}/>
                                                        </div>
                                                        :
                                                        <span>{dispatch.cargoDetails !== null ? (dispatch.cargoDetails.delivery.conditions !== null ? dispatch.cargoDetails.delivery.conditions : '-') : '-'}</span>
                                                }
                                            </div>
                                            <div>
                                            <strong>Комплектация</strong>
                                                {
                                                    tkMode ?
                                                        <div className='inputs-container'>
                                                            <input type="text" style={{width: '300px', boxSizing: 'border-box'}} value={equipmentDetails} onChange={(event) => setEquipmentDetails(event.target.value)} />
                                                        </div>
                                                        :
                                                        <span>{dispatch.cargoDetails !== null ? (dispatch.cargoDetails.delivery.equipmentDetails !== null ? dispatch.cargoDetails.delivery.equipmentDetails : '-') : '-'}</span>
                                                }
                                            </div>
                                            <div>
                                            <strong>Документы с перевозкой</strong>
                                                {
                                                    tkMode ?
                                                        <div className='checkbox-wrapper-31' style={{width: '35px', height: '35px'}}>
                                                            <input type="checkbox" checked={documentsWithCargo}
                                                                   onChange={(event) => setDocumentsWithCargo(event.target.checked)}/>
                                                            <svg viewBox='0 0 35.6 35.6'>
                                                                <circle className='background' cx='17.8' cy='17.8'
                                                                        r='17.8'></circle>
                                                                <circle className='stroke' cx='17.8' cy='17.8'
                                                                        r='14.37'></circle>
                                                                <polyline className='check'
                                                                          points="11.78 18.12 15.55 22.23 25.17 12.87"></polyline>
                                                            </svg>
                                                        </div>
                                                        :
                                                        <span>{dispatch.cargoDetails !== null ? (dispatch.cargoDetails.delivery.documentsWithCargo ? 'Да' : 'Нет') : 'Нет'}</span>
                                                }
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className='cancel-changedate-container'>
                                    {
                                        dispatch.byCarrier && !changeDateMode && !cancelMode && !tkMode && dispatch.status !== 'Отгружен' && localStorage.getItem('actions')?.includes('CHANGE_TK_INFO') &&
                                        <button type='button' style={{marginRight: '5px'}}
                                                onClick={() => setTkMode(true)}>Изменить ТК</button>
                                    }
                                    {
                                        !changeDateMode && !cancelMode && !tkMode && dispatch.status !== 'Отгружен' &&
                                        <button type='button' style={{marginRight: '5px', marginLeft: '5px'}}
                                                onClick={() => setChangeDateMode(true)}>Изменить дату</button>
                                    }
                                    {
                                    !changeDateMode && !cancelMode && !tkMode && dispatch.status !== 'Отгружен' &&
                                        <button type='button' style={{marginLeft: '5px'}}
                                                onClick={() => setCancelMode(true)}>Отменить отгрузку</button>
                                    }
                                    {
                                        changeDateMode &&
                                        <button type='button' className='save-btn' style={{marginRight: '5px'}}
                                                onClick={() => editDate()}>Сохранить</button>
                                    }
                                    {
                                        changeDateMode && <button type='button' style={{marginLeft: '5px'}}
                                                                  onClick={() => setChangeDateMode(false)}>Отменить
                                            редактирование</button>
                                    }
                                    {
                                        cancelMode && <p style={{margin: '0 20px 0 0'}}>Вы уверены?</p>
                                    }
                                    {
                                        cancelMode &&
                                        <button type='button' className='save-btn' style={{marginRight: '5px'}}
                                                onClick={() => cancelDispatch()}>Подтвердить</button>
                                    }
                                    {
                                        cancelMode && <button type='button' style={{marginLeft: '5px'}}
                                                              onClick={() => setCancelMode(false)}>Назад</button>
                                    }
                                    {
                                        tkMode &&
                                        <button type='button' className='save-btn' style={{marginRight: '5px'}}
                                                onClick={() => updateCargoDetails()}>Сохранить</button>
                                    }
                                    {
                                        tkMode && <button type='button' style={{marginLeft: '5px'}}
                                                                  onClick={() => setTkMode(false)}>Отменить
                                            редактирование</button>
                                    }
                                </div>
                                <h2 style={{
                                    marginTop: '26px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    Описание требуемых позиций
                                    {
                                        !changeRequirementsMode && localStorage.getItem('actions')?.includes('EDIT_DISPATCH_REQUIREMENTS') && dispatch.status !== 'Отгружен' &&
                                        <svg className='svg-change-requirements' onClick={() => triggerEdit()}
                                             height="800px" width="800px" version="1.1" id="Capa_1"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 348.882 348.882">
                                            <g>
                                                <path d="M333.988,11.758l-0.42-0.383C325.538,4.04,315.129,0,304.258,0c-12.187,0-23.888,5.159-32.104,14.153L116.803,184.231
                                                c-1.416,1.55-2.49,3.379-3.154,5.37l-18.267,54.762c-2.112,6.331-1.052,13.333,2.835,18.729c3.918,5.438,10.23,8.685,16.886,8.685
                                                c0,0,0.001,0,0.001,0c2.879,0,5.693-0.592,8.362-1.76l52.89-23.138c1.923-0.841,3.648-2.076,5.063-3.626L336.771,73.176
                                                C352.937,55.479,351.69,27.929,333.988,11.758z M130.381,234.247l10.719-32.134l0.904-0.99l20.316,18.556l-0.904,0.99
                                                L130.381,234.247z M314.621,52.943L182.553,197.53l-20.316-18.556L294.305,34.386c2.583-2.828,6.118-4.386,9.954-4.386
                                                c3.365,0,6.588,1.252,9.082,3.53l0.419,0.383C319.244,38.922,319.63,47.459,314.621,52.943z"/>
                                                <path d="M303.85,138.388c-8.284,0-15,6.716-15,15v127.347c0,21.034-17.113,38.147-38.147,38.147H68.904
                                                c-21.035,0-38.147-17.113-38.147-38.147V100.413c0-21.034,17.113-38.147,38.147-38.147h131.587c8.284,0,15-6.716,15-15
                                                s-6.716-15-15-15H68.904c-37.577,0-68.147,30.571-68.147,68.147v180.321c0,37.576,30.571,68.147,68.147,68.147h181.798
                                                c37.576,0,68.147-30.571,68.147-68.147V153.388C318.85,145.104,312.134,138.388,303.85,138.388z"/>
                                            </g>
                                        </svg>
                                    }
                                </h2>
                                {
                                    (dispatch.positionsDetails !== null || changeRequirementsMode) ?
                                        <table style={{marginBottom: '20px'}}>
                                            <thead>
                                            <tr>
                                                <th style={{width: '16.5%'}}>Марка</th>
                                                <th style={{width: '8.5%'}}>Диаметр</th>
                                                <th style={{width: '12.5%'}}>Упаковка</th>
                                                <th style={{width: '12.5%'}}>Плавка</th>
                                                <th style={{width: '12.5%'}}>Партия</th>
                                                <th style={{width: '12.5%'}}>Толеранс</th>
                                                <th style={{width: '12.5%'}}>Вес</th>
                                                {
                                                    !changeRequirementsMode &&
                                                    <th style={{width: '12.5%'}}>Собрано</th>
                                                }
                                                {
                                                    changeRequirementsMode &&
                                                    <th style={{width: '12.5%'}}>Действия</th>
                                                }
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                !changeRequirementsMode &&
                                                dispatch.positionsDetails.map((pos, index) => <tr key={index}>
                                                    <td>{pos.mark ? pos.mark : '-'}</td>
                                                    <td>{pos.diameter ? pos.diameter : '-'}</td>
                                                    <td style={{color: packings.filter(pack => pack.name === pos.packing).length > 0 ? '#555' : 'crimson'}}>{pos.packing ? pos.packing : '-'}</td>
                                                    <td>{pos.melt ? pos.melt : '-'}</td>
                                                    <td>{pos.batch ? pos.batch : '-'}</td>
                                                    <td>{String(pos.tolerance.from) && String(pos.tolerance.to) ? `${pos.tolerance.from} - ${pos.tolerance.to} %` : '-'}</td>
                                                    <td>{pos.totalWeight ? pos.totalWeight : '-'}</td>
                                                    <td style={(Number(pos.preparedWeight) >= Number(pos.totalWeight) + Number(pos.totalWeight) / 100 * Number(pos.tolerance.from) && Number(pos.preparedWeight) <= Number(pos.totalWeight) + Number(pos.totalWeight) / 100 * Number(pos.tolerance.to)) ? {color: '#00af3d'} : {color: 'crimson'}}>{pos.totalWeight ? `${pos.preparedWeight} / ${pos.totalWeight}` : '-'} </td>
                                                </tr>)
                                            }
                                            {
                                                changeRequirementsMode &&
                                                positionDetails.map((pos, index) => <tr key={index}>
                                                    <td>
                                                        <input type="text" className='requirements-input' name='mark'
                                                               value={pos.mark}
                                                               onChange={(event) => onChangeHandle(index, event)}/>
                                                    </td>
                                                    <td>
                                                        <input type="text" className='requirements-input'
                                                               name='diameter' value={pos.diameter} style={{width: '100%'}}
                                                               onChange={(event) => onChangeHandle(index, event, 'positiveNumber')}/>
                                                    </td>
                                                    <td>
                                                        <select name="packing" id="" className='requirements-input' value={pos.packing} onChange={event => onChangeHandle(index, event)}>
                                                            <option selected disabled value="">Выбрать..</option>
                                                            {packings.map(pack => <option value={pack.name}>{pack.name}</option>)}
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <input type="text" className='requirements-input' name='melt'
                                                               value={pos.melt}
                                                               onChange={(event) => onChangeHandle(index, event)}/>
                                                    </td>
                                                    <td>
                                                        <input type="text" className='requirements-input' name='batch'
                                                               value={pos.batch}
                                                               onChange={(event) => onChangeHandle(index, event)}/>
                                                    </td>
                                                    <td>
                                                        <input type="text" className='requirements-input-tolerance'
                                                               name='from' style={{marginRight: '2px'}} value={pos.from}
                                                               onChange={(event) => onChangeHandle(index, event, 'number')}/>
                                                        -
                                                        <input type="text" className='requirements-input-tolerance'
                                                               name='to' style={{marginLeft: '2px'}} value={pos.to}
                                                               onChange={(event) => onChangeHandle(index, event, 'number')}/>
                                                    </td>
                                                    <td>
                                                        <input type="text" className='requirements-input'
                                                               name='totalWeight' value={pos.totalWeight}
                                                               onChange={(event) => onChangeHandle(index, event, 'positiveNumber')}/>
                                                    </td>
                                                    <td>
                                                        <button type='button' className='remove-requirements-field-btn'
                                                                onClick={() => {
                                                                    if (positionDetails.length > 1) {
                                                                        removeRow(index)
                                                                    } else {
                                                                        toast.error('Нельзя удалить последнюю строку', {
                                                                            position: "top-right",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true,
                                                                            progress: undefined,
                                                                            theme: "light",
                                                                            transition: Bounce,
                                                                        })
                                                                    }
                                                                }}>X
                                                        </button>
                                                    </td>
                                                </tr>)
                                            }
                                            </tbody>
                                        </table>
                                        :
                                        <h2 style={{color: 'black'}}>Позиции не были указаны</h2>
                                }
                                {
                                    changeRequirementsMode &&
                                    <div className='cancel-changedate-container'
                                         style={{marginTop: '0', marginBottom: '10px'}}>
                                        <button type='button' className='save-btn' style={{marginRight: '4px'}}
                                                onClick={() => editRequirements()}>Сохранить
                                        </button>
                                        <button type='button' style={{marginRight: '4px', marginLeft: '4px'}}
                                                onClick={() => addRow()}>Добавить ряд
                                        </button>
                                        <button type='button' style={{marginLeft: '4px'}}
                                                onClick={() => setChangeRequirementsMode(false)}>Назад
                                        </button>
                                    </div>
                                }
                                <h2 className='testing' style={{marginTop: '0'}}>Привязанные позиции</h2>
                                <div className='card-container-reg' style={{marginBottom: '15px', gridGap: '20px 2%'}}>
                                    {dispatch.positions.map((pos, index) => <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center'
                                    }}>
                                        <NewCard card={pos} key={index}/>
                                        {
                                            localStorage.getItem('actions')?.includes('REMOVE_REQUIRED_POSITION') && dispatch.status !== 'Отгружен' &&
                                            <button className='delink-position'
                                                    onClick={() => removePositions(pos.id)}>Отвязать</button>
                                        }
                                    </div>)}
                                    {
                                        !addPositionsMode && localStorage.getItem('actions')?.includes('ADD_REQUIRED_POSITION') && dispatch.status !== 'Отгружен' &&
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center'
                                        }}>
                                            <div className='add-position-btn-container'
                                                 onClick={() => setAddPositionsMode(true)}>
                                                <button className='add-position-btn'>
                                                    <span className='material-symbols-rounded'>add</span>
                                                </button>
                                            </div>
                                        </div>
                                    }
                                    {
                                        addPositionsMode &&
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center'
                                        }}>
                                            <div className='new-card'
                                                 style={{border: '2px dashed #cccccc', backgroundColor: 'transparent'}}>
                                                <div className="main-card-body">
                                                <span className='new-card-title'>
                                                    <input type="text" value={ids} onContextMenu={showMenu} onClick={hideMenu} onBlur={() => {
                                                        if (!ulFocus) {
                                                            hideMenu()
                                                        }
                                                    }} onChange={event => setIds(event.target.value.replace(/[^,1234567890]+/g, ''))}/>
                                                </span>
                                                </div>
                                            </div>
                                            {
                                                menuVisible && <CustomContextMenu setValue={setIds} position={menuPosition} hideMenu={hideMenu} setFlag={setUlFocus}/>
                                            }
                                        </div>
                                    }
                                </div>
                                {
                                    addPositionsMode &&
                                    <div className='cancel-changedate-container'>
                                        <button type='button' className='save-btn' style={{marginRight: '5px'}}
                                                onClick={addPositions}>Добавить
                                        </button>
                                        <button type='button' style={{marginLeft: '5px'}}
                                                onClick={() => setAddPositionsMode(false)}>Назад
                                        </button>
                                    </div>
                                }
                                <h2 className='testing' style={{marginBottom: '-5px', marginTop: '5px'}}>Файлы</h2>
                                <div className="file-section">
                                    <div className='file-item'>
                                        <CustomFileInput id={dispatch.id} url={'files'}
                                                         requiredType={'PLANNED_DISPATCH_BILL'} files={dispatch.files}
                                                         header={'Счёт'} path={'planned-dispatch'}
                                        />
                                    </div>
                                    <div className='file-item'>
                                        <CustomFileInput id={dispatch.id} url={'files'}
                                                         requiredType={'PLANNED_DISPATCH_GRANT'} files={dispatch.files}
                                                         header={'Доверенность'} path={'planned-dispatch'}
                                        />
                                    </div>
                                    <div className='file-item'>
                                        <CustomFileInput id={dispatch.id} multiple={true} url={'files'}
                                                         requiredType={'PLANNED_DISPATCH_OTHER'} files={dispatch.files}
                                                         header={'Прочее'} path={'planned-dispatch'}
                                        />
                                    </div>
                                </div>
                                {
                                    dispatch.status === 'Подготовлен' && localStorage.getItem('actions')?.includes('DISPATCH_PLANNED_DISPATCH') &&
                                    <button className='confirm-dispatch-btn' onClick={submitDispatch}>Отгрузить</button>
                                }
                                <h2 style={{marginTop: '25px', marginBottom: '5px'}}>Комментарии</h2>
                                {
                                    typeof comments === 'string' ?
                                        <h3 style={{color: 'crimson'}}>{comments}</h3>
                                        :
                                        <div className='comment-section'>
                                            {comments !== null && comments.map((comment, index) => <div className='comment'>
                                                <span><strong>{comment.workerInfo.secondName} {comment.workerInfo.firstName}</strong>, {comment.date}</span>
                                                <span>{comment.comment}</span>
                                            </div>)}
                                        </div>
                                }
                                <span id='editable-span' className='leave-dispatch-comment' contentEditable role='textbox'
                                      onInput={event => setComment(event.currentTarget.textContent)}></span>
                                <div className='cancel-changedate-container' style={{justifyContent: 'space-between', alignItems: 'center', marginTop: '10px'}}>
                                    <span style={{color: comment && comment.length > 500 ? 'crimson' : '#555', margin: 'auto 0'}}>{comment?.length} / 500</span>
                                    <button type='button' className='save-btn'
                                            disabled={comment === null || comment === ''} onClick={postComment}>Отправить
                                    </button>
                                </div>
                            </div>
                    }
                    <ToastContainer/>
                </div>
            }
        </>
    )
}