import {useEffect, useState} from "react";
import axios from "axios";
import {BACKEND_URL} from "../ConstConfig";
import {ISetting} from "../interfaces/exportedInterfaces";

export function useSettingsDictionary(refetchFlag: boolean) {

    const [settings, setSettings] = useState<ISetting[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>('')

    async function fetchSettings() {
        setLoading(true)
        setError('')
        try {
            const response = await axios.get(BACKEND_URL + '/api/v1/settings', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            setSettings(response.data)
            setLoading(false)
        } catch (e) {
            setError('Не удалось получить список параметров')
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchSettings()
    }, [refetchFlag])

    return {settings, loading, error}
}