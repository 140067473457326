import '../css/CustomContextMenu.css'
import axios from "axios";
import {BACKEND_URL} from "../ConstConfig";
import {Bounce, toast} from "react-toastify";
import {IModelsCardForPackage} from "../interfaces/exportedInterfaces";
import React from "react";

interface CustomContextMenuProps {
    setValue?: (value: string) => void,
    position: {
        x: number,
        y: number
    },
    hideMenu: () => void,
    setFlag: (prop: boolean) => void,
    mode?: string,
    date?: string,
    id?: number,
    url?: string,
    focusInput?: () => void,
}

export function CustomContextMenu({setValue, position, hideMenu, setFlag, mode, date, id, url, focusInput}: CustomContextMenuProps) {

    async function updateCart() {
        try {
            const response = await axios.get(BACKEND_URL + '/api/v1/mobile-cart', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            let temp: string[] = []
            response.data.positions.forEach((position: IModelsCardForPackage) => temp = [...temp, String(position.id)])
            if (setValue) {
                setValue(temp.join(','))
            }
        } catch (e: unknown) {
            toast.error('Не удалось получить данные из корзины', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
        }
    }

    async function downloadFile() {
        try {
            const response = await toast.promise(axios.get(BACKEND_URL + '/api/v1/planned-dispatch/cargo-summary', {
                params: {
                    date: date
                },
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                responseType: 'blob'
            }), {
                pending: 'Скачиваем файл..',
                success: 'Файл успешно скачан',
                error: 'Не удалось скачать файл'
            })
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `Отгрузка ТК ${date}.xlsx`)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        } catch (e) {
            toast.error('Не удалось получить данные из корзины', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
        }
    }

    const handleClick = () => {
        if (focusInput) {
            focusInput()
        }
    }

    return (
        <div className='custom-context-menu' style={{top: position.y, left: position.x}}>
            {
                mode === 'CART' &&
                <div onClick={() => {
                    updateCart()
                    hideMenu()
                }} onMouseOver={() => setFlag(true)} onMouseOut={() => setFlag(false)}>Вставить из корзины</div>
            }
            {
                mode === 'CARGO' &&
                <div onClick={() => {
                    downloadFile()
                    hideMenu()
                }} onMouseOver={() => setFlag(true)} onMouseOut={() => setFlag(false)}>Загрузить отчёт</div>
            }
            {
                mode === 'PASTE' &&
                <div onClick={() => {
                    handleClick()
                    hideMenu()
                }} onMouseOver={() => setFlag(true)} onMouseOut={() => setFlag(false)}>Вставить</div>
            }
        </div>
    )
}