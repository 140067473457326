import {useEffect, useState} from "react";
import {IPlav} from "../interfaces/exportedInterfaces";
import axios, {AxiosError} from "axios";
import {BACKEND_URL} from "../ConstConfig";

export interface usePlavsPartsProps {
    pageNumber: number,
    userInput: string
}

export function usePlavs({pageNumber, userInput}: usePlavsPartsProps) {

    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>('')
    const [plavs, setPlavs] = useState<IPlav[]>([])

    async function fetchPlavs() {
        try {
            setError('')
            setLoading(true)
            const response = await axios.get(BACKEND_URL + '/api/v1/melt?page=' + pageNumber + '&size=20', {
                params: {
                    query: userInput
                },
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            setPlavs(response.data.content)
            setLoading(false)
        } catch (e: unknown) {
            setLoading(false)
            const error = e as AxiosError
            setError(error.message)
        }
    }

    useEffect(() => {
        fetchPlavs()
    }, [pageNumber, userInput])

    return {error, loading, plavs}
}