import {useState} from "react";
import axios from "axios";
import {BACKEND_URL} from "../ConstConfig";
import {ICertificate} from "../interfaces/exportedInterfaces";
import {CertificatesStepTwo} from "../components/CertificatesStepTwo";
import '../css/CertificatesPage.css'
import {Loader} from "../components/Loader";
import {useRegisterAutocomplete} from "../hooks/useRegisterAutocomplete";

export function CertificatesPage() {

    const [file, setFile] = useState<File | null>(null)
    const [error, setError] = useState<string>('')
    const [secondStepCertificate, setSecondStepCertificate] = useState<boolean>(false)
    const [fetchedDto, setFetchedDto] = useState<ICertificate | null>(null)
    const [loading, setLoading] = useState<boolean>(false)

    const {packings, error: packError} = useRegisterAutocomplete()


    async function sendFile() {
        setError('')
        setLoading(true)
        try {
            const response = await axios.post(BACKEND_URL + '/api/v1/certificate/upload', {
                file: file
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'multipart/form-data'
                }
            })
            if (response.status === 200) {
                setFetchedDto(response.data)
                setSecondStepCertificate(true)
                setLoading(false)
            }
        } catch (e: unknown) {
            setLoading(false)
            setError('Не удалось отправить файл')
        }
    }

    return (
        <>
            {
                localStorage.getItem('actions')?.includes('CERTIFICATE_SCAN') &&
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '100px'}}>
                    {error && <h2 style={{color: 'crimson'}}>{error}</h2>}
                    <label style={{fontSize: '20px'}} htmlFor="certificate"><strong>Выберите сертификат</strong></label>
                    <input style={{marginTop: '12px', marginBottom: '12px'}} id='certificate' name='certificate' type="file" accept='.docx' onChange={(event) => {
                        if (event.target.files) {
                            setFile(event.target.files[0])
                        }
                    }}/>
                    <button disabled={!file} className='cert-button' type='button' onClick={sendFile}>Отправить</button>
                    {loading && <Loader/>}
                    {secondStepCertificate && fetchedDto && <CertificatesStepTwo fetchedDto={fetchedDto} packings={packings} packError={packError}/>}
                </div>
            }
        </>
    )
}