import '../css/RegisterPage.css'
import React, {useState} from "react";
import {IPositionModel} from "../interfaces/exportedInterfaces";
import {useRegisterAutocomplete} from "../hooks/useRegisterAutocomplete";
import {ErrorMessage} from "../components/ErrorMessage";
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {RegisterStepTwo} from "../components/RegisterStepTwo";
import {ModalWindowAddStandard} from "../components/ModalWindowAddStandard";
import axios, {AxiosError} from "axios";
import {BACKEND_URL} from "../ConstConfig";
import {Bounce, toast, ToastContainer} from "react-toastify";
import {useMarksDictionary} from "../hooks/useMarksDictionary";
import {useStandardsDictionary} from "../hooks/useStandardsDictionary";

interface PartValidation {
    valid: boolean,
    data: {
        mark: string,
        plav: string
        diameter: number,
        packing: string,
    }
}

export interface SelectFields {
    value: string,
    label: string
}

export function RegisterPage() {

    const {packings, error, manufacturers} = useRegisterAutocomplete()

    const [disabledDiameter, setDisabledDiameter] = useState(false)
    const [disabledHeat, setDisabledHeat] = useState(false)
    const [disabledPart, setDisabledPart] = useState(false)
    const [disabledWeight, setDisabledWeight] = useState(false)
    const [disabledManufacturer, setDisabledManufacturer] = useState(false)
    const [disabledComment, setDisabledComment] = useState(false)
    const [disabledQuantity, setDisabledQuantity] = useState<boolean>(false)

    const [secondStepRegister, setSecondStepRegister] = useState(false)

    const [newRegister, setNewRegister] = useState(true)

    const [register, setRegister] = useState<IPositionModel>({
        mark: '',
        part: '',
        packing: '',
        plav: '',
        manufacturer: '',
        weight: 0,
        diameter: 0,
        comment: '',
        standard: {
            mark: '',
            standards: []
        }
    })

    const [mark, setMark] = useState<SelectFields | null>(null)
    const [part, setPart] = useState<string>('')
    const [packing, setPacking] = useState<string>('')
    const [plav, setPlav] = useState<string>('')
    const [manufacturer, setManufacturer] = useState<string>('')
    const [weight, setWeight] = useState<string>('')
    const [diameter, setDiameter] = useState<string>('')
    const [comment, setComment] = useState<string>('')
    const [gost, setGost] = useState<SelectFields[]>([])
    const [quantity, setQuantity] = useState<number>(1)

    const [packingRequiredFields, setPackingRequiredFields] = useState<string[]>([])

    const [partDetails, setPartDetails] = useState<PartValidation>({valid: false, data: {mark: '', diameter: 0, packing: '', plav: ''}})

    const [quantityError, setQuantityError] = useState('')
    const [fieldsError, setFieldsError] = useState('')
    const [weightError, setWeightError] = useState('')
    const [diameterError, setDiameterError] = useState('')
    const [partError, setPartError] = useState('')
    const [axiosError, setAxiosError] = useState('')
    const [newRegisterError, setNewRegisterError] = useState('')

    const [modal, setModal] = useState(false)

    const [customWeight, setCustomWeight] = useState<boolean>(false)

    const refetchFlag = true

    const {marks: newMarks, error: gradeError} = useMarksDictionary(refetchFlag)

    async function submitHandler(event: { preventDefault: () => void; }) {
        event.preventDefault()
        setFieldsError('')
        setQuantityError('')
        setWeightError('')
        setDiameterError('')
        setPartError('')
        if (quantity === 0) {
            setQuantityError('Введите количество больше 0')
        } else if ((diameter === '' && !disabledDiameter) ||
            (plav === '' && !disabledHeat) || (part === '' && !disabledPart) || (weight === '' && !disabledWeight) ||
            (manufacturer === '' && !disabledManufacturer)) {
            setFieldsError('Заполните пустые поля или заблокируйте их для заполнения на следующем шаге')
        } else if ((Number(weight) === 0 || isNaN(Number(weight))) && !disabledWeight) {
            setWeightError('Вес не может быть равен 0')
        } else if ((Number(diameter) === 0 || isNaN(Number(diameter))) && !disabledDiameter) {
            setDiameterError('Диаметр не может быть равен 0')
        } else {
            if ((disabledPart || disabledDiameter || disabledHeat) && !newRegister && mark !== null) {
                let gostArray: string[] = []
                gost.map(gost => gostArray = [...gostArray, gost.label])
                setRegister({
                    mark:  mark.value.trim(),
                    part: part.trim(),
                    packing: packing.trim(),
                    plav: plav.trim(),
                    manufacturer: manufacturer.trim(),
                    weight: Number(weight),
                    diameter: Number(diameter),
                    comment: comment.trim(),
                    standard: {
                        mark: mark.value.trim(),
                        standards: gostArray
                    }
                })
                setSecondStepRegister(true)
            } else {
                try {
                    const response = await axios.post(BACKEND_URL + '/api/v1/registration/validate', {
                        part: part.trim(),
                        data: {
                            mark: mark !== null ? mark.value.trim() : null,
                            plav: plav.trim(),
                            diameter: Number(diameter.trim()).toFixed(2),
                            packing: packing.trim()
                        }
                    }, {
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('token')
                        }
                    })
                    if (response.data.valid === true && mark !== null) {
                        let gostArray: string[] = []
                        gost.map(gost => gostArray = [...gostArray, gost.label])
                        setRegister({
                            mark: mark.value.trim(),
                            part: part.trim(),
                            packing: packing.trim(),
                            plav: plav.trim(),
                            manufacturer: manufacturer.trim(),
                            weight: Number(weight),
                            diameter: Number(diameter),
                            comment: comment.trim(),
                            standard: {
                                mark: mark.value.trim(),
                                standards: gostArray
                            }
                        })
                        setSecondStepRegister(true)
                    } else {
                        setPartError('Невалидная партия')
                        setPartDetails(response.data)
                    }
                } catch (e: unknown) {
                    const error = e as AxiosError
                    setAxiosError('Ошибка соединения с сервером: ' + error.message)
                }
            }
        }
    }

    const [manufacturerAutocomplete, setManufacturerAutocomplete] = useState(false)
    const [ulFocus, setUlFocus] = useState(false)
    const [options, setOptions] = useState<SelectFields[]>([])

    const animatedComponents = makeAnimated();

    function clickHandler(event: string, setState: (prop: string) => void, setFlag: (prop: boolean) => void) {
        setState(event)
        setFlag(false)
    }

    function showAutocomplete(input: string, items: string[], setState: (prop: string) => void,
                              setItemAutocomplete: (prop: boolean) => void, isMark: boolean,
                              ulClass: string, liClass: string, emClass: string) {
        if (items.filter((item) => item.toLowerCase().includes(input.toLowerCase())).length) {
            return (
                <ul className={ulClass}>
                    {items.filter((item) => item.toLowerCase().includes(input.toLowerCase()))
                        .map((item, index) =>
                            <li className={liClass} key={index} onMouseOver={() => setUlFocus(true)}
                                onMouseOut={() => setUlFocus(false)}
                                onClick={event => {
                                    if (isMark) {
                                        // gostOptions(event.currentTarget.innerText)
                                        setGost([])
                                    }
                                    clickHandler(event.currentTarget.innerText, setState, setItemAutocomplete)
                                    setUlFocus(false)
                                }}>{item}</li>
                        )}
                </ul>
            )
        } else {
            return (
                <div className={emClass}>
                    <em>Нет элементов</em>
                </div>
            )
        }
    }

    // function gostOptions(input: string) {
    //     const standard = standards.find(element => element.toLowerCase() === input.toLowerCase())
    //     if (standard !== undefined) {
    //         let appropriateOptions: SelectFields[] = []
    //         standard.map((string) => appropriateOptions = [...appropriateOptions, {
    //             value: string,
    //             label: string
    //         }])
    //         setOptions(appropriateOptions)
    //     } else {
    //         setOptions([])
    //     }
    // }

    async function fetchPart() {
        setNewRegisterError('')
        try {
            const response = await axios.post(BACKEND_URL + '/api/v1/batch', {
                name: part
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            setDiameter(response.data.diameter)
            setPlav(response.data.melt)
            setPacking(response.data.packing)
            setManufacturer(response.data.manufacturer)
            let temp: SelectFields[] = []
            response.data.standard.map((std: string) => temp = [...temp, {value: std, label: std}])
            setOptions(temp)
            const responseMark = await axios.post(BACKEND_URL + '/api/v1/melt', {
                name: response.data.melt
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            setMark(responseMark.data.mark)
        } catch (e: unknown) {
            toast.error('Партия не найдена, включена старая регистрация', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
            setNewRegister(false)
        }
    }

    const realMarks = () => {
        let temp: SelectFields[] = []
        newMarks.map(mark => temp = [...temp, {value: mark.grade, label: mark.grade}])
        return temp
    }

    return (
        <>
            {
                localStorage.getItem('actions')?.includes('REGISTRATION') &&
                <div className='register-page'>
                    {modal && <ModalWindowAddStandard openModal={setModal}/>}
                    {!secondStepRegister &&
                        <div className='margin-block'>
                            {
                                partError &&
                                <div className='part-error-wrapper'>
                                    <div className='part-error'>
                                        <h3>Корректные поля для указанной партии</h3>
                                        <p><span>Марка: </span>{partDetails.data.mark}</p>
                                        <p><span>Плавка: </span>{partDetails.data.plav}</p>
                                        <p><span>Диаметр: </span>{partDetails.data.diameter.toFixed(2)}</p>
                                        <p><span>Упаковка: </span>{partDetails.data.packing}</p>
                                    </div>
                                </div>
                            }
                            {
                                (localStorage.getItem('actions')?.includes('REGISTRATION_NEW') ||
                                localStorage.getItem('actions')?.includes('REGISTRATION_DYNAMIC') ||
                                localStorage.getItem('actions')?.includes('ADD_STANDARD')) &&
                                <div className='register-block-button'>
                                    {
                                        localStorage.getItem('actions')?.includes('REGISTRATION_DYNAMIC') &&
                                        <div className='switchbar-reg'>
                                            <div style={{marginBottom: '-7px'}}>Разные веса</div>
                                            {
                                                ((!disabledWeight && !disabledQuantity) || (disabledWeight && disabledQuantity)) &&
                                                <label className='switch'>
                                                    <input type='checkbox' checked={customWeight} onChange={() => {
                                                        if (!customWeight) {
                                                            setCustomWeight(true)
                                                            setDisabledWeight(true)
                                                            setDisabledQuantity(true)
                                                        } else {
                                                            setCustomWeight(false)
                                                            setDisabledWeight(false)
                                                            setDisabledQuantity(false)
                                                        }
                                                    }}/>
                                                    <span className='slider round'></span>
                                                </label>
                                            }
                                        </div>
                                    }
                                    {
                                        localStorage.getItem('actions')?.includes('ADD_STANDARD') &&
                                        <button className='add-standard-button' onClick={() => setModal(true)}>Добавить
                                            стандарт</button>
                                    }
                                </div>
                            }
                            {!newRegister &&
                                <form className='register-block' onSubmit={submitHandler} autoComplete='off'>
                                    {
                                        error && <div>
                                            <ErrorMessage error={error}/>
                                            <h4 className='reg-error'>Автозаполнения и/или список упаковок не
                                                загрузились</h4>
                                        </div>
                                    }
                                    {
                                        axiosError && <div>
                                            <ErrorMessage error={axiosError}/>
                                        </div>
                                    }
                                    {fieldsError && <h4 className='reg-error'>{fieldsError}</h4>}
                                    <div className='register-input' style={{marginBottom: '10px'}}>
                                        <label htmlFor="mark">Марка:</label>
                                        <label htmlFor='mark' className='warn-label'>Обязательное поле</label>




                                        {/*<input type="text" name="" id="mark" value={mark}*/}
                                        {/*       onChange={event => {*/}
                                        {/*           const input: string = event.target.value*/}
                                        {/*           setMark(input)*/}
                                        {/*           setGost([])*/}
                                        {/*           gostOptions(input)*/}
                                        {/*       }}*/}
                                        {/*       onFocus={() => setMarkAutocomplete(true)} onBlur={() => {*/}
                                        {/*    if (ulFocus) {*/}
                                        {/*        return*/}
                                        {/*    } else {*/}
                                        {/*        setMarkAutocomplete(false)*/}
                                        {/*    }*/}
                                        {/*}} required autoComplete='off'/>*/}



                                        <Select placeholder='Выберите маркy' id='mark' value={mark} name='mark'
                                                className='basic-multi-select'
                                                classNamePrefix='select' options={realMarks()} closeMenuOnSelect={false}
                                                components={animatedComponents} defaultValue={mark} required
                                                noOptionsMessage={() => 'Нет подходящих марок'}
                                                isClearable //@ts-ignore
                                                onChange={setMark}/>




                                        {/*{mark !== '' && markAutocomplete && !error && showAutocomplete(mark, marks, setMark,*/}
                                        {/*    setMarkAutocomplete, true, 'suggestions-reg', 'suggestion-hoverable-reg', 'no-suggestions-reg')}*/}
                                    </div>
                                    <div className='register-input'>
                                        <Select placeholder='Выберите ГОСТ' isMulti id='gost' value={gost} name='gost'
                                                className='basic-multi-select'
                                                classNamePrefix='select' options={options} closeMenuOnSelect={false}
                                                components={animatedComponents} defaultValue={gost} required
                                                noOptionsMessage={() => 'Нет подходящих ГОСТов'}
                                                isClearable //@ts-ignore
                                                onChange={setGost}/>
                                    </div>
                                    <div className='register-input'>
                                        <label htmlFor='packing'>Упаковка: </label>
                                        <label htmlFor='packing' className='warn-label'>Обязательное поле</label>
                                        <select name="packing" id="packing" required onChange={event => {
                                            setPacking(event.target.value)
                                            setPackingRequiredFields(packings.find(pack => pack.name === event.target.value)!.requiredFields)
                                        }}>
                                            <option selected disabled value=''>Выбрать..</option>
                                            {packings.map(pack => <option value={pack.name}>{pack.name}</option>)}
                                        </select>
                                    </div>
                                    <div className='register-input'>
                                        <button className='lock-btn' onClick={(event) => {
                                            event.preventDefault()
                                            setDisabledDiameter(!disabledDiameter)
                                            setDiameter('')
                                        }}>Заблокировать поле
                                        </button>
                                        <label htmlFor="diameter">Диаметр:</label>
                                        {diameterError &&
                                            <label htmlFor="diameter" className='error-label'>{diameterError}</label>}
                                        <input type="text" name="" id="diameter" value={diameter}
                                               disabled={disabledDiameter}
                                               onChange={event => setDiameter(event.target.value.replace(/[^.1234567890]+/g, ''))}
                                               autoComplete='off'/>
                                    </div>
                                    <div className='register-input'>
                                        <button className='lock-btn' onClick={(event) => {
                                            event.preventDefault()
                                            setDisabledHeat(!disabledHeat)
                                            setPlav('')
                                        }}>Заблокировать поле
                                        </button>
                                        <label htmlFor="heat">Плавка:</label>
                                        <input type="text" name="" id="heat" disabled={disabledHeat} value={plav}
                                               autoComplete='off'
                                               onChange={event => setPlav(event.target.value)}/>
                                    </div>
                                    <div className='register-input'>
                                        <button className='lock-btn' onClick={(event) => {
                                            event.preventDefault()
                                            setDisabledPart(!disabledPart)
                                            setPart('')
                                        }}>Заблокировать поле
                                        </button>
                                        <label htmlFor="part">Партия:</label>
                                        {partError && <label htmlFor="part" className='error-label'>{partError}</label>}
                                        <input type="text" name="" id="part" value={part}
                                               onChange={event => setPart(event.target.value.toUpperCase())}
                                               disabled={disabledPart} autoComplete='off'/>
                                    </div>
                                    <div className='register-input'>
                                        {
                                            !customWeight &&
                                            <button className='lock-btn' onClick={(event) => {
                                                event.preventDefault()
                                                setDisabledWeight(!disabledWeight)
                                                setWeight('')
                                            }}>Заблокировать поле
                                            </button>
                                        }
                                        <label htmlFor="weight">Вес:</label>
                                        {weightError &&
                                            <label htmlFor="weight" className='error-label'>{weightError}</label>}
                                        <input type="text" name="" id="weight" value={weight}
                                               onChange={event => setWeight(event.target.value.replace(/[^.1234567890]+/g, ''))}
                                               disabled={disabledWeight} autoComplete='off'/>
                                    </div>
                                    <div className='register-input'>
                                        <button className='lock-btn' onClick={(event) => {
                                            event.preventDefault()
                                            setDisabledManufacturer(!disabledManufacturer)
                                            setManufacturer('')
                                        }}>Заблокировать поле
                                        </button>
                                        <label htmlFor="manufacturer">Производитель:</label>
                                        <input type="text" name="" id="manufacturer" value={manufacturer}
                                               disabled={disabledManufacturer}
                                               onChange={event => setManufacturer(event.target.value)}
                                               onFocus={() => setManufacturerAutocomplete(true)}
                                               onBlur={() => {
                                                   if (ulFocus) {
                                                       return
                                                   } else {
                                                       setManufacturerAutocomplete(false)
                                                   }
                                               }} autoComplete='off'/>
                                        {manufacturer !== '' && !error && manufacturerAutocomplete && showAutocomplete(manufacturer,
                                            manufacturers, setManufacturer, setManufacturerAutocomplete, false,
                                            'suggestions-reg', 'suggestion-hoverable-reg', 'no-suggestions-reg')}
                                    </div>
                                    <div className='register-input-quantity'>
                                        {quantityError && <h4 className='reg-error'>{quantityError}</h4>}
                                        <label htmlFor="quantity">Количество:</label>
                                        <div className='quantity-input-buttons' id="quantity">
                                            <input type="text" name="" value={quantity}
                                                   onChange={event => setQuantity(Number(event.target.value.replace(/[^1234567890]+/g, '')))}
                                                   required autoComplete='off' disabled={disabledQuantity}/>
                                            {
                                                !disabledQuantity &&
                                                <div className='form-btns'>
                                                    <button type='button' className='form-btn' onClick={(event) => {
                                                        setQuantity(quantity + 1)
                                                    }}>+
                                                    </button>
                                                    <button type='button' className='form-btn' onClick={(event) => {
                                                        if (quantity <= 1) {
                                                            return
                                                        } else {
                                                            setQuantity(quantity - 1)
                                                        }
                                                    }}>-
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='register-input-textarea'>
                                        <button className='lock-btn-comment' onClick={(event) => {
                                            event.preventDefault()
                                            setDisabledComment(!disabledComment)
                                            setComment('')
                                        }}>Заблокировать поле
                                        </button>
                                        <label htmlFor="comment">Комментарий:</label>
                                        <label htmlFor='comment' className='comment-add-info'>Можно оставить пустым или
                                            заполнить на следующем шаге</label>
                                        <textarea name="" id="comment" cols={Number("45")} rows={Number("4")}
                                                  disabled={disabledComment} autoComplete='off'
                                                  value={comment} onChange={event => setComment(event.target.value)}/>
                                    </div>
                                    <button type='submit' className='form-main-button'>Зарегистрировать</button>
                                </form>
                            }
                            {newRegister &&
                                <form className='register-block' onSubmit={submitHandler} autoComplete='off'>
                                    <div className='register-input'>
                                        <label htmlFor="part">Партия:</label>
                                        <label htmlFor='mark' className='warn-label'>Обязательное поле</label>
                                        {newRegisterError &&
                                            <label htmlFor="part" className='error-label'>{newRegisterError}</label>}
                                        <input type="text" name="" id="part" value={part}
                                               onChange={event => setPart(event.target.value)}
                                               onBlur={() => fetchPart()}
                                        />
                                    </div>
                                    <div className='register-input'>
                                        <label htmlFor="heat">Плавка:</label>
                                        <input type="text" name="" id="heat" value={plav} disabled={newRegister}
                                               onChange={event => setPlav(event.target.value)}/>
                                    </div>
                                    <div className='register-input' style={{marginBottom: '10px'}}>
                                        <Select placeholder='Выберите маркy' id='mark' value={mark} name='mark'
                                                className='basic-multi-select'
                                                classNamePrefix='select' options={realMarks()} closeMenuOnSelect={false}
                                                components={animatedComponents} defaultValue={mark} required
                                                noOptionsMessage={() => 'Нет подходящих марок'} isDisabled={newRegister}
                                                isClearable //@ts-ignore
                                                onChange={setMark}/>
                                    </div>
                                    <div className='register-input'>
                                        <Select placeholder='Выберите ГОСТ' isMulti id='gost' value={gost} name='gost'
                                                className='basic-multi-select'
                                                classNamePrefix='select' options={options} closeMenuOnSelect={false}
                                                components={animatedComponents} defaultValue={gost} required
                                                noOptionsMessage={() => 'Нет подходящих ГОСТов'} isClearable //@ts-ignore
                                                onChange={setGost}/>
                                    </div>
                                    <div className='register-input'>
                                        <label htmlFor="diameter">Диаметр:</label>
                                        {diameterError && <label htmlFor="diameter" className='error-label'>{diameterError}</label>}
                                        <input type="text" name="" id="diameter" value={diameter} disabled={newRegister}
                                               onChange={event => setDiameter(event.target.value.replace(/[^.1234567890]+/g, ''))}/>
                                    </div>
                                    <div className='register-input'>
                                        <label htmlFor="package">Упаковка:</label>
                                        <input type="text" name="" id="package" value={packing} disabled={newRegister}
                                               onChange={event => setPacking(event.target.value)}
                                        />
                                    </div>
                                    <div className='register-input'>
                                        <label htmlFor="manufacturer">Производитель:</label>
                                        <input type="text" name="" id="manufacturer" value={manufacturer} disabled={newRegister}
                                               onChange={event => setManufacturer(event.target.value)}
                                        />
                                    </div>
                                    <div className='register-input'>
                                        {
                                            !customWeight &&
                                            <button className='lock-btn' onClick={(event) => {
                                                event.preventDefault()
                                                setDisabledWeight(!disabledWeight)
                                                setWeight('')
                                            }}>Заблокировать поле
                                            </button>
                                        }
                                        <label htmlFor="weight">Вес:</label>
                                        {weightError &&
                                            <label htmlFor="weight" className='error-label'>{weightError}</label>}
                                        <input type="text" name="" id="weight" value={weight}
                                               onChange={event => setWeight(event.target.value.replace(/[^.1234567890]+/g, ''))}
                                               disabled={disabledWeight}/>
                                    </div>
                                    <div className='register-input-quantity'>
                                        {quantityError && <h4 className='reg-error'>{quantityError}</h4>}
                                        <label htmlFor="quantity">Количество:</label>
                                        <div className='quantity-input-buttons' id="quantity">
                                            <input type="text" name="" value={quantity} disabled={disabledQuantity}
                                                   onChange={event => setQuantity(Number(event.target.value.replace(/[^1234567890]+/g, '')))}
                                                   required/>
                                            {
                                                !disabledQuantity &&
                                                <div className='form-btns'>
                                                    <button type='button' className='form-btn' onClick={(event) => {
                                                        setQuantity(quantity + 1)
                                                    }}>+
                                                    </button>
                                                    <button type='button' className='form-btn' onClick={(event) => {
                                                        if (quantity <= 1) {
                                                            return
                                                        } else {
                                                            setQuantity(quantity - 1)
                                                        }
                                                    }}>-
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='register-input-textarea'>
                                        <button className='lock-btn-comment' onClick={(event) => {
                                            event.preventDefault()
                                            setDisabledComment(!disabledComment)
                                            setComment('')
                                        }}>Заблокировать поле
                                        </button>
                                        <label htmlFor="comment">Комментарий:</label>
                                        <label htmlFor='comment' className='comment-add-info'>Можно оставить пустым или
                                            заполнить на следующем шаге</label>
                                        <textarea name="" id="comment" cols={Number("45")} rows={Number("4")}
                                                  disabled={disabledComment}
                                                  value={comment} onChange={event => setComment(event.target.value)}/>
                                    </div>
                                    <button type='submit' className='form-main-button'>Зарегистрировать</button>
                                </form>
                            }
                        </div>
                    }
                    {secondStepRegister &&
                        <RegisterStepTwo quantity={quantity} quantityBlock={disabledQuantity} diameterBlock={disabledDiameter} plavBlock={disabledHeat}
                                         partBlock={disabledPart} commentBlock={disabledComment}
                                         manufacturerBlock={disabledManufacturer}
                                         weightBlock={disabledWeight} registerProp={register}
                                         setRegisterProp={setRegister} packs={packings} manufacturers={manufacturers}
                                         ulFocus={ulFocus} showAutocomplete={showAutocomplete} requiredFields={packingRequiredFields}/>
                    }
                    <ToastContainer/>
                </div>
            }
        </>
    )
}