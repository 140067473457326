import ReactDom from "react-dom";
import React, {ChangeEvent, useState} from "react";
import axios, {AxiosError} from "axios";
import {BACKEND_URL} from "../ConstConfig";
import '../css/ModalWindowAddPlav.css'
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {SelectFields} from "../pages/RegisterPage";
import {useRegisterAutocomplete} from "../hooks/useRegisterAutocomplete";

interface InputFieldsProps {
    element: string,
    quantity: string
}

interface ModalWindowAddPlavProps {
    openModal: (prop: boolean) => void,
    predefinedPlav?: string,
    predefinedMark?: string
}

export function ModalWindowAddPlav({openModal, predefinedPlav, predefinedMark}: ModalWindowAddPlavProps) {

    const portalElement: HTMLElement = document.getElementById('portal')!

    const {standards} = useRegisterAutocomplete()

    const [error, setError] = useState<string>('')
    const [quantityError, setQuantityError] = useState<string>('')
    const [mark, setMark] = useState<SelectFields | undefined>(() => {
        return predefinedMark ? {label: predefinedMark, value: predefinedMark} : undefined
    })
    const [plav, setPlav] = useState<string>(() => {
        return predefinedPlav ? predefinedPlav : ''
    })
    const [inputFields, setInputFields] = useState<InputFieldsProps[]>([
        {element: '', quantity: ''}
    ])
    const options = ['Si', 'C', 'Mn', 'S', 'P', 'Cr', 'Ni', 'Cu', 'Ti', 'Mo', 'W', 'V', 'N', 'Al', 'As', 'Zr', 'Co']
    const disabledOptions = [false, false, false, false, false, false, false, false, false, false, false ,false, false, false, false, false, false]
    const [disabledFields, setDisabledFields] = useState(disabledOptions)
    // const [optionsMark, setOptionsMark] = useState<SelectFields[]>(() => {
    //     let temp: SelectFields[] = []
    //     standards.map((std, index) => temp = [...temp, {label: std.mark, value: std.mark}])
    //     return temp
    // })
    // const [availableOptions, setAvailableOptions] = useState(options)
    const optionsMark = () => {
        let temp: SelectFields[] = []
        standards.map((std, index) => temp = [...temp, {label: std.mark, value: std.mark}])
        return temp
    }

    const animatedComponents = makeAnimated();

    const handleOnChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>, index: number) => {
        let data: InputFieldsProps[]  = [...inputFields]
        data[index][event.target.name as keyof InputFieldsProps] = event.target.value
        setInputFields(data)
    }

    const handleOnChangeSelect = (event: ChangeEvent<HTMLSelectElement>, index: number) => {
        let data: InputFieldsProps[]  = [...inputFields]
        let temp = [...disabledFields]
        if (data[index].element === '') {
            temp[options.indexOf(event.target.value)] = true
            setDisabledFields(temp)
        } else {
            temp[options.indexOf(inputFields[index].element)] = false
            temp[options.indexOf(event.target.value)] = true
            setDisabledFields(temp)
        }
        data[index][event.target.name as keyof InputFieldsProps] = event.target.value
        setInputFields(data)
    }

    const addFields = () => {
        let newField = {element: '', quantity: ''}
        setInputFields([...inputFields, newField])
    }

    const removeFields = (index: number) => {
        let data = [...inputFields]

        if (data[index].element !== '') {
            let temp = [...disabledFields]
            temp[options.indexOf(inputFields[index].element)] = false
            setDisabledFields(temp)
        }

        data.splice(index, 1)
        setInputFields(data)
    }

    async function sendRequest(event: { preventDefault: () => void; }) {
        event.preventDefault()
        setQuantityError('')
        setError('')
        let sum: number = 0
        inputFields.forEach(el => sum = sum + Number(el.quantity))
        if (sum !== 100) {
            setQuantityError('Состав всех элементов не равняется 100%')
        } else {
            const chemicals = {
                Si: inputFields.find((el) => el.element === 'Si')?.quantity.trim(),
                C: inputFields.find((el) => el.element === 'C')?.quantity.trim(),
                Mn: inputFields.find((el) => el.element === 'Mn')?.quantity.trim(),
                S: inputFields.find((el) => el.element === 'S')?.quantity.trim(),
                P: inputFields.find((el) => el.element === 'P')?.quantity.trim(),
                Cr: inputFields.find((el) => el.element === 'Cr')?.quantity.trim(),
                Ni: inputFields.find((el) => el.element === 'Ni')?.quantity.trim(),
                Cu: inputFields.find((el) => el.element === 'Cu')?.quantity.trim(),
                Ti: inputFields.find((el) => el.element === 'Ti')?.quantity.trim(),
                Mo: inputFields.find((el) => el.element === 'Mo')?.quantity.trim(),
                W: inputFields.find((el) => el.element === 'W')?.quantity.trim(),
                V: inputFields.find((el) => el.element === 'V')?.quantity.trim(),
                N: inputFields.find((el) => el.element === 'N')?.quantity.trim(),
                Al: inputFields.find((el) => el.element === 'Al')?.quantity.trim(),
                As: inputFields.find((el) => el.element === 'As')?.quantity.trim(),
                Zr: inputFields.find((el) => el.element === 'Zr')?.quantity.trim(),
                Co: inputFields.find((el) => el.element === 'Co')?.quantity.trim()
            }
            try {
                const response = await axios.put(BACKEND_URL + '/api/v1/melt', {
                    mark: mark?.label.trim(),
                    melt: plav.trim(),
                    chemicals: chemicals
                }, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                })
                if (response.status === 200) {
                    window.location.reload()
                }
            } catch (e: unknown) {
                const error = e as AxiosError
                setError(error.message)
            }
        }
    }

    return ReactDom.createPortal(
        <form className='modalWindow' onSubmit={sendRequest} autoComplete='off'>
            <div className='modalBackground' id='bg' onClick={() => openModal(false)}/>
            <div className='modalContainer' id='container'>
                <div className='titleCloseBtn'>
                    <button type='button' id='closeBtn' onClick={() => openModal(false)}>X</button>
                </div>
                <div className='title-plavs'>
                    <h1>Добавление плавки</h1>
                    {error && <h3 style={{color: 'red'}}>Ошибка добавления плавки: {error}</h3>}
                </div>
                <div className='body'>
                    <div className='modalInput' style={{marginBottom: '-5px'}}>
                        <label htmlFor="plav">Название плавки:</label>
                        <input type="text" id='plav' placeholder='Введите плавку' value={plav}
                               onChange={(event) => setPlav(event.target.value)} required/>
                    </div>
                    <div className='modalInput'>
                        {/*<label htmlFor="mark">Марка:</label>*/}
                        {/*<input type="text" id='mark' placeholder='Введите марку' value={mark}*/}
                        {/*       onChange={(event) => setMark(event.target.value)} required/>*/}
                        <Select placeholder='Выберите марку' id='mark' value={mark} name='mark'
                                className='basic-multi-select'
                                classNamePrefix='select' options={optionsMark()} closeMenuOnSelect={true}
                                components={animatedComponents} required
                                noOptionsMessage={() => 'Нет подходящих марок'} isClearable //@ts-ignore
                                onChange={setMark}/>
                    </div>
                    <h4 style={{margin: '0'}}>Хим. состав:</h4>
                    {quantityError && <h5 style={{color: 'red', margin: '0'}}>{quantityError}</h5>}
                    {inputFields.map((field, index) => <div key={index} className='modalInputPlav'>
                        {/*<input name='element' type="text" placeholder='Элемент' value={field.element} onChange={event => handleOnChange(event, index)}/>*/}
                        <select required name="element" placeholder='Элемент' value={field.element}
                                onChange={event => handleOnChangeSelect(event, index)}>
                            <option selected disabled value=''>Элемент</option>
                            {options.map((option, newIndex) => <option disabled={disabledFields[newIndex]} value={option} key={newIndex}>{option}</option>)}
                        </select>
                        <input required name='quantity' type="text" placeholder='Содержание (%)' value={field.quantity} onChange={event => handleOnChange(event, index)}/>
                        <button type='button' className='remove-plav-field-btn' onClick={() => {
                            if (inputFields.length > 1) {
                                removeFields(index)
                            }
                        }}>X</button>
                    </div>)}
                    <button type='button' className='add-plav-button-small' onClick={addFields}>Добавить поле</button>
                </div>
                <div className='footer'>
                    <button type='button' id='cancelBtn' onClick={() => openModal(false)}>Отменить</button>
                    <button type='submit' id='confirmBtn'>Добавить</button>
                </div>
            </div>
        </form>,
        portalElement
    )
}