import {Bounce, toast, ToastContainer} from "react-toastify";
import React, {useState} from "react";
import {usePackingsDictionary} from "../hooks/usePackingsDictionary";
import {Loader} from "../components/Loader";
import Select from "react-select";
import {SelectFields} from "./RegisterPage";
import makeAnimated from "react-select/animated";
import axios from "axios";
import {BACKEND_URL} from "../ConstConfig";

export function PackingsDirectoryPage() {

    const [newPacking, setNewPacking] = useState<string>('')
    const [newRequiredFields, setNewRequiredFields] = useState<SelectFields[]>([])
    const [refetchFlag, setRefetchFlag] = useState<boolean>(false)

    const animatedComponents = makeAnimated();

    const {packings, loading, error} = usePackingsDictionary(refetchFlag)

    const options: SelectFields[] = [
        {value: 'mark', label: 'Марка'},
        {value: 'diameter', label: 'Диаметр'},
        {value: 'part', label: 'Партия'},
        {value: 'plav', label: 'Плавка'},
        {value: 'manufacturer', label: 'Производитель'},
        {value: 'weight', label: 'Вес'}
    ]

    async function savePacking(event: { preventDefault: () => void; }) {
        event.preventDefault()
        let temp: string[] = []
        newRequiredFields.forEach(item => temp = [...temp, item.value])
        try {
            const response = await toast.promise(axios.post(BACKEND_URL + '/api/v1/dictionary/packing', {
                name: newPacking,
                requiredFields: temp.length === 0 ? null : temp
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }), {
                pending: 'Сохраняется упаковка..',
                success: 'Упаковка добавлена',
                error: 'Ошибка добавления упаковки'
            })
            if (response.status === 200 || response.status === 201) {
                setRefetchFlag(!refetchFlag)
            }
        } catch (e) {
            return
        }
    }

    return (
        <>
            {
                localStorage.getItem('actions')?.includes('DIRECTORIES_PACKINGS') &&
                !loading ?
                    <div className='dispatch-body'>
                        {
                            error && <h1 style={{color: 'crimson'}}>{error}</h1>
                        }
                        {
                            !error &&
                            <form className='container' onSubmit={savePacking}>
                                <table style={{marginBottom: '20px'}}>
                                    <thead>
                                    <tr>
                                        <th style={{width: '30%'}}>Упаковка</th>
                                        <th style={{width: '70%'}}>Требуемые поля</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        packings.map((packing, index) => <tr key={index}>
                                            <td>{packing.name}</td>
                                            <td>{packing.requiredFields.join(', ')}</td>
                                        </tr>)
                                    }
                                    {
                                        localStorage.getItem('actions')?.includes('EDIT_PACKINGS') &&
                                        <tr>
                                            <td>
                                                <input type="text" className='requirements-input'
                                                       style={{width: '100%'}}
                                                       name='newPacking' value={newPacking} required
                                                       onChange={(event) => setNewPacking(event.target.value)}/>
                                            </td>
                                            <td>
                                                <Select placeholder='Выберите поля' isMulti id='gost'
                                                        value={newRequiredFields} required
                                                        name='newRequiredFields'
                                                        className='basic-multi-select'
                                                        classNamePrefix='select' options={options}
                                                        closeMenuOnSelect={false}
                                                        components={animatedComponents}
                                                        noOptionsMessage={() => 'Нет подходящих ГОСТов'}
                                                        isClearable //@ts-ignore
                                                        onChange={setNewRequiredFields}/>
                                            </td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>
                                {
                                    localStorage.getItem('actions')?.includes('EDIT_PACKINGS') &&
                                    <div className='cancel-changedate-container' style={{margin: '0'}}>
                                        <button type='submit' className='save-btn'>Добавить упаковку</button>
                                    </div>
                                }
                            </form>
                        }
                    </div>
                    :
                    <div style={{height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Loader/>
                    </div>
            }
            <ToastContainer/>
        </>
    )
}