import React, {useRef, useState} from "react";
import {DispatchDetails} from "../pages/DispatchCalendarPage";
import {IPlannedDispatch} from "../interfaces/exportedInterfaces";
import useCustomContextMenu from "../hooks/useCustomContextMenu";
import {CustomContextMenu} from "./CustomContextMenu";

interface CollapsibleDispatchesProps {
    dispatches: IPlannedDispatch[],
    date: string,
    day: string,
    showMenu: (event: any) => void,
    hideMenu: () => void,
    ulFocus: boolean,
    setUlFocus: (prop: boolean) => void,
}

export function CollapsibleDispatches({dispatches, date, day, showMenu, hideMenu, ulFocus, setUlFocus}: CollapsibleDispatchesProps) {

    const [isOpen, setIsOpen] = useState(false);

    const toggleCollapse = () => {
        setIsOpen(prevState => !prevState);
    };

    // const [height, setHeight] = useState<string>('0px');

    const contentRef = useRef<HTMLDivElement | null>(null)

    // useEffect(() => {
    //     if (isOpen && contentRef.current) {
    //         setHeight(`${contentRef.current.scrollHeight}px`);
    //     } else {
    //         setHeight('0px');
    //     }
    // }, [isOpen])

    return (
        <div className="collapsible"
             onClick={toggleCollapse}
             style={{
                 display: dispatches.filter(dispatch => dispatch.date === date && dispatch.partOfDay === day && dispatch.byCarrier).length > 0 ?
                     'inline-block'
                     :
                     'none'
             }}
             tabIndex={0}
             onContextMenu={showMenu}
             onBlur={() => {
                 if (!ulFocus) {
                     hideMenu()
                 }
             }}
             onMouseOver={() => setUlFocus(true)}
             onMouseOut={() => setUlFocus(false)}
        >
            <div className="collapsible-header">
                <h3 style={{color: '#555', margin: '15px 0', fontSize: '18px'}}>Отгрузки ТК</h3>
                <span id='arrow' style={{marginLeft: '8px', marginRight: '0', borderColor: '#555'}} className={isOpen ? 'arrow down up' : 'arrow down adown'}></span>
            </div>

            <div className={`collapsible-content ${isOpen ? 'open' : ''}`} ref={contentRef}>
                {dispatches.filter(dispatch => dispatch.date === date && dispatch.partOfDay === day && dispatch.byCarrier)
                    .map((dispatch, idx) => {
                        if (dispatch.date === date && dispatch.partOfDay === day) {
                            return (
                                <>
                                    <DispatchDetails event={dispatch} idx={idx}/>
                                </>
                            )
                        } else {
                            return null
                        }
                    })}
            </div>
        </div>
    )
}