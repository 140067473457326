import '../css/ScanPage.css'
import {SearchAutoComplete} from "../components/SearchAutoComplete";
import {useEffect, useState} from "react";
import {SearchTableView} from "../components/SearchTableView";
import {ISearchAutoComplete, ISearchModels} from "../interfaces/exportedInterfaces";
import axios, {AxiosError} from "axios";
import {ErrorMessage} from "../components/ErrorMessage";
import {BACKEND_URL} from "../ConstConfig";
import useToken from "../hooks/useToken";
import {useParams} from "react-router-dom";

export function SearchPage() {
    const {token} = useToken()
    const [request, setRequest] = useState(false)
    const [state, setState] = useState<ISearchAutoComplete>({
        activeSuggestion: 0,
        markSuggestions: [],
        partSuggestions: [],
        heatSuggestions: [],
        showSuggestions: false,
        userInput: ''
    })
    const [models, setModels] = useState<ISearchModels[]>([])
    const [error, setError] = useState('')
    const [clicked, setClicked] = useState<boolean>(false)
    const [flag, setFlag] = useState<boolean>(false)

    let {selectedMark} = useParams()

    useEffect(() => {
        if (selectedMark !== undefined) {
            state.userInput = selectedMark.replace('+', '/')
            submitHandler()
        }
    }, [selectedMark])

    useEffect(() => {
        if (state.userInput !== '') {
            submitHandler()
        }
    }, [clicked])

    async function submitHandler() {
        try {
            setError('')
            const response = await axios.post(BACKEND_URL + '/api/v1/search/params', {
                query: state.userInput
            }, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
            setModels(response.data.rows)
            setRequest(true)
        } catch (e: unknown) {
            const error = e as AxiosError
            setError(error.message)
        }
    }

    return (
        <>
            {
                localStorage.getItem('actions')?.includes('SEARCH') &&
                <div>
                    <form className='input-block'>
                        <button type='button' onClick={submitHandler} style={{fontSize: '16px'}}>Найти</button>
                        <SearchAutoComplete state={state} setState={setState} requestTrigger={clicked} setRequestTrigger={setClicked}
                            flag={flag} setFlag={setFlag}/>
                    </form>
                    {error && <ErrorMessage error={error}/>}
                    {request &&
                        <SearchTableView models={models}/>
                    }
                </div>
            }
        </>
    )
}