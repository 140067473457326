import '../css/PlavPage.css'
import {usePlavs} from "../hooks/usePlavs";
import {useState} from "react";
import {Loader} from "../components/Loader";
import {ModalWindowAddPlav} from "../components/ModalWindowAddPlav";

export function PlavPage() {

    const [pageNumber, setPageNumber] = useState(0)
    const [modal, setModal] = useState<boolean>(false)
    const [userInput, setUserInput] = useState<string>('')

    const {error, loading, plavs} = usePlavs({pageNumber: pageNumber, userInput: userInput})

    return (
        <>
            {
                localStorage.getItem('actions')?.includes('MELT_INFO') &&
                <div className='plav-page-container'>
                    {modal && <ModalWindowAddPlav openModal={setModal}/>}
                    <div className='upper-plav-page-container'>
                        <div className='search-bar-container'>
                            <input placeholder='Начните ввод для поиска..' type="text" value={userInput} onChange={event => setUserInput(event.target.value)}/>
                        </div>
                        {
                            localStorage.getItem('actions')?.includes('ADD_MELT') &&
                            <button className='add-plav-button' type='button' onClick={() => setModal(true)}>Добавить плавку</button>
                        }
                    </div>
                    <div className='plav-page-table-container'>
                        {loading && <Loader/>}
                        {error && <h2 style={{color: 'red'}}>{error}</h2>}
                        {!error && !loading &&
                            <table className='plav-page-table'>
                                <thead>
                                <tr>
                                    <th style={{width: '25%'}}>Плавка</th>
                                    <th style={{width: '25%'}}>Марка</th>
                                    <th style={{width: '50%'}}>Хим. свойства</th>
                                </tr>
                                </thead>
                                <tbody>
                                {plavs.map((plav, index) =>
                                    <tr>
                                        <td>{plav.melt}</td>
                                        <td>{plav.mark}</td>
                                        <td>
                                            {Object.keys(plav.chemicals).map(chem => `${chem} = ${plav.chemicals[chem]}, `)}
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        }
                    </div>
                    <div className='plav-page-table-page-container'>
                        <button type='button' onClick={() => {
                            if (pageNumber > 0) {
                                setPageNumber(pageNumber - 1)
                            } else {
                                return
                            }
                        }}>{'<'}</button>
                        <span>стр: {pageNumber + 1}</span>
                        <button type='button' onClick={() => {
                            if (plavs.length === 20) {
                                setPageNumber(pageNumber + 1)
                            } else {
                                return
                            }
                        }}>{'>'}</button>
                    </div>
                </div>
            }
        </>
    )
}