import ReactDom from "react-dom";
import {ModalWindowProps} from "./ModalWindowAddStandard";
import React, {ChangeEvent, useState} from "react";
import {ToastContainer} from "react-toastify";
import {IStandardIdInfo} from "../interfaces/exportedInterfaces";

interface NewInputFieldsProps {
    name: string,
    type: string,
    min?: string,
    max?: string,
    options?: string,
    value?: string
}

interface ThisModalWindowProps {
    selectedGrade: string,
    standard: IStandardIdInfo | string,
    openModal: (prop: boolean) => void
}

export function ModalWindowAddMechanicalProperties({openModal, selectedGrade, standard}: ThisModalWindowProps) {

    const portalElement: HTMLElement = document.getElementById('portal')!

    const [inputFields, setInputFields] = useState<NewInputFieldsProps[]>([
        {name: '', type: '', min: '', max: '', options: '', value: ''}
    ])

    const options = [
        'RANGE',
        'MIN',
        'MAX',
        'SELECT',
        'TEXT'
    ]

    const handleOnChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>, index: number) => {
        let data: NewInputFieldsProps[]  = [...inputFields]
        data[index][event.target.name as keyof NewInputFieldsProps] = event.target.value
        setInputFields(data)
    }

    const removeFields = (index: number) => {
        let data = [...inputFields]
        data.splice(index, 1)
        setInputFields(data)
    }

    const addFields = () => {
        let newField = {name: '', type: '', min: '', max: '', options: '', value: ''}
        setInputFields([...inputFields, newField])
    }

    async function sendRequest() {

    }

    return ReactDom.createPortal(
        <form className='modalWindow' onSubmit={sendRequest}>
            <div className='modalBackground' id='bg' onClick={() => openModal(false)}/>
            <div className='modalContainer' id='container' style={{height: '400px'}}>
                <div className='titleCloseBtn'>
                    <button type='button' id='closeBtn' onClick={() => openModal(false)}>X</button>
                </div>
                <div className='title'>
                    <h1 style={{color: '#555', marginTop: '0'}}>Добавление мех. свойств</h1>
                </div>
                <div className='body'>
                    {inputFields.map((field, index) => <div key={index} className='modalInputPlav'>
                        <input required name='name' type="text" placeholder='Свойство' className='requirements-input new-design-input' style={{width: '100px'}}
                               value={field.name} onChange={event => handleOnChange(event, index)}/>
                        <select required name="type" placeholder='Тип' value={field.type} className='requirements-input new-design-select'
                                onChange={event => handleOnChange(event, index)}>
                            <option selected disabled value=''>Тип</option>
                            {options.map((option, newIndex) => <option value={option} key={newIndex}>{option}</option>)}
                        </select>
                        {
                            field.type === 'RANGE' &&
                            <>
                                <input required name='min' type="text" placeholder='От' style={{width: '120px'}} className='requirements-input new-design-input'
                                       value={field.min} onChange={event => handleOnChange(event, index)}/>
                                -
                                <input required name='max' type="text" placeholder='До' style={{width: '120px'}} className='requirements-input new-design-input'
                                       value={field.max} onChange={event => handleOnChange(event, index)}/>
                            </>
                        }
                        {
                            field.type === 'MIN' &&
                            <input required name='min' type="text" placeholder='Не менее' style={{width: '120px'}} className='requirements-input new-design-input'
                                   value={field.min} onChange={event => handleOnChange(event, index)}/>
                        }
                        {
                            field.type === 'MAX' &&
                            <input required name='max' type="text" placeholder='Не более' style={{width: '120px'}} className='requirements-input new-design-input'
                                   value={field.max} onChange={event => handleOnChange(event, index)}/>
                        }
                        <button type='button' className='remove-requirements-field-btn' style={{width: '34px', height: '34px'}} onClick={() => {
                            if (inputFields.length > 1) {
                                removeFields(index)
                            }
                        }}>X
                        </button>
                    </div>)}
                    <div className='cancel-changedate-container' style={{justifyContent: 'center'}}>
                        <button type='button' className='new-design-btn' onClick={addFields}>Добавить поле</button>
                    </div>
                </div>
                <div className='footer'>
                    <div className='cancel-changedate-container'>
                        <button type='button' className='new-design-btn'
                                style={{marginRight: '10px', width: '170px', fontSize: '20px', height: '40px'}}
                                onClick={() => openModal(false)}>Отменить
                        </button>
                        <button type='submit' className='save-btn new-design-btn'
                                style={{marginLeft: '10px', width: '170px', fontSize: '20px', height: '40px'}}>Добавить
                        </button>
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </form>,
        portalElement
    )
}