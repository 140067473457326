import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './css/app.css'
import {LoginPage} from "./pages/LoginPage";
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from "react-router-dom";
import {SkladPage} from "./pages/SkladPage";
import {RegisterPage} from "./pages/RegisterPage";
import {ShowPage} from "./pages/ShowPage";
import {ScanPage} from "./pages/ScanPage";
import {SearchPage} from "./pages/SearchPage";
import {CombinePage} from "./pages/CombinePage";
import {DispatchPage} from "./pages/DispatchPage";
import {DispatchHistoryPage} from "./pages/DispatchHistoryPage";
import {AdmissionPage} from "./pages/AdmissionPage";
import {SendPage} from "./pages/SendPage";
import {PositionPage} from "./pages/PositionPage";
import {PositionDetails} from "./components/PositionDetails";
import {PositionPrint} from "./components/PositionPrint";
import {PositionShipping} from "./components/PositionShipping";
import {MeltSearchWeights, weightLoader} from "./components/MeltSearchWeights";
import {ReservePage} from "./pages/ReservePage";
import {ReserveDetailsPage, reserveIdLoader} from "./pages/ReserveDetailsPage";
import {ReserveConfirm} from "./components/ReserveConfirm";
import {ReserveDispatch} from "./components/ReserveDispatch";
import {ReserveDetails} from "./components/ReserveDetails";
import {WelcomePage} from "./pages/WelcomePage";
import {SendHistoryPage} from "./pages/SendHistoryPage";
import {SendDetails, transferLoader} from "./components/SendDetails";
import {PricesPage} from "./pages/PricesPage";
import {DispatchIdInfo, dispatchIdLoader} from "./components/DispatchIdInfo";
import {PartPage} from "./pages/PartPage";
import {PlavPage} from "./pages/PlavPage";
import {CertificatesPage} from "./pages/CertificatesPage";
import {PartPageInfo, partPageLoader} from "./components/PartPageInfo";
import {loader as cardIdLoader} from './pages/PositionPage'
import {SchemePage} from "./pages/SchemePage";
import {InventorizationPage} from "./pages/InventorizationPage";
import {WeightTransferPage} from "./pages/WeightTransferPage";
import {PositionPartialPrint} from "./components/PositionPartialPrint";
import {CartPage} from "./pages/CartPage";
import {DispatchCalendarPage} from "./pages/DispatchCalendarPage";
import {plannedDispatchLoader, PlannedDispatchPage} from "./pages/PlannedDispatchPage";
import {PositionSplit} from "./components/PositionSplit";
import {PositionHistory} from "./components/PositionHistory";
import {PackingsDirectoryPage} from "./pages/PackingsDirectoryPage";
import {MarksDirectoryPage} from "./pages/MarksDirectoryPage";
import {SettingsDirectoryPage} from "./pages/SettingsDirectoryPage";
import {ActionsDirectoryPage} from "./pages/ActionsDirectoryPage";
import {StandardsDirectoryPage} from "./pages/StandardsDirectoryPage";
import {StandardIdInfo, standardIdLoader} from "./components/StandardIdInfo";
import {TicketsPage} from "./pages/TicketsPage";
import {TicketIdInfo, ticketIdLoader} from "./components/TicketIdInfo";

const JSXRoutes = (
    <>
        <Route path='/' element={<SkladPage/>} children={
            <>
                <Route path='/' element={<WelcomePage/>}/>
                <Route path='/register' element={<RegisterPage/>}/>
                <Route path='/show' element={<ShowPage/>}/>
                <Route path='/search' element={<ScanPage/>}/>
                <Route path='/melt-search/:selectedMark?' element={<SearchPage/>}/>
                <Route path='/weights/:mark/:diameter/:packing/:plav/:part/:selectedSklad?' loader={weightLoader} element={<MeltSearchWeights/>}/>
                <Route path='/combine' element={<CombinePage/>}/>
                <Route path='/dispatch' element={<DispatchPage/>}/>
                <Route path='/dispatch-history' element={<DispatchHistoryPage/>}/>
                <Route path='/dispatch-history/:id/info' loader={dispatchIdLoader} element={<DispatchIdInfo/>}/>
                <Route path='/dispatch-calendar' element={<DispatchCalendarPage/>}/>
                <Route path='/planned-dispatch/:id' loader={plannedDispatchLoader} element={<PlannedDispatchPage/>}/>
                <Route path='/admission' element={<AdmissionPage/>}/>
                <Route path='/send' element={<SendPage/>}/>
                <Route path='/send-details/:id' loader={transferLoader} element={<SendDetails/>}/>
                <Route path='/send-history' element={<SendHistoryPage/>}/>
                <Route path='/reserve/:mark?/:diameter?/:packing?' element={<ReservePage/>}/>
                <Route path='/prices' element={<PricesPage/>}/>
                <Route path='/part' element={<PartPage/>}/>
                <Route path='/part/:batch/info' loader={partPageLoader} element={<PartPageInfo/>}/>
                <Route path='/plav' element={<PlavPage/>}/>
                <Route path='/certificates' element={<CertificatesPage/>}/>
                <Route path='/scheme' element={<SchemePage/>}/>
                <Route path='/inventory' element={<InventorizationPage/>}/>
                <Route path='/weight-transfer' element={<WeightTransferPage/>}/>
                <Route path='/cart' element={<CartPage/>}/>
                <Route path='/packings' element={<PackingsDirectoryPage/>}/>
                <Route path='/marks' element={<MarksDirectoryPage/>}/>
                <Route path='/settings' element={<SettingsDirectoryPage/>}/>
                <Route path='/actions' element={<ActionsDirectoryPage/>}/>
                <Route path='/standards' element={<StandardsDirectoryPage/>}/>
                <Route path='/standard/:id/info' loader={standardIdLoader} element={<StandardIdInfo/>}/>
                <Route path='/tickets' element={<TicketsPage/>}/>
                <Route path='/ticket/:id' loader={ticketIdLoader} element={<TicketIdInfo/>}/>
            </>
        }/>
        <Route path='/login' element={<LoginPage/>}/>

        <Route path='/position/:id' loader={cardIdLoader} element={<PositionPage/>} children={
            <>
                <Route path='/position/:id/info' element={<PositionDetails/>}/>
                <Route path='/position/:id/print' element={<PositionPrint/>}/>
                <Route path='/position/:id/dispatch' element={<PositionShipping/>}/>
                <Route path='/position/:id/partial-print' element={<PositionPartialPrint/>}/>
                <Route path='/position/:id/split' element={<PositionSplit/>}/>
                <Route path='/position/:id/history' element={<PositionHistory/>}/>
            </>
        }/>

        <Route path='/reserve/:id' loader={reserveIdLoader} element={<ReserveDetailsPage/>} children={
            <>
                <Route path='/reserve/:id/info' element={<ReserveDetails/>}/>
                <Route path='/reserve/:id/confirm' element={<ReserveConfirm/>}/>
                <Route path='/reserve/:id/dispatch' element={<ReserveDispatch/>}/>
            </>
        }/>

    </>
)

const routes = createRoutesFromElements(JSXRoutes);
const router = createBrowserRouter(routes);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <RouterProvider
        router={router}
    />
);

