import '../css/Card.css'
import {IPositionsForDispatch, IPositionsResponse, IStoragePositions} from "../interfaces/exportedInterfaces";
import {Link} from "react-router-dom";
import React, {useState} from "react";

export interface OldModelCardProps {
    card: IPositionsResponse,
    selectedCards?: string[],
    setSelectedCards?: (prop: string[]) => void,
    check?: boolean,
    setCheck?: (prop: boolean) => void
}

export interface NewModelCardProps {
    card: IPositionsForDispatch | IPositionsResponse,
    selectedCards?: string[],
    setSelectedCards?: (prop: string[]) => void,
    check?: boolean,
    setCheck?: (prop: boolean) => void
}

export function Card({card, selectedCards, setSelectedCards, check, setCheck}: OldModelCardProps) {

    const [clicked, setClicked] = useState<boolean>(false)

    const borderStyle = () => {
        if (card.status === 'В наличии') {
            return '3px solid #3b4da3'
        } else if (card.status === 'Отгружено') {
            return '3px solid gray'
        } else if (card.status === 'Резерв') {
            return '3px solid rgb(253, 185, 0)'
        } else {
            return '3px dashed #3b4da3'
        }
    }

    function cartActions() {
        if (setCheck) {
            setCheck(!check)
        }
        setClicked(!clicked)
        if (selectedCards?.includes(String(card.id))) {
            const index = selectedCards.indexOf(String(card.id))
            if (setSelectedCards) {
                let temp = selectedCards
                temp.splice(index, 1)
                setSelectedCards(temp)
            }
        } else {
            if (setSelectedCards && selectedCards) {
                let temp: string[] = selectedCards
                temp = [...temp, String(card.id)]
                setSelectedCards(temp)
            }
        }
    }

    if (localStorage.getItem('actions')?.includes('POSITION_INFO_BY_ID') && !selectedCards) {
        return (
            <Link className="card-item" target="_blank" to={'/position/' + card.id + '/info'} style={{border: borderStyle()}}>
                <div>
                    <p className="card-item__title">{card.type}</p>
                    <p className="card-item__text"><span>Марка:</span> {card.mark}</p>
                    <p className="card-item__text"><span>Диаметр:</span> {card.diameter}</p>
                    <p className="card-item__text"><span>Упаковка:</span> {card.packing}</p>
                    <p className="card-item__text"><span>Партия:</span> {card.part}</p>
                    <p className="card-item__text"><span>Плавка:</span> {card.plav}</p>
                    <p className="card-item__text"><span>Вес:</span> {card.weight}</p>
                    {card.comment && <p className="card-item__text"><span>Комментарий:</span> {card.comment}</p>}
                    <p className="card-item__text"><span>Статус:</span> {card.status}</p>
                    <p className="card-item__text"><span>Склад:</span> {card.location}</p>
                    {card.storageLocation !== null && <p className="card-item__text"><span>Ряд:</span> {card.storageLocation.row}</p>}
                    {card.storageLocation !== null && <p className="card-item__text"><span>Место:</span> {card.storageLocation.place}</p>}
                    {card.storageLocation !== null && <p className="card-item__text"><span>Тип:</span> {card.storageLocation.storageType}</p>}
                </div>
            </Link>
        )
    } else if (selectedCards) {
        return (
            <div className='card-item' style={{borderColor: clicked ? 'green' : '#3b4da3'}} onClick={cartActions}>
                <p className="card-item__title">{card.type}</p>
                <p className="card-item__text"><span>Марка:</span> {card.mark}</p>
                <p className="card-item__text"><span>Диаметр:</span> {card.diameter}</p>
                <p className="card-item__text"><span>Упаковка:</span> {card.packing}</p>
                <p className="card-item__text"><span>Партия:</span> {card.part}</p>
                <p className="card-item__text"><span>Плавка:</span> {card.plav}</p>
                <p className="card-item__text"><span>Вес:</span> {card.weight}</p>
                {card.comment && <p className="card-item__text"><span>Комментарий:</span> {card.comment}</p>}
                <p className="card-item__text"><span>Статус:</span> {card.status}</p>
                <p className="card-item__text"><span>Склад:</span> {card.location}</p>
                {card.storageLocation !== null && <p className="card-item__text"><span>Ряд:</span> {card.storageLocation.row}</p>}
                {card.storageLocation !== null && <p className="card-item__text"><span>Место:</span> {card.storageLocation.place}</p>}
                {card.storageLocation !== null && <p className="card-item__text"><span>Тип:</span> {card.storageLocation.storageType}</p>}
            </div>
        )
    } else {
        return (
            <div className='card-item-normal' style={{border: borderStyle()}}>
                <p className="card-item__title">{card.type}</p>
                <p className="card-item__text"><span>Марка:</span> {card.mark}</p>
                <p className="card-item__text"><span>Диаметр:</span> {card.diameter}</p>
                <p className="card-item__text"><span>Упаковка:</span> {card.packing}</p>
                <p className="card-item__text"><span>Партия:</span> {card.part}</p>
                <p className="card-item__text"><span>Плавка:</span> {card.plav}</p>
                <p className="card-item__text"><span>Вес:</span> {card.weight}</p>
                {card.comment && <p className="card-item__text"><span>Комментарий:</span> {card.comment}</p>}
                <p className="card-item__text"><span>Статус:</span> {card.status}</p>
                <p className="card-item__text"><span>Склад:</span> {card.location}</p>
                {card.storageLocation !== null && <p className="card-item__text"><span>Ряд:</span> {card.storageLocation.row}</p>}
                {card.storageLocation !== null && <p className="card-item__text"><span>Место:</span> {card.storageLocation.place}</p>}
                {card.storageLocation !== null && <p className="card-item__text"><span>Тип:</span> {card.storageLocation.storageType}</p>}
            </div>
        )
    }
}