import '../css/Loader.css'

interface LoaderProps {
    color?: boolean
}

export function Loader({color}: LoaderProps) {
    return (
        <div className="lds-ripple">
            <div style={{border: color ? '4px solid white' : '4px solid #3b4da3'}}></div>
            <div style={{border: color ? '4px solid white' : '4px solid #3b4da3'}}></div>
        </div>
    )
}