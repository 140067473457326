import ReactDom from "react-dom";
import React, {useState} from "react";
import {IStoragePlace} from "../interfaces/exportedInterfaces";
import '../css/ModalWindowScheme.css'
import {SpecialCard} from "./SpecialCard";

interface ModalWindowSchemeProps {
    chosenType: string,
    chosenFloor: string,
    chosenRow: string,
    openModal: (prop: boolean) => void,
    scheme: IStoragePlace[]
}

export function ModalWindowScheme({scheme, chosenType, chosenFloor, chosenRow, openModal}: ModalWindowSchemeProps) {

    const portalElement: HTMLElement = document.getElementById('portal')!

    const [selectedFloor, setSelectedFloor] = useState<string>('')
    const [selectedPlace, setSelectedPlace] = useState<string>('')

    return ReactDom.createPortal(
        <form className='modalWindow' autoComplete='off'>
            <div className='modalBackground' id='bg' onClick={() => openModal(false)}/>
            <div className='modalContainerReserve adding-reserve' id='container'>
                <div className='titleCloseBtn'>
                    <button type='button' id='closeBtn' onClick={() => openModal(false)}>X</button>
                </div>
                <div className='body'>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        {
                            chosenType === 'Стеллажи' && chosenFloor === '1' &&
                            <div className='modalInputScheme'>
                                <div className='triple-switch-container'>
                                    <div className='triple-switch'>
                                        <span id='span-one' className='item-notclicked' onClick={() => {
                                            setSelectedFloor('0')
                                            const element1 = document.getElementById('span-one')!
                                            const element2 = document.getElementById('span-two')!
                                            const element3 = document.getElementById('span-three')!
                                            const element4 = document.getElementById('span-four')!
                                            element1.classList.add('item-clicked')
                                            element2.classList.remove('item-clicked')
                                            element3.classList.remove('item-clicked')
                                            element4.classList.remove('item-clicked')
                                        }}><label>Ряд 0</label></span>
                                        <span id='span-two' className='item-notclicked' onClick={() => {
                                            setSelectedFloor('1')
                                            const element1 = document.getElementById('span-one')!
                                            const element2 = document.getElementById('span-two')!
                                            const element3 = document.getElementById('span-three')!
                                            const element4 = document.getElementById('span-four')!
                                            element2.classList.add('item-clicked')
                                            element1.classList.remove('item-clicked')
                                            element3.classList.remove('item-clicked')
                                            element4.classList.remove('item-clicked')
                                        }}><label>Ряд 1</label></span>
                                        <span id='span-three' className='item-notclicked' onClick={() => {
                                            setSelectedFloor('2')
                                            const element1 = document.getElementById('span-one')!
                                            const element2 = document.getElementById('span-two')!
                                            const element3 = document.getElementById('span-three')!
                                            const element4 = document.getElementById('span-four')!
                                            element3.classList.add('item-clicked')
                                            element2.classList.remove('item-clicked')
                                            element1.classList.remove('item-clicked')
                                            element4.classList.remove('item-clicked')
                                        }}><label>Ряд 2</label></span>
                                        <span id='span-four' className='item-notclicked' onClick={() => {
                                            setSelectedFloor('3')
                                            const element1 = document.getElementById('span-one')!
                                            const element2 = document.getElementById('span-two')!
                                            const element3 = document.getElementById('span-three')!
                                            const element4 = document.getElementById('span-four')!
                                            element4.classList.add('item-clicked')
                                            element3.classList.remove('item-clicked')
                                            element2.classList.remove('item-clicked')
                                            element1.classList.remove('item-clicked')
                                        }}><label>Ряд 3</label></span>
                                    </div>
                                </div>
                                {
                                    selectedFloor &&
                                    <div className='subrow-container' style={{width: '100%', marginTop: '15px'}}>
                                        <div className='subrow'>
                                            {scheme.filter(item => item.storageLocation.storageType === 'Стеллажи' && item.storageLocation.row === Number(selectedFloor)).map((item, index) => <div className='storage-unit' onClick={() => setSelectedPlace(String(item.storageLocation.place))}>{item.storageLocation.place}</div>)}
                                        </div>
                                    </div>
                                }
                                {
                                    selectedFloor && selectedPlace &&
                                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                                        <div style={{width: '97%'}}>
                                            <h4>Ряд {selectedFloor ? selectedFloor : '...'} место {selectedPlace ? selectedPlace : '...'} содержит:</h4>
                                            {scheme.filter(item => item.storageLocation.storageType === 'Стеллажи' && item.storageLocation.row === Number(selectedFloor) && item.storageLocation.place === Number(selectedPlace))
                                                .map(item => item.storageLocation.positions.map((position, index) => <SpecialCard card={position}/>))}
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        {/*scheme.filter(item => item.storageLocation.storageType === 'Стеллажи' && item.storageLocation.row === Number(selectedFloor))*/}
                        {/*.filter(item => selectedPlace && item.storageLocation.place === Number(selectedPlace))*/}
                        {/*.map((item, index) => <div></div>)*/}
                        {
                            chosenType === 'Стеллажи' && chosenFloor === '2' &&
                            <div className='modalInputScheme'>
                                <div className='triple-switch-container'>
                                    <div className='triple-switch'>
                                        <span id='span-one' className='item-notclicked' onClick={() => {
                                            setSelectedFloor('4')
                                            const element1 = document.getElementById('span-one')!
                                            const element2 = document.getElementById('span-two')!
                                            const element3 = document.getElementById('span-three')!
                                            const element4 = document.getElementById('span-four')!
                                            element1.classList.add('item-clicked')
                                            element2.classList.remove('item-clicked')
                                            element3.classList.remove('item-clicked')
                                            element4.classList.remove('item-clicked')
                                        }}><label>Ряд 4</label></span>
                                        <span id='span-two' className='item-notclicked' onClick={() => {
                                            setSelectedFloor('5')
                                            const element1 = document.getElementById('span-one')!
                                            const element2 = document.getElementById('span-two')!
                                            const element3 = document.getElementById('span-three')!
                                            const element4 = document.getElementById('span-four')!
                                            element2.classList.add('item-clicked')
                                            element1.classList.remove('item-clicked')
                                            element3.classList.remove('item-clicked')
                                            element4.classList.remove('item-clicked')
                                        }}><label>Ряд 5</label></span>
                                        <span id='span-three' className='item-notclicked' onClick={() => {
                                            setSelectedFloor('6')
                                            const element1 = document.getElementById('span-one')!
                                            const element2 = document.getElementById('span-two')!
                                            const element3 = document.getElementById('span-three')!
                                            const element4 = document.getElementById('span-four')!
                                            element3.classList.add('item-clicked')
                                            element2.classList.remove('item-clicked')
                                            element1.classList.remove('item-clicked')
                                            element4.classList.remove('item-clicked')
                                        }}><label>Ряд 6</label></span>
                                        <span id='span-four' className='item-notclicked' onClick={() => {
                                            setSelectedFloor('7')
                                            const element1 = document.getElementById('span-one')!
                                            const element2 = document.getElementById('span-two')!
                                            const element3 = document.getElementById('span-three')!
                                            const element4 = document.getElementById('span-four')!
                                            element4.classList.add('item-clicked')
                                            element3.classList.remove('item-clicked')
                                            element2.classList.remove('item-clicked')
                                            element1.classList.remove('item-clicked')
                                        }}><label>Ряд 7</label></span>
                                    </div>
                                </div>
                                {
                                    selectedFloor &&
                                    <div className='subrow-container' style={{width: '100%', marginTop: '15px'}}>
                                        <div className='subrow'>
                                            {scheme.filter(item => item.storageLocation.storageType === 'Стеллажи' && item.storageLocation.row === Number(selectedFloor)).map((item, index) => <div className='storage-unit' onClick={() => setSelectedPlace(String(item.storageLocation.place))}>{item.storageLocation.place}</div>)}
                                        </div>
                                    </div>
                                }
                                {
                                    selectedFloor && selectedPlace &&
                                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                                        <div style={{width: '97%'}}>
                                            <h4>Ряд {selectedFloor ? selectedFloor : '...'} место {selectedPlace ? selectedPlace : '...'} содержит:</h4>
                                            <div className='card-container' style={{width: '100%'}}>
                                                {
                                                    scheme.filter(item => item.storageLocation.storageType === 'Стеллажи' && item.storageLocation.row === Number(selectedFloor) && item.storageLocation.place === Number(selectedPlace))
                                                        .map(item => item.storageLocation.positions.map((position, index) => <SpecialCard card={position}/>))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        {
                            chosenType === 'Мотки' && chosenRow === '1' &&
                            <div className='modalInputScheme'>
                                <div className='triple-switch-container'>
                                    <div className='triple-switch'>
                                        <span id='span-one' className='item-notclicked double-span' onClick={() => {
                                            setSelectedFloor('1')
                                            const element1 = document.getElementById('span-one')!
                                            const element2 = document.getElementById('span-two')!
                                            element1.classList.add('item-clicked')
                                            element2.classList.remove('item-clicked')
                                        }}><label>Ряд 1</label></span>
                                        <span id='span-two' className='item-notclicked double-span' onClick={() => {
                                            setSelectedFloor('2')
                                            const element1 = document.getElementById('span-one')!
                                            const element2 = document.getElementById('span-two')!
                                            element2.classList.add('item-clicked')
                                            element1.classList.remove('item-clicked')
                                        }}><label>Ряд 2</label></span>
                                    </div>
                                </div>
                                {
                                    selectedFloor &&
                                    <div className='subrow-container' style={{width: '100%', marginTop: '15px'}}>
                                        <div className='subrow'>
                                            {scheme.filter(item => item.storageLocation.storageType === 'Мотки' && item.storageLocation.row === Number(selectedFloor)).map((item, index) => <div className='storage-unit' onClick={() => setSelectedPlace(String(item.storageLocation.place))} key={item.storageLocation.place}>{item.storageLocation.place}</div>)}
                                        </div>
                                    </div>
                                }
                                {
                                    selectedFloor && selectedPlace &&
                                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                                        <div style={{width: '97%'}}>
                                            <h4>Ряд {selectedFloor ? selectedFloor : '...'} место {selectedPlace ? selectedPlace : '...'} содержит:</h4>
                                            {scheme.filter(item => item.storageLocation.storageType === 'Мотки' && item.storageLocation.row === Number(selectedFloor) && item.storageLocation.place === Number(selectedPlace))
                                                .map(item => item.storageLocation.positions.map((position, index) => <SpecialCard card={position}/>))}
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        {
                            chosenType === 'Мотки' && chosenRow === '2' &&
                            <div className='modalInputScheme'>
                                <div className='triple-switch-container'>
                                    <div className='triple-switch'>
                                        <span id='span-one' className='item-notclicked double-span' onClick={() => {
                                            setSelectedFloor('3')
                                            const element1 = document.getElementById('span-one')!
                                            const element2 = document.getElementById('span-two')!
                                            element1.classList.add('item-clicked')
                                            element2.classList.remove('item-clicked')
                                        }}><label>Ряд 3</label></span>
                                        <span id='span-two' className='item-notclicked double-span' onClick={() => {
                                            setSelectedFloor('4')
                                            const element1 = document.getElementById('span-one')!
                                            const element2 = document.getElementById('span-two')!
                                            element2.classList.add('item-clicked')
                                            element1.classList.remove('item-clicked')
                                        }}><label>Ряд 4</label></span>
                                    </div>
                                </div>
                                {
                                    selectedFloor &&
                                    <div className='subrow-container' style={{width: '100%', marginTop: '15px'}}>
                                        <div className='subrow'>
                                            {scheme.filter(item => item.storageLocation.storageType === 'Мотки' && item.storageLocation.row === Number(selectedFloor)).map((item, index) => <div className='storage-unit' onClick={() => setSelectedPlace(String(item.storageLocation.place))} key={item.storageLocation.place}>{item.storageLocation.place}</div>)}
                                        </div>
                                    </div>
                                }
                                {
                                    selectedFloor && selectedPlace &&
                                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                                        <div style={{width: '97%'}}>
                                            <h4>Ряд {selectedFloor ? selectedFloor : '...'} место {selectedPlace ? selectedPlace : '...'} содержит:</h4>
                                            {scheme.filter(item => item.storageLocation.storageType === 'Мотки' && item.storageLocation.row === Number(selectedFloor) && item.storageLocation.place === Number(selectedPlace))
                                                .map(item => item.storageLocation.positions.map((position, index) => <SpecialCard card={position}/>))}
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        {
                            chosenType === 'Мотки' && chosenRow === '3' &&
                            <div className='modalInputScheme'>
                                <div className='triple-switch-container'>
                                    <div className='triple-switch'>
                                        <span id='span-one' className='item-notclicked double-span' onClick={() => {
                                            setSelectedFloor('5')
                                            const element1 = document.getElementById('span-one')!
                                            const element2 = document.getElementById('span-two')!
                                            element1.classList.add('item-clicked')
                                            element2.classList.remove('item-clicked')
                                        }}><label>Ряд 5</label></span>
                                        <span id='span-two' className='item-notclicked double-span' onClick={() => {
                                            setSelectedFloor('6')
                                            const element1 = document.getElementById('span-one')!
                                            const element2 = document.getElementById('span-two')!
                                            element2.classList.add('item-clicked')
                                            element1.classList.remove('item-clicked')
                                        }}><label>Ряд 6</label></span>
                                    </div>
                                </div>
                                {
                                    selectedFloor &&
                                    <div className='subrow-container' style={{width: '100%', marginTop: '15px'}}>
                                        <div className='subrow'>
                                            {scheme.filter(item => item.storageLocation.storageType === 'Мотки' && item.storageLocation.row === Number(selectedFloor)).map((item, index) => <div className='storage-unit' onClick={() => setSelectedPlace(String(item.storageLocation.place))} key={item.storageLocation.place}>{item.storageLocation.place}</div>)}
                                        </div>
                                    </div>
                                }
                                {
                                    selectedFloor && selectedPlace &&
                                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                                        <div style={{width: '97%'}}>
                                            <h4>Ряд {selectedFloor ? selectedFloor : '...'} место {selectedPlace ? selectedPlace : '...'} содержит:</h4>
                                            {scheme.filter(item => item.storageLocation.storageType === 'Мотки' && item.storageLocation.row === Number(selectedFloor) && item.storageLocation.place === Number(selectedPlace))
                                                .map(item => item.storageLocation.positions.map((position, index) => <SpecialCard card={position}/>))}
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        {
                            chosenType === 'Стойки' && chosenRow === '4' &&
                            <div className='modalInputScheme'>
                                <div className='triple-switch-container'>
                                    <div className='triple-switch'>
                                        <span id='span-one' className='item-notclicked double-span' onClick={() => {
                                            setSelectedFloor('1')
                                            const element1 = document.getElementById('span-one')!
                                            const element2 = document.getElementById('span-two')!
                                            element1.classList.add('item-clicked')
                                            element2.classList.remove('item-clicked')
                                        }}><label>Ряд 1</label></span>
                                        <span id='span-two' className='item-notclicked double-span' onClick={() => {
                                            setSelectedFloor('2')
                                            const element1 = document.getElementById('span-one')!
                                            const element2 = document.getElementById('span-two')!
                                            element2.classList.add('item-clicked')
                                            element1.classList.remove('item-clicked')
                                        }}><label>Ряд 2</label></span>
                                    </div>
                                </div>
                                {
                                    selectedFloor &&
                                    <div className='subrow-container' style={{width: '100%', marginTop: '15px'}}>
                                        <div className='subrow'>
                                            {scheme.filter(item => item.storageLocation.storageType === 'Стойки' && item.storageLocation.row === Number(selectedFloor)).map((item, index) => <div className='storage-unit' onClick={() => setSelectedPlace(String(item.storageLocation.place))} key={item.storageLocation.place}>{item.storageLocation.place}</div>)}
                                        </div>
                                    </div>
                                }
                                {
                                    selectedFloor && selectedPlace &&
                                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                                        <div style={{width: '97%'}}>
                                            <h4>Ряд {selectedFloor ? selectedFloor : '...'} место {selectedPlace ? selectedPlace : '...'} содержит:</h4>
                                            {scheme.filter(item => item.storageLocation.storageType === 'Стойки' && item.storageLocation.row === Number(selectedFloor) && item.storageLocation.place === Number(selectedPlace))
                                                .map(item => item.storageLocation.positions.map((position, index) => <SpecialCard card={position}/>))}
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
                {/*<div className='footer'>*/}
                {/*    <button type='button' id='cancelBtn' onClick={() => openModal(false)}>Отменить</button>*/}
                {/*</div>*/}
            </div>
        </form>,
        portalElement
    )
}