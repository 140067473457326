import axios from "axios";
import {BACKEND_URL} from "../ConstConfig";
import {useLoaderData, useParams} from "react-router-dom";
import {CardView} from "./CardView";
import '../css/MeltSearchWeights.css'
import {useEffect, useState} from "react";

//@ts-ignore
export async function weightLoader({params}) {
    const mark = params.mark.replace('+', '/')
    const part = params.part.replace('+', '/')
    const melt = params.plav.replace('+', '/')
    const response = await axios.post(BACKEND_URL + '/api/v1/search/weight', {
        mark: mark,
        diameter: Number(params.diameter),
        packing: params.packing,
        part: part,
        plav: melt
    }, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    })
    const weight = response.data
    return {weight}
}

export function MeltSearchWeights() {
    //@ts-ignore
    const {weight} = useLoaderData()

    const {selectedSklad} = useParams()

    const [state, setState] = useState<string>(selectedSklad ? selectedSklad : 'sol')

    useEffect(() => {
        if (selectedSklad === 'bel') {
            const element = document.getElementById('span-one')!
            element.classList.add('item-clicked')
        } else if (selectedSklad === 'sol') {
            const element = document.getElementById('span-two')!
            element.classList.add('item-clicked')
        } else if (selectedSklad === 'manu') {
            const element = document.getElementById('span-three')!
            element.classList.add('item-clicked')
        } else if (selectedSklad === 'matr') {
            const element = document.getElementById('span-four')!
            element.classList.add('item-clicked')
        } else {
            return
        }
    }, [])

    return (
        <>
            {
                localStorage.getItem('actions')?.includes('SEARCH_BY_LOCATION') &&
                <div style={{marginTop: '90px'}}>
                    <div className='triple-switch-container'>
                        <div className='triple-switch'>
                            <span id='span-one' className='item-notclicked' onClick={() => {
                                setState('bel')
                                const element1 = document.getElementById('span-one')!
                                const element2 = document.getElementById('span-two')!
                                const element3 = document.getElementById('span-three')!
                                const element4 = document.getElementById('span-four')!
                                element1.classList.add('item-clicked')
                                element2.classList.remove('item-clicked')
                                element3.classList.remove('item-clicked')
                                element4.classList.remove('item-clicked')
                            }}><label>Белорецк</label></span>
                            <span id='span-two' className='item-notclicked' onClick={() => {
                                setState('sol')
                                const element1 = document.getElementById('span-one')!
                                const element2 = document.getElementById('span-two')!
                                const element3 = document.getElementById('span-three')!
                                const element4 = document.getElementById('span-four')!
                                element2.classList.add('item-clicked')
                                element1.classList.remove('item-clicked')
                                element3.classList.remove('item-clicked')
                                element4.classList.remove('item-clicked')
                            }}><label>Солнечногорск</label></span>
                            <span id='span-three' className='item-notclicked' onClick={() => {
                                setState('manu')
                                const element1 = document.getElementById('span-one')!
                                const element2 = document.getElementById('span-two')!
                                const element3 = document.getElementById('span-three')!
                                const element4 = document.getElementById('span-four')!
                                element3.classList.add('item-clicked')
                                element2.classList.remove('item-clicked')
                                element1.classList.remove('item-clicked')
                                element4.classList.remove('item-clicked')
                            }}><label>Производство</label></span>
                            <span id='span-four' className='item-notclicked' onClick={() => {
                                setState('matr')
                                const element1 = document.getElementById('span-one')!
                                const element2 = document.getElementById('span-two')!
                                const element3 = document.getElementById('span-three')!
                                const element4 = document.getElementById('span-four')!
                                element4.classList.add('item-clicked')
                                element3.classList.remove('item-clicked')
                                element2.classList.remove('item-clicked')
                                element1.classList.remove('item-clicked')
                            }}><label>Матросово</label></span>
                        </div>
                    </div>
                    <div className='search-cards'>
                        {state === 'bel' &&
                            <div>
                                <h2>Белорецк</h2>
                                {weight.belSklad.length !== 0 ? <div style={{marginLeft: '1%'}}><CardView cards={weight.belSklad}/></div>
                                    : <h4 style={{color: 'rgb(253, 185, 0)'}}>Нет позиций в данной категории</h4>}
                            </div>
                        }
                        {state === 'sol' &&
                            <div>
                                <h2>Солнечногорск</h2>
                                {weight.solnechnogorsk.length !== 0 ? <div style={{marginLeft: '1%'}}><CardView cards={weight.solnechnogorsk}/></div>
                                    : <h4 style={{color: 'rgb(253, 185, 0)'}}>Нет позиций в данной категории</h4>}
                            </div>
                        }
                        {state === 'manu' &&
                            <div>
                                <h2>Производство</h2>
                                {weight.manufacture.length !== 0 ? <div style={{marginLeft: '1%'}}><CardView cards={weight.manufacture}/></div>
                                    : <h4 style={{color: 'rgb(253, 185, 0)'}}>Нет позиций в данной категории</h4>}
                            </div>
                        }
                        {state === 'matr' &&
                            <div>
                                <h2>Матросово</h2>
                                {weight.matrosovo.length !== 0 ? <div style={{marginLeft: '1%'}}><CardView cards={weight.matrosovo}/></div>
                                    : <h4 style={{color: 'rgb(253, 185, 0)'}}>Нет позиций в данной категории</h4>}
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    )
}