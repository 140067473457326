import '../css/Login.css'
import React, {useState} from "react";
import axios, {AxiosError} from "axios";
import {ErrorMessage} from "../components/ErrorMessage";
import {useNavigate} from 'react-router-dom';
import icon from "../assets/logo_new.png"
import {BACKEND_URL} from "../ConstConfig";
import useToken from "../hooks/useToken";
import {Loader} from "../components/Loader";
import {jwtDecode, JwtPayload} from "jwt-decode";

interface ILoginInfo extends JwtPayload {
    firstName: string,
    secondName: string,
    actions: string[],
    roles: string[],
    locations: string[],
    mainLocation: string
}

export function LoginPage() {
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState<boolean>(false)
    const {setToken} = useToken()
    const navigate = useNavigate();

    const submitHandler = async (event: { preventDefault: () => void; }) => {
        event.preventDefault()
        setError('')
        setLoading(true)
        try {
            const responseLogin = await axios.post(BACKEND_URL + '/api/v1/auth/login', {
                login,
                password
            })
            setToken(responseLogin.data.accessToken)
            localStorage.setItem('expireTime', responseLogin.data.expireTime)
            const decodedJwt: ILoginInfo = jwtDecode(responseLogin.data.accessToken)
            console.log(decodedJwt)
            localStorage.setItem('worker', decodedJwt.secondName + ' ' + decodedJwt.firstName)
            localStorage.setItem('roles', JSON.stringify(decodedJwt.roles))
            localStorage.setItem('location', JSON.stringify(decodedJwt.locations))
            localStorage.setItem('actions', JSON.stringify(decodedJwt.actions))
            setLoading(false)
            navigate("/")
        } catch (e: unknown) {
            setLoading(false)
            const error = e as AxiosError
            setError(error.message)
        }
    }

    return (
        <div className='login'>
            {error && <ErrorMessage error={error}/>}
            <form onSubmit={submitHandler} className='login-panel'>
                <img src={icon} alt={'Logo'}/>
                <h1>Авторизация</h1>
                <span className='field'>
                    <p>Логин:</p>
                    <input type="text" value={login} onChange={event => setLogin(event.target.value)}/>
                </span>
                <span className='field'>
                    <p>Пароль:</p>
                    <input type="password" value={password} onChange={event => setPassword(event.target.value)}/>
                </span>
                {loading &&
                    <div style={{marginTop: '20px'}}>
                        <Loader/>
                    </div>
                }
                {!loading &&
                    <button type='submit' className='btn-login'>Войти</button>
                }
            </form>
        </div>
    )
}