import React, {useRef, useState} from "react";
import '../css/CustomFileInput.css'
import axios from "axios";
import {BACKEND_URL} from "../ConstConfig";
import {IFile} from "../interfaces/exportedInterfaces";
import {Bounce, toast} from "react-toastify";

interface CustomFileInputProps {
    multiple?: boolean,
    url: string,
    id: number,
    files: IFile[],
    requiredType: string,
    header: string,
    path: string
}

export function CustomFileInput({multiple, url, id, files, requiredType, header, path}: CustomFileInputProps) {

    const inputRef = useRef<HTMLInputElement | null>(null)
    const pasteRef = useRef<HTMLDivElement | null>(null)

    const [selectedFile, setSelectedFile] = useState<IFile[] | null>(null)
    const [error, setError] = useState<string>('')
    const [ulFocus, setUlFocus] = useState<boolean>(false)

    const [border, setBorder] = useState({
        border: '',
    })

    const onChooseFile = () => {
        if (inputRef.current) {
            inputRef.current.click()
        }
    }

    async function uploadFiles(files: File[]) {
        setError('')
        const formData = new FormData()
        files.forEach((file, index) => formData.append('files', file))
        try {
            const response = await toast.promise(axios.post(BACKEND_URL + '/api/v1/' + path + '/' + id + '/' + url, formData
                // file: requiredType === 'PLANNED_DISPATCH_OTHER' ? files : files[0]
            , {
                params: {
                    fileType: requiredType
                },
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'multipart/form-data'
                },
            }), {
                pending: 'Загружается файл..',
                success: 'Файл успешно загружен',
                error: 'Не удалось загрузить файл'
            })
            if (response.status === 200) {
                setSelectedFile(response.data.files)
                if (url === 'upload-bill') {
                    window.location.reload()
                }
            }
        } catch (e: unknown) {
            setError('Не удалось загрузить файл(-ы)')
        }
    }

    async function deleteFile(fileUrl: string) {
        try {
            const response = await axios.delete(BACKEND_URL + '/api/v1/planned-dispatch/' + id + '/files/' + fileUrl, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            if (response.status === 200) {
                window.location.reload()
            }
        } catch (e) {
            toast.error('Не удалось удалить файл', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
        }
    }

    // const focusInput = async () => {
    //     if (pasteRef.current) {
    //         pasteRef.current.focus()
    //         try {
    //             const clipboardItems = await navigator.clipboard.read();
    //             const dataTransfer = new DataTransfer();
    //
    //             clipboardItems.forEach(item => {
    //                 if (item.types.includes('text/plain')) {
    //                     item.getType('text/plain').then(blob => {
                            // const file = new File([blob], 'hhh.pdf', { type: 'application/pdf' });
                            // dataTransfer.items.add(file);
                        // });
                    // }
                // });
                //
                // setTimeout(() => {
                //     const newPasteEvent = new ClipboardEvent('paste', {
                //         clipboardData: dataTransfer,
                //         bubbles: true,
                //         cancelable: true,
                //     });
                //     pasteRef.current?.dispatchEvent(newPasteEvent);
                // }, 100);
            // } catch (e) {
            //     toast.error('Ошибка при чтении данных из буфера обмена');
            // }
        // }
    // }

    const focusInput = () => {
        if (pasteRef.current) {
            pasteRef.current.focus()
        }
    }

    const handlePaste = (event: { preventDefault: () => void; clipboardData: { items: any; }; }) => {
        const items = event.clipboardData.items;
        console.log(items)

        const newFiles: File[] = [];

        if (items) {
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                if (item.kind === 'file') {
                    const file = item.getAsFile();
                    if (file) {
                        newFiles.push(file);
                    }
                }
            }
        }

        if (newFiles.length > 0) {
            uploadFiles(Array.from(newFiles))
        } else {
            toast.error('Буфер обмена пуст либо вставлен неподходящий тип файлов', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
        }
    }

    return (
        <div>
            <h3 style={{marginTop: '5px', marginBottom: '10px'}} onClick={focusInput}>{header}</h3>
            {
                ((!multiple && !selectedFile && (files?.filter(file => file.type === requiredType).length === 0 || files === null)) || multiple) &&
                <div>
                    <input accept={!multiple ? '.pdf' : ''} id='dispatchFile' name='file[]' type="file" ref={inputRef}
                           style={{display: 'none'}} multiple={multiple} onChange={(event) => {
                        if (event.target.files) {
                            uploadFiles(Array.from(event.target.files))
                        }
                    }}/>
                    <div className='file-btn'
                         tabIndex={0}
                         ref={pasteRef}
                         onPaste={handlePaste}
                         style={border}
                         onBlur={() => setBorder({
                             border: '1.5px dashed #beaa87',
                         })}
                         onFocus={() => setBorder({
                             border: '1.5px solid #beaa87',
                         })}>
                        <span onClick={onChooseFile} className='material-symbols-rounded'>upload</span> Загрузить файл
                    </div>
                </div>
            }

            {
                error && <p style={{color: 'crimson'}}>Ошибка загрузки</p>
            }

            {
                selectedFile && selectedFile.filter((file) => file.type === requiredType).map((file, index) =>
                    <div className='selected-file' key={index} onClick={() => {
                        if (!ulFocus) {
                            window.open(file.url, '_blank')
                        }
                    }}>
                        <p style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden'
                        }}>{file.fileName.length > 25 ? file.fileName.substring(0, 24) + '..' : file.fileName}</p>
                        <button className='delete' onMouseOver={() => setUlFocus(true)} onMouseOut={() => setUlFocus(false)}
                                onClick={() => deleteFile(file.id)}>
                            <span className='material-symbols-rounded'>delete</span>
                        </button>
                    </div>
                )
            }

            {
                files && !selectedFile && files.filter((file) => file.type === requiredType)
                    .map((file, index) => <div className='selected-file' key={index} onClick={() => {
                            if (!ulFocus) {
                                window.open(file.url, '_blank')
                            }
                        }}>
                            <p style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden'
                            }}>{file.fileName.length > 25 ? file.fileName.substring(0, 24) + '..' : file.fileName}</p>
                            <button className='delete' onMouseOver={() => setUlFocus(true)} onMouseOut={() => setUlFocus(false)}
                                    onClick={() => deleteFile(file.id)}>
                                <span className='material-symbols-rounded'>delete</span>
                            </button>
                        </div>
                    )
            }
        </div>
    )
}



