import {useEffect, useState} from "react";
import {IPositionHistory} from "../interfaces/exportedInterfaces";
import axios from "axios";
import {BACKEND_URL} from "../ConstConfig";

export function usePositionHistory(id: number) {

    const [error, setError] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [history, setHistory] = useState<IPositionHistory[]>([])

    async function fetchHistory() {
        setError('')
        setLoading(true)
        try {
            const response = await axios.post(BACKEND_URL + '/api/v1/position/' + id + '/history', {}, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            setHistory(response.data)
            setLoading(false)
        } catch (e) {
            setLoading(false)
            setError('Не удалось получить историю позиции')
        }
    }

    useEffect(() => {
        fetchHistory()
    }, [])

    return {loading, error, history}
}