import {NavLink} from "react-router-dom";
import "../css/Navigation.css"
import {useState} from "react";

export function Navigation() {

    const navbarItems = [
        {
            name: 'ПОЗИЦИИ',
            subnames: [
                {
                    link: 'Регистрация',
                    path: '/register',
                    cypher: 'REGISTRATION'
                },
                {
                    link: 'Наличие',
                    path: '/show',
                    cypher: 'STOCK_INFO'
                },
                {
                    link: 'Резервы',
                    path: '/reserve',
                    cypher: 'RESERVES_INFO'
                },
                {
                    link: 'Цены',
                    path: '/prices',
                    cypher: 'PRICE_CALCULATION'
                }
            ]
        },
        {
            name: 'ХРАНЕНИЕ',
            subnames: [
                {
                    link: 'Схема',
                    path: '/scheme',
                    cypher: 'STORAGE_LOCATION_VIEW'
                },
                {
                    link: 'Инвентаризация',
                    path: '/inventory',
                    cypher: 'REBASE'
                },
                {
                    link: 'Перенос веса',
                    path: '/weight-transfer',
                    cypher: 'WEIGHT_TRANSFER'
                }
            ]
        },
        {
            name: 'ПОИСК',
            subnames: [
                {
                    link: 'Сканирование',
                    path: '/search',
                    cypher: 'SEARCH_BY_ID'
                },
                {
                    link: 'Поиск',
                    path: '/melt-search',
                    cypher: 'SEARCH'
                },
            ]
        },
        {
            name: 'ОТГРУЗКИ',
            subnames: [
                {
                    link: 'Отгрузка',
                    path: '/dispatch',
                    cypher: 'DISPATCH'
                },
                {
                    link: 'История отгрузок',
                    path: '/dispatch-history',
                    cypher: 'DISPATCH_HISTORY'
                },
                {
                    link: 'Планер',
                    path: '/dispatch-calendar',
                    cypher: 'DISPATCH_CALENDAR'
                }
            ]
        },
        {
            name: 'ПЕРЕВОЗКА',
            subnames: [
                {
                    link: 'Отправить',
                    path: '/send',
                    cypher: 'TRANSFER_SEND'
                },
                {
                    link: 'Приёмка',
                    path: '/admission',
                    cypher: 'TRANSFER_RECEIVE'
                },
                {
                    link: 'История перевозок',
                    path: '/send-history',
                    cypher: 'TRANSFER_INFO'
                }
            ]
        },
        {
            name: 'СЕРТИФИКАТЫ',
            subnames: [
                {
                    link: 'Плавка',
                    path: '/plav',
                    cypher: 'MELT_INFO'
                },
                {
                    link: 'Партия',
                    path: '/part',
                    cypher: 'BATCH_INFO'
                },
                {
                    link: 'Сертификаты',
                    path: '/certificates',
                    cypher: 'CERTIFICATE_SCAN'
                }
            ]
        },
        {
            name: 'СПРАВОЧНИК',
            subnames: [
                {
                    link: 'Упаковки',
                    path: '/packings',
                    cypher: 'DIRECTORIES_PACKINGS'
                },
                {
                    link: 'Марки',
                    path: '/marks',
                    cypher: 'VIEW_GRADES'
                },
                {
                    link: 'Стандарты',
                    path: '/standards',
                    cypher: 'VIEW_STANDARDS'
                },
                {
                    link: 'Экшены',
                    path: '/actions',
                    cypher: 'DIRECTORIES_ACTIONS'
                },
                {
                    link: 'Настройки',
                    path: '/settings',
                    cypher: 'VIEW_APP_SETTINGS'
                },
            ]
        }
    ]

    const [activeState, setActiveState] = useState<number | null>(null)

    // if (ind === 0) {
    //     return (localStorage.getItem('roles')?.includes('WAREHOUSE_USER') || localStorage.getItem('roles')?.includes('ADMIN')) ? '160px' : '120px'
    // } else if (ind === 1) {
    //     return '80px'
    // } else if (ind === 2) {
    //     return '80px'
    // } else if (ind === 3) {
    //     return (localStorage.getItem('roles')?.includes('WAREHOUSE_USER') || localStorage.getItem('roles')?.includes('ADMIN')) ? '80px' : '40px'
    // } else if (ind === 4) {
    //     return '120px'
    // } else if (ind === 5) {
    //     return '120px'
    // } else {
    //     return 0
    // }

    function findHeight(ind: number, subnames: {link: string, path: string, cypher: string}[]) {
        if (activeState === ind) {
            let counter: number = 0
            subnames.forEach(subname => {
                if (localStorage.getItem('actions')?.includes(subname.cypher)) {
                    counter = counter + 1
                }
            })
            return `${40 * counter}px`
        } else {
            return '0px'
        }

        // if (activeState === ind) {
        //     return `${40 * len}px`
        // } else {
        //     return '0'
        // }
    }

    function setVisibility(subnames: {link: string, path: string, cypher: string}[]) {
        let counter: number = 0
        subnames.forEach(subname => {
            if (localStorage.getItem('actions')?.includes(subname.cypher)) {
                counter = counter + 1
            }
        })
        return `${40 * counter}px`
    }

    return (
        <nav>
                <span className='block'>
                    {
                        navbarItems.map((item, index) => <>
                            <span className='span-hoverable-sidebar'
                            onClick={() => {
                                setActiveState(index)
                            }} style={{display: setVisibility(item.subnames) === '0px' ? 'none' : 'inline-block'}}>
                                <span>
                                    <label htmlFor="">{item.name}</label>
                                    <i id={'arrow' + index} className={activeState === index ? 'arrow down adown' : 'arrow down'}></i>
                                </span>
                            </span>
                            <div id={'submenu' + index} style={{maxHeight: findHeight(index, item.subnames)}} className='sidebar-submenu'>
                                    {
                                        item.subnames.map((subname, key) =>
                                            localStorage.getItem('actions')?.includes(subname.cypher) ?
                                                <li><NavLink className='sidebar-span' style={{textDecoration: 'none', color: 'black'}} to={subname.path}>{subname.link}</NavLink></li>
                                                :
                                                <></>)
                                    }
                            </div>
                        </>)
                    }

                    {/*{*/}
                    {/*    (localStorage.getItem('roles')?.includes('SELLER') ||*/}
                    {/*        localStorage.getItem('roles')?.includes('ADMIN') ||*/}
                    {/*        localStorage.getItem('roles')?.includes('WAREHOUSE_USER') ||*/}
                    {/*        localStorage.getItem('roles')?.includes('PRICE_CALC') ||*/}
                    {/*        localStorage.getItem('roles')?.includes('PRICE_CALC_ADM')) &&*/}
                    {/*    <span className='span-hoverable-sidebar' onClick={() => {*/}
                    {/*        document.getElementById('submenu1')!.style.maxHeight =*/}
                    {/*            (localStorage.getItem('roles')?.includes('WAREHOUSE_USER') || localStorage.getItem('roles')?.includes('ADMIN')) ? '160px' : '120px'*/}
                    {/*        document.getElementById('submenu2')!.style.maxHeight = '0'*/}
                    {/*        document.getElementById('submenu3')!.style.maxHeight = '0'*/}
                    {/*        document.getElementById('submenu4')!.style.maxHeight = '0'*/}
                    {/*        document.getElementById('submenu5')!.style.maxHeight = '0'*/}
                    {/*        document.getElementById('arrow1')!.classList.add('adown')*/}
                    {/*        document.getElementById('arrow2')!.classList.remove('adown')*/}
                    {/*        document.getElementById('arrow3')!.classList.remove('adown')*/}
                    {/*        document.getElementById('arrow4')!.classList.remove('adown')*/}
                    {/*        document.getElementById('arrow5')!.classList.remove('adown')*/}
                    {/*    }}>*/}
                    {/*               <span><label htmlFor="">ПОЗИЦИИ</label><i id='arrow1'*/}
                    {/*                                                         className="arrow down"></i></span>*/}
                    {/*    </span>*/}
                    {/*}*/}
                    {/*<div id='submenu1' className='sidebar-submenu'>*/}
                    {/*        {*/}
                    {/*            (localStorage.getItem('roles')?.includes('WAREHOUSE_USER') || localStorage.getItem('roles')?.includes('ADMIN')) &&*/}
                    {/*            <li><NavLink className='sidebar-span' to='/register'>Регистрация</NavLink></li>*/}
                    {/*        }*/}

                    {/*    {*/}
                    {/*        (localStorage.getItem('roles')?.includes('SELLER') ||*/}
                    {/*            localStorage.getItem('roles')?.includes('WAREHOUSE_USER') ||*/}
                    {/*            localStorage.getItem('roles')?.includes('ADMIN')) &&*/}
                    {/*        <li><NavLink className='sidebar-span' to='/show'>Наличие</NavLink></li>*/}
                    {/*    }*/}
                    {/*    {*/}
                    {/*        (localStorage.getItem('roles')?.includes('SELLER') ||*/}
                    {/*            localStorage.getItem('roles')?.includes('WAREHOUSE_USER') ||*/}
                    {/*            localStorage.getItem('roles')?.includes('ADMIN')) &&*/}
                    {/*        <li><NavLink className='sidebar-span' to='/reserve'>Резервы</NavLink></li>*/}
                    {/*    }*/}

                    {/*    {*/}
                    {/*        (localStorage.getItem('roles')?.includes('PRICE_CALC') ||*/}
                    {/*            localStorage.getItem('roles')?.includes('PRICE_CALC_ADM') ||*/}
                    {/*            localStorage.getItem('roles')?.includes('ADMIN')) &&*/}
                    {/*        <li><NavLink className='sidebar-span' to='/prices'>Цены</NavLink></li>*/}
                    {/*    }*/}

                    {/*</div>*/}
                    {/*{*/}
                    {/*    (localStorage.getItem('roles')?.includes('SELLER') ||*/}
                    {/*        localStorage.getItem('roles')?.includes('ADMIN') ||*/}
                    {/*        localStorage.getItem('roles')?.includes('WAREHOUSE_USER')) &&*/}
                    {/*    <span className='span-hoverable-sidebar' onClick={() => {*/}
                    {/*        document.getElementById('submenu2')!.style.maxHeight = '80px'*/}
                    {/*        document.getElementById('submenu1')!.style.maxHeight = '0'*/}
                    {/*        document.getElementById('submenu3')!.style.maxHeight = '0'*/}
                    {/*        document.getElementById('submenu4')!.style.maxHeight = '0'*/}
                    {/*        document.getElementById('submenu5')!.style.maxHeight = '0'*/}
                    {/*        document.getElementById('arrow1')!.classList.remove('adown')*/}
                    {/*        document.getElementById('arrow2')!.classList.add('adown')*/}
                    {/*        document.getElementById('arrow3')!.classList.remove('adown')*/}
                    {/*        document.getElementById('arrow4')!.classList.remove('adown')*/}
                    {/*        document.getElementById('arrow5')!.classList.remove('adown')*/}
                    {/*    }}>*/}
                    {/*        <span><label>ПОИСК</label><i id='arrow2' className="arrow down"></i></span>*/}
                    {/*    </span>*/}
                    {/*}*/}
                    {/*    <div id='submenu2' className='sidebar-submenu'>*/}
                    {/*    <li><NavLink className='sidebar-span' to='/search'>Сканирование</NavLink></li>*/}
                    {/*    <li><NavLink className='sidebar-span' to='/melt-search'>Поиск</NavLink></li>*/}
                    {/*</div>*/}
                    {/*{*/}
                    {/*    (localStorage.getItem('roles')?.includes('SELLER') ||*/}
                    {/*        localStorage.getItem('roles')?.includes('ADMIN') ||*/}
                    {/*        localStorage.getItem('roles')?.includes('WAREHOUSE_USER')) &&*/}
                    {/*    <span className='span-hoverable-sidebar' onClick={() => {*/}
                    {/*        document.getElementById('submenu3')!.style.maxHeight =*/}
                    {/*            (localStorage.getItem('roles')?.includes('WAREHOUSE_USER') || localStorage.getItem('roles')?.includes('ADMIN')) ? '80px' : '40px'*/}
                    {/*        document.getElementById('submenu1')!.style.maxHeight = '0'*/}
                    {/*        document.getElementById('submenu2')!.style.maxHeight = '0'*/}
                    {/*        document.getElementById('submenu4')!.style.maxHeight = '0'*/}
                    {/*        document.getElementById('submenu5')!.style.maxHeight = '0'*/}
                    {/*        document.getElementById('arrow1')!.classList.remove('adown')*/}
                    {/*        document.getElementById('arrow2')!.classList.remove('adown')*/}
                    {/*        document.getElementById('arrow3')!.classList.add('adown')*/}
                    {/*        document.getElementById('arrow4')!.classList.remove('adown')*/}
                    {/*        document.getElementById('arrow5')!.classList.remove('adown')*/}
                    {/*    }}>*/}
                    {/*        <span><label htmlFor="">ОТГРУЗКИ</label><i id='arrow3' className="arrow down"></i></span>*/}
                    {/*    </span>*/}
                    {/*}*/}
                    {/*<div id='submenu3' className='sidebar-submenu'>*/}
                    {/*        {*/}
                    {/*            (localStorage.getItem('roles')?.includes('WAREHOUSE_USER') || localStorage.getItem('roles')?.includes('ADMIN')) &&*/}
                    {/*            <li><NavLink className='sidebar-span' to='/dispatch'>Отгрузка</NavLink></li>*/}
                    {/*        }*/}
                    {/*    <li><NavLink className='sidebar-span' to='/dispatch-history'>История отгрузок</NavLink></li>*/}
                    {/*</div>*/}
                    {/*{*/}
                    {/*    (localStorage.getItem('roles')?.includes('SELLER') ||*/}
                    {/*        localStorage.getItem('roles')?.includes('ADMIN') ||*/}
                    {/*        localStorage.getItem('roles')?.includes('WAREHOUSE_USER')) &&*/}
                    {/*    <span className='span-hoverable-sidebar' onClick={() => {*/}
                    {/*        document.getElementById('submenu4')!.style.maxHeight = '120px'*/}
                    {/*        document.getElementById('submenu1')!.style.maxHeight = '0'*/}
                    {/*        document.getElementById('submenu2')!.style.maxHeight = '0'*/}
                    {/*        document.getElementById('submenu3')!.style.maxHeight = '0'*/}
                    {/*        document.getElementById('submenu5')!.style.maxHeight = '0'*/}
                    {/*        document.getElementById('arrow1')!.classList.remove('adown')*/}
                    {/*        document.getElementById('arrow2')!.classList.remove('adown')*/}
                    {/*        document.getElementById('arrow5')!.classList.remove('adown')*/}
                    {/*        document.getElementById('arrow3')!.classList.remove('adown')*/}
                    {/*        document.getElementById('arrow4')!.classList.add('adown')*/}
                    {/*    }}>*/}
                    {/*        <span><label>ПЕРЕВОЗКА</label><i id='arrow4' className="arrow down"></i></span>*/}
                    {/*    </span>*/}
                    {/*}*/}
                    {/*<div id='submenu4' className='sidebar-submenu'>*/}
                    {/*    <li><NavLink className='sidebar-span' to='/send'>Отправить</NavLink></li>*/}
                    {/*    <li><NavLink className='sidebar-span' to='/admission'>Приёмка</NavLink></li>*/}
                    {/*    <li><NavLink className='sidebar-span' to='/send-history'>История перевозок</NavLink></li>*/}
                    {/*</div>*/}
                    {/*{*/}
                    {/*    (localStorage.getItem('roles')?.includes('SELLER') ||*/}
                    {/*        localStorage.getItem('roles')?.includes('ADMIN') ||*/}
                    {/*        localStorage.getItem('roles')?.includes('WAREHOUSE_USER')) &&*/}
                    {/*    <span className='span-hoverable-sidebar' onClick={() => {*/}
                    {/*        document.getElementById('submenu5')!.style.maxHeight = '120px'*/}
                    {/*        document.getElementById('submenu1')!.style.maxHeight = '0'*/}
                    {/*        document.getElementById('submenu2')!.style.maxHeight = '0'*/}
                    {/*        document.getElementById('submenu3')!.style.maxHeight = '0'*/}
                    {/*        document.getElementById('submenu4')!.style.maxHeight = '0'*/}
                    {/*        document.getElementById('arrow1')!.classList.remove('adown')*/}
                    {/*        document.getElementById('arrow2')!.classList.remove('adown')*/}
                    {/*        document.getElementById('arrow3')!.classList.remove('adown')*/}
                    {/*        document.getElementById('arrow4')!.classList.remove('adown')*/}
                    {/*        document.getElementById('arrow5')!.classList.add('adown')*/}
                    {/*    }}>*/}
                    {/*        <span><label>СЕРТИФИКАТЫ</label><i id='arrow5' className="arrow down"></i></span>*/}
                    {/*    </span>*/}
                    {/*}*/}
                    {/*<div id='submenu5' className='sidebar-submenu'>*/}
                    {/*    <li><NavLink className='sidebar-span' to='/plav'>Плавка</NavLink></li>*/}
                    {/*    <li><NavLink className='sidebar-span' to='/part'>Партия</NavLink></li>*/}
                    {/*    <li><NavLink className='sidebar-span' to='/certificates'>Сертификаты</NavLink></li>*/}
                    {/*</div>*/}
                </span>
        </nav>
    )
}