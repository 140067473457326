import {useEffect, useState} from "react";
import {IPlav, ITickets} from "../interfaces/exportedInterfaces";
import axios, {AxiosError} from "axios";
import {BACKEND_URL} from "../ConstConfig";

export interface useTicketProps {
    pageNumber: number
}

export function useTickets({pageNumber}: useTicketProps) {

    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>('')
    const [tickets, setTickets] = useState<ITickets[]>([])
    const [totalPages, setTotalPages] = useState<number>(0)

    async function fetchTickets() {
        try {
            setError('')
            setLoading(true)
            const response = await axios.get(BACKEND_URL + '/api/v1/ticket', {
                params: {
                    page: pageNumber,
                    size: 25
                },
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            setTickets(response.data.content)
            setTotalPages(response.data.totalPages)
            setLoading(false)
        } catch (e: unknown) {
            setLoading(false)
            const error = e as AxiosError
            setError(error.message)
        }
    }

    useEffect(() => {
        fetchTickets()
    }, [pageNumber])

    return {error, loading, tickets, totalPages}
}