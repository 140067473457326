import React from "react";
import {ISearchModels} from "../interfaces/exportedInterfaces";
import {Link} from "react-router-dom";

interface SearchTableRowProps {
    model: ISearchModels
}

export function SearchTableRow({model}: SearchTableRowProps) {
    return (
        <tr>
            <td>{model.mark}</td>
            <td>{model.diameter}</td>
            <td>{model.pack}</td>
            <td>{model.part}</td>
            <td>{model.plav}</td>
            {
                localStorage.getItem('actions')?.includes('SEARCH_BY_LOCATION') ?
                    <td><Link to={'/weights/' + model.mark.replace('/', '+') + '/' + model.diameter + '/' + model.pack + '/' + `${model.plav ? model.plav.replace('/', '+') : '-'}` + '/' + model.part.replace('/', '+') + '/sol'}>{model.solnechnogorsk}</Link></td>
                    :
                    <td>{model.solnechnogorsk}</td>
            }
            {
                localStorage.getItem('actions')?.includes('SEARCH_BY_LOCATION') ?
                    <td><Link to={'/weights/' + model.mark.replace('/', '+') + '/' + model.diameter + '/' + model.pack + '/' + `${model.plav ? model.plav.replace('/', '+') : '-'}` + '/' + model.part.replace('/', '+') + '/bel'}>{model.belSklad}</Link></td>
                    :
                    <td>{model.belSklad}</td>
            }
            {
                localStorage.getItem('actions')?.includes('SEARCH_BY_LOCATION') ?
                    <td><Link to={'/weights/' + model.mark.replace('/', '+') + '/' + model.diameter + '/' + model.pack + '/' + `${model.plav ? model.plav.replace('/', '+') : '-'}` + '/' + model.part.replace('/', '+') + '/manu'}>{model.manufacture}</Link></td>
                    :
                    <td>{model.manufacture}</td>
            }
            {
                localStorage.getItem('actions')?.includes('SEARCH_BY_LOCATION') ?
                    <td><Link to={'/weights/' + model.mark.replace('/', '+') + '/' + model.diameter + '/' + model.pack + '/' + `${model.plav ? model.plav.replace('/', '+') : '-'}` + '/' + model.part.replace('/', '+') + '/matr'}>{model.matrosovo}</Link></td>
                    :
                    <td>{model.matrosovo}</td>
            }
        </tr>
    )
}