import {useEffect, useState} from "react";
import axios from "axios";
import {BACKEND_URL} from "../ConstConfig";
import {IPlannedDispatch} from "../interfaces/exportedInterfaces";

// interface useCalendarProps {
//     flag: boolean
// }

export function useCalendar(currentDate: Date, flag: boolean) {

    const [error, setError] = useState<string>('')
    const [dispatches, setDispatches] = useState<IPlannedDispatch[]>([])
    const [unplannedDispatches, setUnplannedDispatches] = useState<IPlannedDispatch[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    async function fetchDispatches() {
        setError('')
        setLoading(false)
        try {
            // const currentDate = new Date()
            const startOfWeek = (): Date => {
                const date = new Date(currentDate)
                const day = date.getDay()
                const diff = date.getDate() - day + (day === 0 ? -6 : 1)
                return new Date(date.setDate(diff))
            }
            const date = startOfWeek()
            const response = await axios.get(BACKEND_URL + '/api/v1/planned-dispatch/week', {
                params: {
                    startDate: `${date.getFullYear()}-${String(date.getMonth() + 1).length === 1 ? '0' + String(date.getMonth() + 1) : date.getMonth() + 1}-${String(date.getDate()).length === 1 ? '0' + String(date.getDate()) : date.getDate()}`
                },
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            const responseUnplannedDispatches = await  axios.get(BACKEND_URL + '/api/v1/planned-dispatch/unplanned', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            setDispatches(response.data)
            setUnplannedDispatches(responseUnplannedDispatches.data)
            setLoading(false)
        } catch (e: unknown) {
            setLoading(false)
            setError('Не удалось получить отгрузки')
        }
    }

    useEffect(() => {
        fetchDispatches()
    }, [currentDate, flag])

    return {dispatches, unplannedDispatches, error, loading}

}