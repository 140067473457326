import {IPart} from "../interfaces/exportedInterfaces";
import axios from "axios";
import {BACKEND_URL} from "../ConstConfig";
import {useLoaderData} from "react-router-dom";
import React from "react";

// @ts-ignore
export async function partPageLoader({params}) {
    if (!localStorage.getItem('token')) {
        const part: IPart = {batch: '', diameter: '', melt: '', packing: '', standard: [], manufacturer: '', mechanicalProperties: { "Содержание ферритной фазы, %": '', "Временное сопротивление разрыву, кгс/мм2": '', "Временное сопротивление разрыву, МПа": '', "Относительное удлинение, %": '', "Состояние поверхности": '', "Удельное электрическое сопротивление, мкОм*м": '', 'Живучесть, ч/°С': '' }}
        return {part}
    } else {
        const response = await axios.post(BACKEND_URL + '/api/v1/batch', {
            name: params.batch.replace('+', '/')
        }, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token")
            }
        })
        const part: IPart = response.data
        return {part}
    }
}

interface IPartProps {
    part: IPart
}

export function PartPageInfo() {

    const {part}= useLoaderData() as IPartProps

    return (
        <>
            <div className='mainbar-container'>
                <table className='one-card-table' style={{marginBottom: '0'}}>
                    <tr>
                        <td className='header'>Партия</td>
                        <td>{part.batch}</td>
                    </tr>
                    <tr>
                        <td className='header'>Диаметр</td>
                        <td>{part.diameter}</td>
                    </tr>
                    <tr>
                        <td className='header'>Плавка</td>
                        <td>{part.melt}</td>
                    </tr>
                    <tr>
                        <td className='header'>Производитель</td>
                        <td>{part.manufacturer}</td>
                    </tr>
                    <tr>
                        <td className='header'>Упаковка</td>
                        <td>{part.packing}</td>
                    </tr>
                    <tr>
                        <td className='header'>Стандарты</td>
                        <td>{part.standard === null ? '-' : part.standard.join(', ')}</td>
                    </tr>
                </table>
            </div>
            <div className='mainbar-container'>
                <table className='one-card-table'>
                    <tr>
                        <td className='normal-header' colSpan={Object.keys(part.mechanicalProperties).length} >Мех. свойства</td>
                    </tr>
                    <tr>
                        {Object.keys(part.mechanicalProperties).map(property => <td className='header-mechs' key={`prop_${property}`}>{property}</td>)}
                    </tr>
                    <tr>
                        {Object.keys(part.mechanicalProperties).map(property => <td className='value-mechs' key={`value_${property}`}>{part.mechanicalProperties[property]}</td>)}
                    </tr>
                </table>
            </div>
        </>
    )
}