import {NewModelCardProps} from "./Card";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import { ReactComponent as SpeedTruck } from '../assets/truck-speed-svgrepo-com.svg';
import { ReactComponent as Truck } from '../assets/truck-svgrepo-com.svg';
import { ReactComponent as Trash } from '../assets/trash-svgrepo-com.svg';
import { ReactComponent as Warning } from '../assets/warning-circle-svgrepo-com.svg';
import { ReactComponent as WarehouseGreen } from '../assets/warehouse-green-svgrepo-com.svg';
import { ReactComponent as WarehouseYellow } from '../assets/warehouse-yellow-svgrepo-com.svg';
import { ReactComponent as GrayTruck } from '../assets/truck-grey-svgrepo-com.svg';
import { ReactComponent as StoragePlace } from "../assets/packing-warehouse-svgrepo-com.svg";
import {StatusAligner} from "../pages/DispatchCalendarPage";
import {ISmallStorageLocation} from "../interfaces/exportedInterfaces";

interface NewCardStatusProps {
    value: string,
    location: ISmallStorageLocation,
    status: JSX.Element,
    place: JSX.Element
}

export function NewCardStatus({value, status, location, place}: NewCardStatusProps) {

    const [overlayVisibleStatus, setOverlayVisibleStatus] = useState<boolean>(false)
    const [overlayVisiblePlace, setOverlayVisiblePlace] = useState<boolean>(false)

    return <>
        <div style={{marginTop: '3px'}} onMouseEnter={() => setOverlayVisibleStatus(true)} onMouseLeave={() => setOverlayVisibleStatus(false)}>
            {status}
        </div>
        <div style={{marginTop: '3px'}} onMouseEnter={() => setOverlayVisiblePlace(true)} onMouseLeave={() => setOverlayVisiblePlace(false)}>
            {place}
        </div>
        {
            overlayVisibleStatus && !overlayVisiblePlace &&
            <StatusAligner>
                <StatusDetails value={value}/>
            </StatusAligner>
        }
        {
            overlayVisiblePlace && !overlayVisibleStatus &&
            <StatusAligner>
                <PlaceDetails location={location}/>
            </StatusAligner>
        }
    </>
}

interface StatusDetailsProps {
    value: string
}

interface PlaceDetailsProps {
    location: ISmallStorageLocation
}

export function StatusDetails({value}: StatusDetailsProps) {
    return <div className='device-failed-checks__container-status'>
        <div className='device-failed-checks__label' style={{color: 'black'}}>
            <strong> {value} </strong> <br/>
        </div>
    </div>
}

export function PlaceDetails({location}: PlaceDetailsProps) {
    return <div className='device-failed-checks__container-status'>
        <div className='device-failed-checks__label' style={{color: 'black'}}>
            <strong>{location.storageType} (р{location.row}, м{location.place}) </strong>
        </div>
    </div>
}

export function NewCard({card, selectedCards, setSelectedCards, check, setCheck}: NewModelCardProps) {

    const [clicked, setClicked] = useState<boolean>(false)

    function cartActions() {
        if (setCheck) {
            setCheck(!check)
        }
        setClicked(!clicked)
        if (selectedCards?.includes(String(card.id))) {
            const index = selectedCards.indexOf(String(card.id))
            if (setSelectedCards) {
                let temp = selectedCards
                temp.splice(index, 1)
                setSelectedCards(temp)
            }
        } else {
            if (setSelectedCards && selectedCards) {
                let temp: string[] = selectedCards
                temp = [...temp, String(card.id)]
                setSelectedCards(temp)
            }
        }
    }

    const status = () => {
        if (card.status === 'В наличии') {
            return <WarehouseGreen style={{marginLeft: '4px'}}/>
        } else if (card.status === 'Резерв') {
            return <WarehouseYellow style={{marginLeft: '4px'}}/>
        } else if (card.status === 'В пути на склад') {
            return <SpeedTruck style={{marginLeft: '4px', marginTop: '3px'}}/>
        } else if (card.status === 'Подготовлено к отгрузке') {
            return <Truck style={{marginLeft: '4px', marginTop: '3px'}}/>
        } else if (card.status === 'Отгружено') {
            return <GrayTruck style={{marginLeft: '4px', marginTop: '3px'}}/>
        } else if (card.status === 'Брак') {
            return <Warning style={{marginLeft: '4px', marginTop: '2px'}}/>
        } else {
            return <Trash style={{marginLeft: '4px'}}/>
        }
    }

    const place = () => {
        if (card.storageLocation !== null) {
            return <StoragePlace style={{width: '16px', height: '16px'}}/>
        } else {
            return <></>
        }
    }

    if (localStorage.getItem('actions')?.includes('POSITION_INFO_BY_ID') && !selectedCards) {
        return (
            <Link className="new-card-item" target="_blank" to={'/position/' + card.id + '/info'}>
                <div className="new-card">
                    <div className="main-card-body">
                        <span className='new-card-title'>{card.type} {status()} {place()}</span>
                        <span className='new-card-field'><strong>Марка:</strong> {card.mark}</span>
                        <span className='new-card-field'><strong>Диаметр:</strong> {card.diameter}</span>
                        <span className='new-card-field'><strong>Упаковка:</strong> {card.packing}</span>
                        <span className='new-card-field'><strong>Плавка:</strong> {card.plav}</span>
                        <span className='new-card-field'><strong>Партия:</strong> {card.part}</span>
                    </div>
                    <div className="card-details">
                        <div className="center">
                            <h2>{card.type} <NewCardStatus value={card.status} location={card.storageLocation} status={status()} place={place()}/></h2>
                            <hr color='#beaa87'/>
                            <span><strong>Вес:</strong> {card.weight}</span>
                            <span><strong>Склад:</strong> {card.location}</span>
                            <span><strong>Комментарий:</strong> {card.comment ? card.comment : '-'}</span>
                        </div>
                    </div>
                </div>
            </Link>
        )
    } else if (selectedCards) {
        return (
            <div className="new-card" style={{borderColor: clicked ? 'green' : '#cccccc'}} onClick={cartActions}>
                <div className="main-card-body">
                    <span className='new-card-title'>{card.type} {status()}</span>
                    <span className='new-card-field'><strong>Марка:</strong> {card.mark}</span>
                    <span className='new-card-field'><strong>Диаметр:</strong> {card.diameter}</span>
                    <span className='new-card-field'><strong>Упаковка:</strong> {card.packing}</span>
                    <span className='new-card-field'><strong>Плавка:</strong> {card.plav}</span>
                    <span className='new-card-field'><strong>Партия:</strong> {card.part}</span>
                </div>
                <div className="card-details">
                    <div className="center">
                        <h2>{card.type} <NewCardStatus value={card.status} location={card.storageLocation} status={status()} place={place()}/></h2>
                        <hr color='#beaa87'/>
                        <span><strong>Вес:</strong> {card.weight}</span>
                        <span><strong>Склад:</strong> {card.location}</span>
                        <span><strong>Комментарий:</strong> {card.comment ? card.comment : '-'}</span>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="new-card">
                <div className="main-card-body">
                    <span className='new-card-title'>{card.type} {status()}</span>
                    <span className='new-card-field'><strong>Марка:</strong> {card.mark}</span>
                    <span className='new-card-field'><strong>Диаметр:</strong> {card.diameter}</span>
                    <span className='new-card-field'><strong>Упаковка:</strong> {card.packing}</span>
                    <span className='new-card-field'><strong>Плавка:</strong> {card.plav}</span>
                    <span className='new-card-field'><strong>Партия:</strong> {card.part}</span>
                </div>
                <div className="card-details">
                    <div className="center">
                    <h2>{card.type} <NewCardStatus value={card.status} location={card.storageLocation} status={status()} place={place()}/></h2>
                        <hr color='#beaa87'/>
                        <span><strong>Вес:</strong> {card.weight}</span>
                        <span><strong>Склад:</strong> {card.location}</span>
                        <span><strong>Комментарий:</strong> {card.comment ? card.comment : '-'}</span>
                    </div>
                </div>
            </div>
        )
    }
}