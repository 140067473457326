import {useTickets} from "../hooks/useTickets";
import React, {useState} from "react";
import {Loader} from "../components/Loader";

export function TicketsPage() {

    const [pageNumber, setPageNumber] = useState(0)

    const {tickets, loading, error, totalPages} = useTickets({pageNumber: pageNumber})

    return (
        <>
            {
                localStorage.getItem('actions')?.includes('VIEW_TICKETS') &&
                <div className='dispatch-body'>
                    {loading &&
                        <div style={{height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Loader/>
                        </div>}
                    {error &&
                        <h1 style={{color: 'red', marginBottom: '0', marginTop: '90px'}}>Ошибка загрузки: {error}</h1>}
                    {!loading &&
                        <table style={{margin: '10px 0', width: '98%'}}>
                            <thead>
                            <tr>
                                <th>ID тикета</th>
                                <th>ID позиции</th>
                                <th>Дата создания</th>
                                <th>Комментарий</th>
                                <th>Автор</th>
                                <th>Дата обработки</th>
                                <th>Статус</th>
                            </tr>
                            </thead>
                            <tbody>
                            {tickets.map((ticket, index) => <tr className='hoverable-tr' onClick={() => window.open(`/ticket/${ticket.id}`, '_blank')}>
                                <td>{ticket.id}</td>
                                <td>{ticket.currentPositionInfo.id}</td>
                                <td>{ticket.createdAt}</td>
                                <td>{ticket.comment}</td>
                                <td>{ticket.createdByWorker.firstName + ' ' + ticket.createdByWorker.secondName}</td>
                                <td>{ticket.approvedAt ? ticket.approvedAt : '-'}</td>
                                <td>{ticket.status}</td>
                            </tr>)}
                            </tbody>
                        </table>
                    }
                    <div className='plav-page-table-page-container'>
                        <button type='button' onClick={() => {
                            if (pageNumber > 0) {
                                setPageNumber(pageNumber - 1)
                            } else {
                                return
                            }
                        }}>{'<'}</button>
                        <span>стр: {pageNumber + 1}</span>
                        <button type='button' onClick={() => {
                            if (pageNumber + 1 < totalPages) {
                                setPageNumber(pageNumber + 1)
                            } else {
                                return
                            }
                        }}>{'>'}</button>
                    </div>
                </div>
            }
        </>
    )
}