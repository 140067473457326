import {useEffect, useState} from "react";
import {IGradeStandard} from "../interfaces/exportedInterfaces";
import axios from "axios";
import {BACKEND_URL} from "../ConstConfig";

export function useStandardsDictionary(refetchFlag: boolean) {

    const [standards, setStandards] = useState<IGradeStandard[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>('')

    async function fetchStandards() {
        setLoading(true)
        setError('')
        try {
            const response = await axios.get(BACKEND_URL + '/api/v1/standards', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            setStandards(response.data)
            setLoading(false)
        } catch (e) {
            setError('Не удалось получить список стандартов')
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchStandards()
    }, [refetchFlag])

    return {standards, loading, error}
}