import {Loader} from "../components/Loader";
import React, {useState} from "react";
import {toast, ToastContainer} from "react-toastify";
import axios from "axios";
import {BACKEND_URL} from "../ConstConfig";
import '../css/MarksDirectoryPage.css'
import {useStandardsDictionary} from "../hooks/useStandardsDictionary";
import {StandardsDirectoryTableRow} from "../components/StandardsDirectoryTableRow";
import {useMarksDictionary} from "../hooks/useMarksDictionary";
import {SelectFields} from "./RegisterPage";
import makeAnimated from "react-select/animated";
import Select from "react-select";

export function StandardsDirectoryPage() {

    const [refetchFlag, setRefetchFlag] = useState<boolean>(false);
    const [newMarks, setNewMarks] = useState<SelectFields[]>([])
    const [newStandard, setNewStandard] = useState<string>('')
    const [changeMarkMode, setChangeMarkMode] = useState<boolean>(false)
    const [miniFlag, setMiniFlag] = useState<boolean>(false)
    const [chosenId, setChosenId] = useState<string>('')

    const {standards, loading, error} = useStandardsDictionary(refetchFlag)
    const {marks} = useMarksDictionary(refetchFlag)

    const marksOptions = () => {
        let temp: SelectFields[] = []
        marks.map(mark => temp = [...temp, {value: mark.grade, label: mark.grade}])
        return temp
    }

    async function saveStandard(event: { preventDefault: () => void; }) {
        event.preventDefault()
        try {
            const response = await toast.promise(axios.post(BACKEND_URL + '/api/v1/standards', {
                grades: [],
                standard: newStandard
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }), {
                pending: 'Добавление стандарта..',
                success: 'Стандарт добавлен!',
                error: 'Не удалось добавить стандарт'
            })
            if (response.status === 200 || response.status === 201 || response.status === 204) {
                setRefetchFlag(!refetchFlag)
            }
        } catch (e) {
            return
        }
    }

    async function updateStandard(event: { preventDefault: () => void; }) {
        event.preventDefault()
        let temp: string[] = []
        newMarks.map(mark => temp = [...temp, mark.value])
        try {
            const response = await toast.promise(axios.put(BACKEND_URL + '/api/v1/standards/' + chosenId, {
                grades: temp,
                standard: newStandard
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }), {
                pending: 'Обновляем стандарт..',
                success: 'Стандарт обновлен!',
                error: 'Не удалось обновить стандарт'
            })
            if (response.status === 200 || response.status === 201 || response.status === 204) {
                window.location.reload()
            }
        } catch (e) {
            return
        }
    }

    return (
        <>
            {
                localStorage.getItem('actions')?.includes('VIEW_STANDARDS') &&
                !loading ?
                    <div className='dispatch-body'>
                        {
                            error && <h1 style={{color: 'crimson'}}>{error}</h1>
                        }
                        {
                            !error &&
                            <form className='container' onSubmit={saveStandard}>
                                <table style={{marginBottom: '20px'}}>
                                    <thead>
                                    <tr>
                                        <th style={{width: '10%'}}>ID</th>
                                        <th style={{width: '30%'}}>Стандарт</th>
                                        <th style={{width: '50%'}}>Марки</th>
                                        {
                                            localStorage.getItem('actions')?.includes('EDIT_STANDARDS') &&
                                            <th style={{width: '10%'}}>Действия</th>
                                        }
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        standards.map((standard, index) => <StandardsDirectoryTableRow
                                            index={index} standard={standard} refetchFlag={refetchFlag} marksOptions={marksOptions()}
                                            setRefetchFlag={setRefetchFlag} markInput={newMarks} setMarkInput={setNewMarks} standardInput={newStandard}
                                            setStandardInput={setNewStandard} setOverallFlag={setChangeMarkMode} miniFlag={miniFlag} setChosenId={setChosenId}/>)
                                    }
                                    {
                                        localStorage.getItem('actions')?.includes('EDIT_STANDARDS') &&
                                        <tr>
                                            <td>
                                                -
                                            </td>
                                            <td>
                                                <input type="text" className='requirements-input'
                                                       style={{width: '100%'}}
                                                       name='newPacking' value={newStandard} required
                                                       onChange={(event) => setNewStandard(event.target.value)}/>
                                            </td>
                                            <td>
                                                -
                                            </td>
                                            <td>
                                                -
                                            </td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>
                                {/*{*/}
                                {/*    changeMarkMode && localStorage.getItem('actions')?.includes('EDIT_STANDARDS') &&*/}
                                {/*    <div className='cancel-changedate-container' style={{margin: '0'}}>*/}
                                {/*        <button type='submit' className='save-btn' onMouseOver={() => setMiniFlag(true)}*/}
                                {/*                onMouseOut={() => setMiniFlag(false)}>Изменить стандарт</button>*/}
                                {/*        <button type='button' style={{marginLeft: '10px'}} onClick={() => setChangeMarkMode(false)}>Добавить новый стандарт</button>*/}
                                {/*    </div>*/}
                                {/*}*/}
                                {
                                    !changeMarkMode && localStorage.getItem('actions')?.includes('EDIT_STANDARDS') &&
                                    <div className='cancel-changedate-container' style={{margin: '0'}}>
                                        <button type='submit' className='save-btn'>Добавить стандарт</button>
                                    </div>
                                }
                            </form>
                        }
                    </div>
                    :
                    <div style={{height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Loader/>
                    </div>
            }
            <ToastContainer/>
        </>
    )
}