import {Loader} from "../components/Loader";
import React, {useState} from "react";
import {toast, ToastContainer} from "react-toastify";
import {useMarksDictionary} from "../hooks/useMarksDictionary";
import axios from "axios";
import {BACKEND_URL} from "../ConstConfig";
import {MarksDirectoryTableRow} from "../components/MarksDirectoryTableRow";
import '../css/MarksDirectoryPage.css'
import {useSettingsDictionary} from "../hooks/useSettingsDictionary";
import {SettingsDirectoryTableRow} from "../components/SettingsDirectoryTableRow";

export function SettingsDirectoryPage() {

    const [refetchFlag, setRefetchFlag] = useState<boolean>(false);
    const [newKey, setNewKey] = useState<string>('')
    const [newType, setNewType] = useState<string>('')
    const [newValue, setNewValue] = useState<string>('')
    const [newName, setNewName] = useState<string>('')
    const [changeMarkMode, setChangeMarkMode] = useState<boolean>(false)
    const [miniFlag, setMiniFlag] = useState<boolean>(false)
    const [chosenKey, setChosenKey] = useState<string>('')

    const {settings, loading, error} = useSettingsDictionary(refetchFlag)

    const options = [
        'STRING',
        'INTEGER',
        'BOOLEAN',
        'DECIMAL'
    ]

    async function saveSetting(event: { preventDefault: () => void; }) {
        event.preventDefault()
        try {
            const response = await toast.promise(axios.post(BACKEND_URL + '/api/v1/settings', {
                key: newKey,
                value: newValue,
                name: newName,
                type: newType
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }), {
                pending: 'Добавление параметра..',
                success: 'Параметр добавлен!',
                error: 'Не удалось добавить параметр'
            })
            if (response.status === 200 || response.status === 201) {
                setRefetchFlag(!refetchFlag)
            }
        } catch (e) {
            return
        }
    }

    async function updateSetting(event: { preventDefault: () => void; }) {
        event.preventDefault()
        try {
            const response = await toast.promise(axios.put(BACKEND_URL + '/api/v1/settings/' + newKey, {
                key: newKey,
                value: newValue,
                name: newName,
                type: newType
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }), {
                pending: 'Обновляем параметр..',
                success: 'Параметр обновлен!',
                error: 'Не удалось обновить параметр'
            })
            if (response.status === 200 || response.status === 201 || response.status === 204) {
                window.location.reload()
            }
        } catch (e) {
            return
        }
    }

    return (
        <>
            {
                localStorage.getItem('actions')?.includes('VIEW_APP_SETTINGS') &&
                !loading ?
                    <div className='dispatch-body'>
                        {
                            error && <h1 style={{color: 'crimson'}}>{error}</h1>
                        }
                        {
                            !error &&
                            <form className='container' onSubmit={changeMarkMode ? updateSetting : saveSetting}>
                                <table style={{marginBottom: '20px'}}>
                                    <thead>
                                    <tr>
                                        <th style={{width: '22.5%'}}>Ключ</th>
                                        <th style={{width: '22.5%'}}>Имя</th>
                                        <th style={{width: '22.5%'}}>Тип</th>
                                        <th style={{width: '22.5%'}}>Значение</th>
                                        {
                                            localStorage.getItem('actions')?.includes('EDIT_APP_SETTINGS') &&
                                            <th style={{width: '10%'}}>Действия</th>
                                        }
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        settings.map((setting, index) => <SettingsDirectoryTableRow
                                            index={index} setting={setting} refetchFlag={refetchFlag} setRefetchFlag={setRefetchFlag}
                                            keyInput={newKey} setKeyInput={setNewKey}
                                            nameInput={newName} setNameInput={setNewName} typeInput={newType} setTypeInput={setNewType} valueInput={newValue}
                                            setValueInput={setNewValue} setOverallFlag={setChangeMarkMode} miniFlag={miniFlag} setChosenKey={setChosenKey}/>)
                                    }
                                    {
                                        localStorage.getItem('actions')?.includes('EDIT_APP_SETTINGS') && !changeMarkMode &&
                                        <tr>
                                            <td>
                                                <input type="text" className='requirements-input'
                                                       style={{width: '100%'}}
                                                       name='newPacking' value={newKey} required
                                                       onChange={(event) => setNewKey(event.target.value)}/>
                                            </td>
                                            <td>
                                                <input type="text" className='requirements-input'
                                                       style={{width: '100%'}}
                                                       name='newPacking' value={newName} required
                                                       onChange={(event) => setNewName(event.target.value)}/>
                                            </td>
                                            <td>
                                                <select className='requirements-input' style={{width: '100%'}} required value={newType} onChange={(event) => setNewType(event.target.value)}>
                                                    <option value="" selected disabled>Выберите тип..</option>
                                                    {options.map(option => <option value={option}>{option}</option>)}
                                                </select>
                                            </td>
                                            <td>
                                                <input type="text" className='requirements-input'
                                                       style={{width: '100%'}}
                                                       name='newPacking' value={newValue} required
                                                       onChange={(event) => setNewValue(event.target.value)}/>
                                            </td>
                                            <td>
                                                -
                                            </td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>
                                {
                                    changeMarkMode && localStorage.getItem('actions')?.includes('EDIT_APP_SETTINGS') &&
                                    <div className='cancel-changedate-container' style={{margin: '0'}}>
                                        <button type='submit' className='save-btn' onMouseOver={() => setMiniFlag(true)} onMouseOut={() => setMiniFlag(false)}>Изменить параметр</button>
                                        <button type='button' style={{marginLeft: '10px'}} onClick={() => setChangeMarkMode(false)}>Добавить новый параметр</button>
                                    </div>
                                }
                                {
                                    !changeMarkMode && localStorage.getItem('actions')?.includes('EDIT_APP_SETTINGS') &&
                                    <div className='cancel-changedate-container' style={{margin: '0'}}>
                                        <button type='submit' className='save-btn'>Добавить параметр</button>
                                    </div>
                                }
                            </form>
                        }
                    </div>
                    :
                    <div style={{height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Loader/>
                    </div>
            }
            <ToastContainer/>
        </>
    )
}