import {CardView} from "./CardView";
import {useReserve} from "../pages/ReserveDetailsPage";
import React, {useState} from "react";
import {toast, ToastContainer} from "react-toastify";
import axios from "axios";
import {BACKEND_URL} from "../ConstConfig";


export function ReserveDetails() {

    const {reserve} = useReserve()

    const [mark, setMark] = useState<string>(reserve.mark)
    const [packing, setPacking] = useState<string>(reserve.packing)
    const [diameter, setDiameter] = useState<string>(reserve.diameter)
    const [part, setPart] = useState<string>(reserve.part)
    const [bill, setBill] = useState<string>(reserve.bill)
    const [comment, setComment] = useState<string>(reserve.comment)
    const [weight, setWeight] = useState<string>(String(reserve.weight))
    const [customer, setCustomer] = useState<string>(reserve.customer)

    const [editMode, setEditMode] = useState<boolean>(false)

    async function editReserve() {
        try {
            const response = await toast.promise(axios.post(BACKEND_URL + '/api/v1/reserve' + reserve.id, {
                mark: mark,
                diameter: Number(diameter),
                packing: packing,
                part: part,
                weight: Number(weight),
                customer: customer,
                bill: bill,
                comment: comment
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }), {
                pending: 'Сохраняем..',
                success: 'Резерв успешно обновлен',
                error: 'Не удалось изменить резерв'
            })
        } catch (e) {
            return
        }
    }

    return (
        <>
            {
                localStorage.getItem('actions')?.includes('RESERVE_INFO_BY_ID') &&
                <div>
                    <div className='mainbar-container'>
                        <table className='one-card-table' style={{marginBottom: '20px'}}>
                            <tr>
                                <td className='header'>Марка</td>
                                {
                                    !editMode &&
                                    <td>{reserve.mark}</td>
                                }
                                {
                                    editMode &&
                                    <td>
                                        <input className='edit-mode-input' type="text" value={mark}
                                               onChange={event => setMark(event.target.value)}/>
                                    </td>
                                }
                            </tr>
                            <tr>
                                <td className='header'>Диаметр</td>
                                {
                                    !editMode &&
                                    <td>{reserve.diameter}</td>
                                }
                                {
                                    editMode &&
                                    <td>
                                        <input className='edit-mode-input' type="text" value={diameter}
                                               onChange={event => setDiameter(event.target.value)}/>
                                    </td>
                                }
                            </tr>
                            <tr>
                                <td className='header'>Упаковка</td>
                                {
                                    !editMode &&
                                    <td>{reserve.packing}</td>
                                }
                                {
                                    editMode &&
                                    <td>
                                        <input className='edit-mode-input' type="text" value={packing}
                                               onChange={event => setPacking(event.target.value)}/>
                                    </td>
                                }
                            </tr>
                            {
                                reserve.part &&
                                <tr>
                                    <td className='header'>Партия</td>
                                    {
                                        !editMode &&
                                        <td>{reserve.part}</td>
                                    }
                                    {
                                        editMode &&
                                        <td>
                                            <input className='edit-mode-input' type="text" value={part}
                                                   onChange={event => setPart(event.target.value)}/>
                                        </td>
                                    }
                                </tr>
                            }
                            <tr>
                                <td className='header'>Вес</td>
                                {
                                    !editMode &&
                                    <td>{reserve.weight}</td>
                                }
                                {
                                    editMode &&
                                    <td>
                                        <input className='edit-mode-input' type="text" value={weight}
                                               onChange={event => setWeight(event.target.value)}/>
                                    </td>
                                }
                            </tr>
                            {
                                reserve.comment &&
                                <tr>
                                    <td className='header'>Комментарий</td>
                                    {
                                        !editMode &&
                                        <td>{reserve.comment}</td>
                                    }
                                    {
                                        editMode &&
                                        <td>
                                            <input className='edit-mode-input' type="text" value={comment}
                                                   onChange={event => setComment(event.target.value)}/>
                                        </td>
                                    }
                                </tr>
                            }
                            <tr>
                                <td className='header'>Покупатель</td>
                                {
                                    !editMode &&
                                    <td>{reserve.customer}</td>
                                }
                                {
                                    editMode &&
                                    <td>
                                        <input className='edit-mode-input' type="text" value={customer}
                                               onChange={event => setCustomer(event.target.value)}/>
                                    </td>
                                }
                            </tr>
                            <tr>
                                <td className='header'>Создатель</td>
                                <td>{reserve.worker}</td>
                            </tr>
                            <tr>
                                <td className='header'>Счёт</td>
                                {
                                    !editMode &&
                                    <td>{reserve.bill}</td>
                                }
                                {
                                    editMode &&
                                    <td>
                                        <input className='edit-mode-input' type="text" value={bill}
                                               onChange={event => setBill(event.target.value)}/>
                                    </td>
                                }
                            </tr>
                            <tr>
                                <td className='header'>Локация</td>
                                <td>{reserve.location}</td>
                            </tr>
                            <tr>
                                <td className='header'>Дата создания</td>
                                <td>{reserve.creationDate}</td>
                            </tr>
                            <tr>
                                <td className='header'>Дата отмены</td>
                                <td>{reserve.dueDate}</td>
                            </tr>
                            <tr>
                                <td className='header'>Статус</td>
                                <td>{reserve.status}</td>
                            </tr>
                        </table>
                    </div>
                    {
                        !editMode &&
                        <button type='button' className='btn-position-edit'
                                onClick={() => setEditMode(true)}>Редактировать резерв
                        </button>
                    }
                    {
                        editMode &&
                        <div className='edit-btn-container'>
                            <button type='button' className='btn-position-edit green' onClick={() => {
                                setEditMode(false)
                                editReserve()
                            }}>Подтвердить поля</button>
                            <button type='button' className='btn-position-edit red' onClick={() => setEditMode(false)}>Отменить редактирование</button>
                        </div>
                    }
                    {reserve.positions.length !== 0 &&
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='search-cards' style={{width: '97%'}}>
                                <div>
                                    <h2>Содержит:</h2>
                                    <CardView cards={reserve.positions}/>
                                </div>
                            </div>
                        </div>
                    }
                    <ToastContainer/>
                </div>
            }
        </>
    )
}