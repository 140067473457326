import {useState} from "react";
import axios from "axios";
import {BACKEND_URL} from "../ConstConfig";
import {IModelsCard} from "../interfaces/exportedInterfaces";
import {Card} from "../components/Card";
import '../css/WeightTransferPage.css'

export function WeightTransferPage() {

    const [idFrom, setIdFrom] = useState<string>('')
    const [idTo, setIdTo] = useState<string>('')
    const [weight, setWeight] = useState<string>('')

    const [cardFrom, setCardFrom] = useState<IModelsCard>()
    const [cardTo, setCardTo] = useState<IModelsCard>()

    const [requestError, setRequestError] = useState<string>('')

    const [success, setSuccess] = useState<string>('')

    const [cardsWarning, setCardsWarning] = useState<string>('')



    async function sendRequest() {
        setRequestError('')
        setCardsWarning('')
        setSuccess('')
        if (idFrom.length === 0 || idTo.length === 0 || weight.length === 0) {
            setRequestError('Поля не могут быть пустыми')
        } else {
            try {
                const response = await axios.post(BACKEND_URL + '/api/v1/position/weight-transfer', {
                    from: Number(idFrom),
                    to: Number(idTo),
                    weight: Number(weight)
                }, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                })
                if (response.status === 200) {
                    setSuccess('Вес успешно перенесен')
                    try {
                        const responseCardFrom = await axios.get(BACKEND_URL + '/api/v1/position/' + idFrom, {
                            headers: {
                                Authorization: 'Bearer ' + localStorage.getItem('token')
                            }
                        })
                        const responseCardTo = await axios.get(BACKEND_URL + '/api/v1/position/' + idTo, {
                            headers: {
                                Authorization: 'Bearer ' + localStorage.getItem('token')
                            }
                        })
                        setCardFrom(responseCardFrom.data)
                        setCardTo(responseCardTo.data)
                    } catch (e) {
                        setCardsWarning('Не удалось прогрузить карточки, проверьте вес вручную')
                    }
                }
            } catch (e) {
                setRequestError('Не удалось перенести вес')
            }
        }
    }

    return (
        <>
            {
                localStorage.getItem('actions')?.includes('WEIGHT_TRANSFER') &&
                <div className='weight-transfer-main'>
                    {
                        requestError &&
                        <h3 style={{color: 'red'}}>{requestError}</h3>
                    }
                    {
                        success &&
                        <h3 style={{color: 'green'}}>{success}</h3>
                    }
                    {
                        cardsWarning &&
                        <h3 style={{color: 'rgb(253, 185, 0)'}}>{cardsWarning}</h3>
                    }
                    <div className='weight-transfer-inputs'>
                        <div className='weight-transfer-input'>
                            <label htmlFor="from">Перенести из:</label>
                            <input id='from' name='from' type="text" placeholder='ID' value={idFrom} required autoComplete='off'
                                onChange={event => setIdFrom(event.target.value.replace(/[^1234567890]+/g, ''))}/>
                        </div>
                        <div className='transfer-arrow'>
                            &#8594;
                        </div>
                        <div className='weight-transfer-input'>
                            <label htmlFor="to">Поместить в:</label>
                            <input id='to' name='to' type="text" placeholder='ID' value={idTo} required autoComplete='off'
                                onChange={event => setIdTo(event.target.value.replace(/[^1234567890]+/g, ''))}/>
                        </div>
                    </div>
                    <div className='weight-transfer-input'>
                        <label htmlFor="weight">Вес</label>
                        <input id='weight' name='weight' type="text" placeholder='Введите вес' value={weight} required autoComplete='off'
                            onChange={event => setWeight(event.target.value.replace(/[^.1234567890]+/g, ''))}/>
                    </div>
                    <button className='transfer-button' type='button' onClick={sendRequest}>Подтвердить</button>
                    {
                        cardFrom !== undefined && cardTo !== undefined && !cardsWarning &&
                        <div className='weight-transfer-cards'>
                            <div className='card-transfer'>
                                <h3>Новая карточка (из)</h3>
                                <Card card={cardFrom}/>
                            </div>
                            <div className='card-transfer'>
                                <h3>Новая карточка (в)</h3>
                                <Card card={cardTo}/>
                            </div>
                        </div>
                    }
                </div>
            }
        </>
    )
}