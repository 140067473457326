import { useState, useCallback } from 'react';

const useContextMenu = () => {
    const [menuVisible, setMenuVisible] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

    const showMenu = useCallback((event: any) => {
        event.preventDefault();
        setMenuPosition({ x: event.pageX, y: event.pageY });
        setMenuVisible(true);
    }, []);

    const hideMenu = useCallback(() => {
        setMenuVisible(false);
    }, []);

    return { menuVisible, menuPosition, showMenu, hideMenu };
};

export default useContextMenu;