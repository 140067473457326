import React, {useState} from "react";
import {ISetting} from "../interfaces/exportedInterfaces";
import {toast} from "react-toastify";
import axios from "axios";
import {BACKEND_URL} from "../ConstConfig";

interface SettingsDirectoryTableRowProps {
    index: number;
    setting: ISetting,
    refetchFlag: boolean,
    setRefetchFlag: (prop: boolean) => void,
    keyInput: string,
    setKeyInput: (prop: string) => void,
    nameInput: string,
    setNameInput: (prop: string) => void,
    typeInput: string,
    setTypeInput: (prop: string) => void,
    valueInput: string,
    setValueInput: (prop: string) => void,
    setOverallFlag: (prop: boolean) => void,
    miniFlag: boolean,
    setChosenKey: (prop: string) => void,
}

export function SettingsDirectoryTableRow({index, setting, keyInput, setKeyInput, nameInput, setChosenKey, setTypeInput, typeInput, setValueInput, valueInput, setNameInput, refetchFlag, setRefetchFlag, setOverallFlag, miniFlag}: SettingsDirectoryTableRowProps): JSX.Element {

    const [flag, setFlag] = useState<boolean>(false);
    const [ulFocus, setUlFocus] = useState<boolean>(false)

    async function deleteSetting(key: string) {
        try {
            const response = await toast.promise(axios.delete(BACKEND_URL + '/api/v1/settings/' + key, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }), {
                pending: 'Удаление настройки..',
                success: 'Настройка удалена',
                error: 'Не удалось удалить настройку'
            })
            if (response.status === 200 || response.status === 201 || response.status === 204) {
                setRefetchFlag(!refetchFlag)
            }
        } catch (e) {
            return
        }
    }

    return (
        <tr className='hoverable-tr' key={index} onClick={() => {
            setKeyInput(setting.key)
            setNameInput(setting.name)
            setValueInput(setting.value)
            setTypeInput(setting.type)
            setOverallFlag(true)
            setFlag(true)
            setChosenKey(setting.key)
        }} tabIndex={0} onBlur={() => {
            if (!ulFocus) {
                setFlag(false)
            }
        }}>
            {
                flag && localStorage.getItem('actions')?.includes('EDIT_APP_SETTINGS') ?
                    <td>
                        <input className='requirements-input' style={{width: '100%'}} type="text" value={keyInput}
                               onChange={(event) => setKeyInput(event.target.value)} onMouseOver={() => setUlFocus(true)} onMouseOut={() => setUlFocus(false)}/>
                    </td>
                    :
                    <td>{setting.key}</td>
            }
            {
                flag && localStorage.getItem('actions')?.includes('EDIT_APP_SETTINGS') ?
                    <td>
                        <input className='requirements-input' style={{width: '100%'}} type="text" value={nameInput}
                               onChange={(event) => setNameInput(event.target.value)} onMouseOver={() => setUlFocus(true)} onMouseOut={() => setUlFocus(false)}/>
                    </td>
                    :
                    <td>{setting.name}</td>
            }
            {
                flag && localStorage.getItem('actions')?.includes('EDIT_APP_SETTINGS') ?
                    <td>
                        {/*<input className='requirements-input' style={{width: '100%'}} type="text" value={typeInput}*/}
                        {/*       onChange={(event) => setStandardInput(event.target.value)} onMouseOver={() => setUlFocus(true)} onMouseOut={() => setUlFocus(false)}/>*/}
                        <select name="type" className='requirements-input' value={typeInput}
                                onChange={(event) => setTypeInput(event.target.value)}
                                onMouseOver={() => setUlFocus(true)} onMouseOut={() => setUlFocus(false)}
                        >
                            <option value="" selected disabled>Выберите тип</option>
                            <option value="STRING">STRING</option>
                            <option value="INTEGER">INTEGER</option>
                            <option value="BOOLEAN">BOOLEAN</option>
                            <option value="DECIMAL">DECIMAL</option>
                        </select>
                    </td>
                    :
                    <td>{setting.type}</td>
            }
            {
                flag && localStorage.getItem('actions')?.includes('EDIT_APP_SETTINGS') ?
                    <td>
                        <input className='requirements-input' style={{width: '100%'}} type="text" value={valueInput}
                               onChange={(event) => setValueInput(event.target.value)} onMouseOver={() => setUlFocus(true)} onMouseOut={() => setUlFocus(false)}/>
                    </td>
                    :
                    <td>{setting.value}</td>
            }
            {
                localStorage.getItem('actions')?.includes('EDIT_APP_SETTINGS') &&
                <td>
                    <button type='button' className='remove-requirements-field-btn'
                            onClick={() => deleteSetting(setting.key)}>X
                    </button>
                </td>
            }
        </tr>
    )
}