import {useReserves} from "../hooks/useReserves";
import {Reserve} from "../components/Reserve";
import {Loader} from "../components/Loader";
import '../css/ReservePage.css'
import React, {useState} from "react";
import {ModalWindowReserveCreate} from "../components/ModalWindowReserveCreate";
import {useParts} from "../hooks/useParts";
import {useParams} from "react-router-dom";
import {ToastContainer} from "react-toastify";


export function ReservePage() {


    const [modal, setModal] = useState(false)
    const [pageNumber, setPageNumber] = useState(0)
    const {mark, diameter, packing} = useParams()
    const {reserves, error, loader} = useReserves({pageNumber: pageNumber, mark: mark, diameter: diameter, packing: packing})

    return (
        <>
            {
                localStorage.getItem('actions')?.includes('RESERVES_INFO') &&
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    {/*<div style={{width: '100%'}}>*/}
                        {modal && <ModalWindowReserveCreate openModal={setModal}/>}
                        {error && <h2 style={{color: 'red', marginTop: '90px'}}>Ошибка: {error}</h2>}
                        {loader && <div style={{height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Loader/>
                        </div>}
                        {!error && !loader &&
                            <div style={{marginTop: '25px', width: '97%'}}>
                                {
                                    localStorage.getItem('actions')?.includes('ADD_RESERVE') &&
                                    <button className='reserve-button' onClick={() => setModal(true)}>Добавить резерв</button>
                                }
                                <div className='card-container-reg'>
                                    {reserves.map(reserve => <Reserve reserve={reserve}/>)}
                                </div>
                                <div className='plav-page-table-page-container'>
                                    <button type='button' onClick={() => {
                                        if (pageNumber > 0) {
                                            setPageNumber(pageNumber - 1)
                                        } else {
                                            return
                                        }
                                    }}>{'<'}</button>
                                    <span>стр: {pageNumber + 1}</span>
                                    <button type='button' onClick={() => {
                                        if (reserves.length === 25) {
                                            setPageNumber(pageNumber + 1)
                                        } else {
                                            return
                                        }
                                    }}>{'>'}</button>
                                </div>
                            </div>
                        }
                    {/*</div>*/}
                    <ToastContainer/>
                </div>
            }
        </>
    )
}