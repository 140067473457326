import React from "react";
import {useCard} from "../pages/PositionPage";
import {usePositionHistory} from "../hooks/usePositionHistory";
import {Loader} from "./Loader";
import {ExtendableTableRow} from "./ExtendableTableRow";

export function PositionHistory() {

    const {card} = useCard()

    const {history, loading, error} = usePositionHistory(card.id)

    return (
        <>
            {
                localStorage.getItem('actions')?.includes('POSITION_HISTORY') &&
                <div className='dispatch-body'>
                    {
                        loading && <Loader/>
                    }
                    {
                        error && <h1 style={{color: 'crimson'}}>{error}</h1>
                    }
                    {
                        !loading && !error &&
                        <div className='container' style={{margin: '50px auto', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <table style={{width: '950px'}}>
                                <thead>
                                <tr>
                                    <th style={{width: '5%'}}>ID</th>
                                    <th style={{width: '30%'}}>Пользователь</th>
                                    <th style={{width: '30%'}}>Действие</th>
                                    <th style={{width: '15%'}}>Тип</th>
                                    <th style={{width: '20%'}}>Дата</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    history.map((item, index) => <>
                                        <ExtendableTableRow item={item}/>
                                    </>)
                                }
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            }
        </>
    )
}