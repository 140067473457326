import React, {useState} from "react";
import {useCard} from "../pages/PositionPage";
import {toast, ToastContainer} from "react-toastify";
import axios from "axios";
import {BACKEND_URL} from "../ConstConfig";
import '../css/PositionSplit.css'
import { ReactComponent as SpeedTruck } from '../assets/truck-speed-svgrepo-com.svg';
import { ReactComponent as Truck } from '../assets/truck-svgrepo-com.svg';
import { ReactComponent as Trash } from '../assets/trash-svgrepo-com.svg';
import { ReactComponent as Warning } from '../assets/warning-circle-svgrepo-com.svg';
import { ReactComponent as WarehouseGreen } from '../assets/warehouse-green-svgrepo-com.svg';
import { ReactComponent as WarehouseYellow } from '../assets/warehouse-yellow-svgrepo-com.svg';
import { ReactComponent as GrayTruck } from '../assets/truck-grey-svgrepo-com.svg';
import {NewCardStatus, PlaceDetails} from "./NewCard";

export function PositionSplit() {

    const [weight, setWeight] = useState<string>('')

    const {card} = useCard()

    const status = () => {
        if (card.status === 'В наличии') {
            return <WarehouseGreen style={{marginLeft: '4px'}}/>
        } else if (card.status === 'Резерв') {
            return <WarehouseYellow style={{marginLeft: '4px'}}/>
        } else if (card.status === 'В пути на склад') {
            return <SpeedTruck style={{marginLeft: '4px', marginTop: '3px'}}/>
        } else if (card.status === 'Подготовлено к отгрузке') {
            return <Truck style={{marginLeft: '4px', marginTop: '3px'}}/>
        } else if (card.status === 'Отгружено') {
            return <GrayTruck style={{marginLeft: '4px', marginTop: '3px'}}/>
        } else if (card.status === 'Брак') {
            return <Warning style={{marginLeft: '4px', marginTop: '2px'}}/>
        } else {
            return <Trash style={{marginLeft: '4px'}}/>
        }
    }

    const place = () => {
        if (card.storageLocation !== null) {
            return <PlaceDetails location={card.storageLocation}/>
        } else {
            return <></>
        }
    }

    async function splitPosition() {
        try {
            const response = await toast.promise(axios.post(BACKEND_URL + '/api/v1/position/' + card.id + '/split', {
                weightToSplit: Number(weight)
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }), {
                pending: 'Делим позицию..',
                success: 'Позиция успешно разделена',
                error: 'Не удалось разделить позицию'
            })
            if (response.status === 200) {
                window.open(`/position/${response.data.id}/info`, '_blank');
                window.location.reload()
            }
        } catch (e) {
            return
        }
    }

    return (
        <>
            {
                localStorage.getItem('actions')?.includes('SPLIT') &&
                <div className='dispatch-body'>
                    <label className='position-split-label' htmlFor="weight">Вес, который хотите отделить</label>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px'}}>
                        <div className="new-card">
                            <div className="main-card-body">
                                <span className='new-card-title'>{card.type} {status()}</span>
                                <span className='new-card-field'><strong>Марка:</strong> {card.mark}</span>
                                <span className='new-card-field'><strong>Диаметр:</strong> {card.diameter}</span>
                                <span className='new-card-field'><strong>Упаковка:</strong> {card.packing}</span>
                                <span className='new-card-field'><strong>Партия:</strong> {card.part}</span>
                                <span className='new-card-field'
                                      style={{color: Number(weight) < Number(card.weight) ? 'green' : 'crimson'}}><strong>Вес:</strong> {Number(card.weight) - Number(weight)} / {card.weight}</span>
                                <div className="card-details">
                                    <div className="center">
                                        <h2>{card.type} <NewCardStatus value={card.status} location={card.storageLocation} status={status()} place={place()}/></h2>
                                        <hr color='#beaa87'/>
                                        <span><strong>Плавка:</strong> {card.plav}</span>
                                        <span><strong>Склад:</strong> {card.location}</span>
                                        <span><strong>Комментарий:</strong> {card.comment ? card.comment : '-'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='transfer-arrow' style={{margin: '0 20px', fontSize: '26px'}}>
                            &#8594;
                        </div>
                        <div className="new-card" style={{borderStyle: 'dashed'}}>
                            <div className="main-card-body">
                                <span className='new-card-title'>{card.type} {status()}</span>
                                <span className='new-card-field'><strong>Марка:</strong> {card.mark}</span>
                                <span className='new-card-field'><strong>Диаметр:</strong> {card.diameter}</span>
                                <span className='new-card-field'><strong>Упаковка:</strong> {card.packing}</span>
                                <span className='new-card-field'><strong>Партия:</strong> {card.part}</span>
                                <span className='new-card-field'><strong>Вес:</strong> <input name='weight' id='weight' autoComplete='off'
                                                                                              className='position-split-input'
                                                                                              placeholder='Введите вес'
                                                                                              type="text" value={weight}
                                                                                              onChange={event => setWeight(event.target.value.replace(/[^.1234567890]+/g, ''))}/></span>
                            </div>
                        </div>
                    </div>
                    <button className='position-split-confirm-btn' type='button' style={{width: '160px', fontSize: '16px', marginTop: '20px'}}
                            disabled={Number(weight) >= Number(card.weight) || Number(weight) === 0 || isNaN(Number(weight))}
                            onClick={splitPosition}>Подтвердить
                    </button>
                    {/*<h3 style={{*/}
                    {/*    color: Number(weight) < Number(card.weight) ? 'green' : 'crimson',*/}
                    {/*    marginTop: '10px'*/}
                    {/*}}>{Number(card.weight) - Number(weight)} / {Number(card.weight)}</h3>*/}
                    <ToastContainer/>
                </div>
            }
        </>
    )
}