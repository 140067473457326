import {useEffect, useState} from "react";
import {IShippingHistory} from "../interfaces/exportedInterfaces";
import axios, {AxiosError} from "axios";
import {BACKEND_URL} from "../ConstConfig";

interface ShippingHistoryProps {
    pageNumber: number,
    from?: string,
    to?: string,
    customer?: string
}

export function useShippingHistory({pageNumber, from, to, customer}: ShippingHistoryProps) {

    const [error, setError] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [shippingHistory, setShippingHistory] = useState<IShippingHistory[]>([])
    const [totalPages, setTotalPages] = useState<number>(0)

    async function fetchHistory() {
        try {
            setLoading(true)
            const response = await axios.get(BACKEND_URL + '/api/v1/dispatch', {
                params: {
                    page: pageNumber,
                    from: from,
                    to: to,
                    customer: customer
                },
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            setShippingHistory(response.data.content)
            setTotalPages(response.data.totalPages)
            setLoading(false)
        } catch (e: unknown) {
            const error = e as AxiosError
            setError(error.message)
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchHistory()
    }, [pageNumber, from, to, customer])

    return {shippingHistory, error, loading, totalPages}
}