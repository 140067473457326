import {useEffect, useState} from "react";
import axios from "axios";
import {BACKEND_URL} from "../ConstConfig";
import {IPackingDictionary} from "../interfaces/exportedInterfaces";

export function usePackingsDictionary(refetchFlag: boolean) {

    const [packings, setPackings] = useState<IPackingDictionary[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>('')

    async function fetchPackings() {
        setLoading(true)
        setError('')
        try {
            const response = await axios.get(BACKEND_URL + '/api/v1/dictionary/packing', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            setPackings(response.data)
            setLoading(false)
        } catch (e) {
            setError('Не удалось получить список упаковок')
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchPackings()
    }, [refetchFlag])

    return {packings, loading, error}
}