import ReactDom from "react-dom";
import {ModalWindowProps} from "./ModalWindowAddStandard";
import React, {ChangeEvent, useState} from "react";
import {Bounce, toast, ToastContainer} from "react-toastify";
import axios from "axios";
import {BACKEND_URL} from "../ConstConfig";
import {IPositionDetails, ITruePositionDetails} from "../interfaces/exportedInterfaces";
import '../css/ModalWindowAddPlannedDispatch.css'
import {useRegisterAutocomplete} from "../hooks/useRegisterAutocomplete";
import '../css/DispatchPopup.css'

interface LocalModalWindowProps {
    openModal: (prop: boolean) => void,
    flag: boolean,
    setFlag: (prop: boolean) => void,
}

export function ModalWindowAddPlannedDispatch({openModal, flag, setFlag}: LocalModalWindowProps) {

    const portalElement: HTMLElement = document.getElementById('portal')!

    const {marks, packings, error} = useRegisterAutocomplete()


    const [bill, setBill] = useState<string>('')
    const [customer, setCustomer] = useState<string>('')
    const [location, setLocation] = useState<string>('')
    const [partOfDay, setPartOfDay] = useState<string>('')
    const [date, setDate] = useState<string>('')
    const [byCarrier, setByCarrier] = useState<boolean>(false)
    const [style, setStyle] = useState({display: 'none'})
    const [clicked, setClicked] = useState<boolean>(false)
    const [positionDetails, setPositionDetails] = useState<IPositionDetails[]>([{
        mark: '',
        diameter: '',
        packing: '',
        melt: '',
        batch: '',
        totalWeight: '',
        preparedWeight: '',
        from: '',
        to: ''
    }])
    const [ulFocus, setUlFocus] = useState<boolean>(false)
    const [markAutocomplete, setMarkAutocomplete] = useState<boolean[]>([false])

    async function sendRequest(event: { preventDefault: () => void; }) {
        event.preventDefault()
        try {
            let tempFlag: boolean = true
            positionDetails.forEach(pos => {
                if (pos.mark === '' || pos.diameter === '' || pos.packing === '' || pos.totalWeight === '' || pos.from === '' || pos.to === '') {
                    tempFlag = false
                }
            })

            if (!tempFlag && clicked) {
                throw new Error()
            }

            if ((partOfDay !== '' && date === '') || (partOfDay === '' && date !== '')) {
                throw new Error()
            }

            let temp: ITruePositionDetails[] = []
            positionDetails.forEach(pos => temp = [...temp, {mark: pos.mark, diameter: Number(pos.diameter), packing: pos.packing, melt: pos.melt ? pos.melt : null, batch: pos.batch ? pos.batch : null, totalWeight: Number(pos.totalWeight), preparedWeight: Number(pos.preparedWeight), tolerance: {from: Number(pos.from), to: Number(pos.to)}}])
            const response = await axios.post(BACKEND_URL + '/api/v1/planned-dispatch', {
                customer: customer.trim(),
                bill: bill.trim(),
                date: date === '' ? null : date.trim(),
                partOfDay: partOfDay === '' ? null : partOfDay.trim(),
                location: location.trim(),
                byCarrier: byCarrier,
                positionsDetails: clicked ? temp : []
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            if (response.status === 200 || 201) {
                toast.success('Отгрузка успешно добавлена!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                })
                setDate('')
                setBill('')
                setCustomer('')
                setPartOfDay('')
                setLocation('')
                setPositionDetails([{
                    mark: '',
                    diameter: '',
                    packing: '',
                    melt: '',
                    batch: '',
                    preparedWeight: '',
                    totalWeight: '',
                    from: '',
                    to: ''
                }])
                setFlag(!flag)
            }
        } catch (e: unknown) {
            toast.error('Не удалось добавить отгрузку', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
        }
    }

    function showAutocomplete(input: string, items: string[], inputIndex: number,
                              ulClass: string, liClass: string, emClass: string) {
        if (items.filter((item) => item.toLowerCase().includes(input.toLowerCase())).length) {
            return (
                <ul className={ulClass}>
                    {items.filter((item) => item.toLowerCase().includes(input.toLowerCase()))
                        .map((item, index) =>
                            <li className={liClass} key={index} onMouseOver={() => setUlFocus(true)}
                                onMouseOut={() => setUlFocus(false)}
                                onClick={event => {
                                    onChangeHandle(inputIndex, event.currentTarget.innerText)
                                    AutocompleteHandle(inputIndex, false)
                                    setUlFocus(false)
                                }}>{item}</li>
                        )}
                </ul>
            )
        } else {
            return (
                <div className={emClass}>
                    <em>Нет элементов</em>
                </div>
            )
        }
    }

    function addRow() {
        if (clicked) {
            let data = [...positionDetails, {
                mark: '',
                diameter: '',
                packing: '',
                melt: '',
                batch: '',
                preparedWeight: '',
                totalWeight: '',
                from: '',
                to: ''
            }]
            let temp = [...markAutocomplete]
            setMarkAutocomplete(temp)
            setPositionDetails(data)
        } else {
            setStyle({display: 'table-row'})
            setClicked(true)
        }

    }

    function removeRow(index: number) {
        let data: IPositionDetails[] = [...positionDetails]
        data.splice(index, 1)
        setPositionDetails(data)
    }

    function onChangeHandle(index: number, event: ChangeEvent<HTMLInputElement | HTMLSelectElement> | string, flag?: string) {
        let data: IPositionDetails[] = [...positionDetails]
        if (typeof event === 'string') {
            data[index]['mark' as keyof IPositionDetails] = event
        } else {
            switch (flag) {
                case 'number':
                    data[index][event.target.name as keyof IPositionDetails] = event.target.value.replace(/[^-.1234567890]+/g, '')
                    break
                case 'positiveNumber':
                    data[index][event.target.name as keyof IPositionDetails] = event.target.value.replace(/[^.1234567890]+/g, '')
                    break
                default:
                    data[index][event.target.name as keyof IPositionDetails] = event.target.value
            }
        }
        setPositionDetails(data)
    }

    function AutocompleteHandle(index: number, flag: boolean) {
        let temp: boolean[] = [...markAutocomplete]
        temp[index] = flag
        setMarkAutocomplete(temp)
    }

    return ReactDom.createPortal(
        <form className='modalWindow' onSubmit={sendRequest}>
            <div className='modalBackground' onClick={() => openModal(false)}/>
            <div className='modalContainerReserve' style={{width: '70%', height: '60%'}}>
                <div className='titleCloseBtn'>
                    <button type='button' id='closeBtn' onClick={() => openModal(false)}>X</button>
                </div>
                <div className='titleReserve'>
                    <h1>Добавление отгрузки</h1>
                    {
                        error && <h3 style={{color: 'crimson'}}>Автозаполнение марок и/или выбор упоковок недоступны</h3>
                    }
                </div>
                <div className='body'>
                    <div className='reserve-input-container'>
                        <div className='modalInputReserve'>
                            <label htmlFor="" className='reserve-label'>Покупатель</label>
                            <input type="text" value={customer} className='modal-window-add-user-input'
                                   onChange={event => setCustomer(event.target.value)} required/>
                        </div>
                        <div className='modalInputReserve'>
                            <label htmlFor="" className='reserve-label'>Счет</label>
                            <input type="text" value={bill} className='modal-window-add-user-input'
                                   onChange={event => setBill(event.target.value)} required/>
                        </div>
                    </div>
                    <div className='reserve-input-container'>
                        <div className='modalInputReserve'>
                            <select className='planner-select' name="location" id="location" value={location}
                                    onChange={(event) => setLocation(event.target.value)} required>
                                <option selected disabled value=''>Локация</option>
                                <option value="BelSklad">Белорецк</option>
                                <option value="Manufacture">Производство</option>
                                <option value="Solnechnogorsk">Солнечногорск</option>
                                <option value="Matrosovo">Матросово</option>
                            </select>
                        </div>
                        <div className='modalInputReserve'>
                            <select className='planner-select' name="partOfDay" id="partOfDay" value={partOfDay}
                                    onChange={(event) => setPartOfDay(event.target.value)}>
                                <option selected disabled value=''>Время суток</option>
                                <option value="Утро">Утро</option>
                                <option value="День">День</option>
                                <option value="Вечер">Вечер</option>
                            </select>
                        </div>
                        <div className='modalInputReserve'>
                            <input type="date" className='planner-date' value={date}
                                   min={new Date().toISOString().split('T')[0]}
                                   onChange={(event) => setDate(event.target.value)}/>
                        </div>
                    </div>
                    <div className='reserve-input-container'>
                        <div className='modalInputReserve'
                             style={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', padding: '5px 0'}}>
                            <span style={{fontSize: '20px'}}>Отправка транспортной компанией</span>
                            <div className='checkbox-wrapper-31'>
                                <input type="checkbox" checked={byCarrier} onChange={(event) => setByCarrier(event.target.checked)}/>
                                <svg viewBox='0 0 35.6 35.6'>
                                    <circle className='background' cx='17.8' cy='17.8' r='17.8'></circle>
                                    <circle className='stroke' cx='17.8' cy='17.8' r='14.37'></circle>
                                    <polyline className='check' points="11.78 18.12 15.55 22.23 25.17 12.87"></polyline>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className='reserve-input-container'>
                        <table className='dynamic-table' style={{width: '100%', marginTop: '10px'}}>
                            <thead>
                            <tr>
                                <th style={{width: '15%'}}>Марка</th>
                                <th style={{width: '10%'}}>Диаметр</th>
                                <th style={{width: '12.5%'}}>Упаковка</th>
                                <th style={{width: '12.5%'}}>Плавка*</th>
                                <th style={{width: '12.5%'}}>Партия*</th>
                                <th style={{width: '12.5%'}}>Общий вес</th>
                                <th style={{width: '15%'}}>Толеранс</th>
                                <th style={{width: '10%'}}>Действия</th>
                                </tr>
                            </thead>
                            <tbody>
                                {positionDetails.map((position, index) => <>
                                    <tr key={index} style={style}>
                                        <td className='dynamic-table-cell'>
                                            <input type="text" name='mark' value={position.mark} onChange={event => onChangeHandle(index, event)} onFocus={() => AutocompleteHandle(index, true)} onBlur={() => {
                                                if (ulFocus) {
                                                    return
                                                } else {
                                                    AutocompleteHandle(index, false)
                                                }
                                            }}/>
                                            {position.mark !== '' && markAutocomplete[index] && !error && showAutocomplete(position.mark, marks, index, 'suggestions-dispatch', 'suggestion-hoverable-reg', 'no-suggestions-reg')}
                                        </td>
                                        <td className='dynamic-table-cell'>
                                            <input type="text" name='diameter' value={position.diameter} onChange={event => onChangeHandle(index, event, 'positiveNumber')}/>
                                        </td>
                                        <td className='dynamic-table-cell'>
                                            <select name="packing" id="" value={position.packing} onChange={event => onChangeHandle(index, event)}>
                                                <option selected disabled value="">Выбрать..</option>
                                                {packings.map(pack => <option value={pack.name}>{pack.name}</option>)}
                                            </select>
                                            {/*<input type="text" name='packing' value={position.packing} onChange={event => onChangeHandle(index, event)}/>*/}
                                        </td>
                                        <td className='dynamic-table-cell'>
                                            <input type="text" name='melt' value={position.melt} onChange={event => onChangeHandle(index, event)}/>
                                        </td>
                                        <td className='dynamic-table-cell'>
                                            <input type="text" name='batch' value={position.batch} onChange={event => onChangeHandle(index, event)}/>
                                        </td>
                                        <td className='dynamic-table-cell'>
                                            <input type="text" name='totalWeight' value={position.totalWeight} onChange={event => onChangeHandle(index, event, 'positiveNumber')}/>
                                        </td>
                                        <td className='dynamic-table-cell-tolerance'>
                                            <input type="text" name='from' value={position.from} onChange={event => onChangeHandle(index, event, 'number')}/>
                                             -
                                            <input type="text" name='to' value={position.to} onChange={event => onChangeHandle(index, event, 'number')}/>
                                        </td>
                                        <td>
                                            <button type='button' className='remove-plav-field-btn' onClick={() => {
                                                if (positionDetails.length > 1) {
                                                    removeRow(index)
                                                } else {
                                                    setStyle({display: 'none'})
                                                    setClicked(false)
                                                }
                                            }}>X</button>
                                        </td>
                                    </tr>

                                </>)}
                            </tbody>
                        </table>
                    </div>
                    <div className='reserve-input-container' style={{marginTop: '20px', marginBottom: '10px'}}>
                        <button className='add-new-row-btn' type='button' onClick={addRow}>Добавить ряд</button>
                    </div>
                </div>
                <div className='footer' style={{flex: '0%'}}>
                    <button type='button' id='cancelBtn' onClick={() => openModal(false)}>Отменить</button>
                    <button type='submit' id='confirmBtn'>Добавить</button>
                </div>
            </div>
            <ToastContainer/>
        </form>, portalElement
    )
}
