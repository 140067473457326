import {Outlet, useLoaderData, useNavigate, useOutletContext} from "react-router-dom";
import icon from "../assets/logo_new_v2.png";
import {PositionNavigation} from "../components/PositionNavigation";
import '../css/CardIdView.css'
import React, {useEffect, useState} from "react";
import useToken from "../hooks/useToken";
import {closeNav, exitHandler, openNav} from "./SkladPage";
import axios from "axios";
import {BACKEND_URL} from "../ConstConfig";
import {IModelsCard, IModelsCardFull, IModelsCardPlus, IStoragePlace} from "../interfaces/exportedInterfaces";

interface PositionPageProps {
    card?: IModelsCard | IModelsCardPlus | IModelsCardFull,
    places?: IStoragePlace[]
}

// @ts-ignore
export async function loader({params}) {
    if (!localStorage.getItem('token')) {
        // const card = {mark: '', diameter: '', type: '', date: '', location: '', packing: '', part: '', plav: '', weight: '', status: '', manufacturer: '', comment: ''}
        return {}
    } else {
        let realPlaces: IStoragePlace[] = []
        const responseCard = await axios.get(BACKEND_URL + '/api/v1/search/' + params.id, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token")
            }
        })
        if (responseCard.status === 200 && responseCard.data.type !== 'Поддон') {
            const responsePlaces = await axios.get(BACKEND_URL + '/api/v1/storage-location/position/' + responseCard.data.id, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            realPlaces = responsePlaces.data
            try {
                const responsePlav = await axios.post(BACKEND_URL + '/api/v1/melt', {
                    name: responseCard.data.plav
                }, {
                    timeout: 2000,
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem("token")
                    }
                })
                try {
                    const responsePart = await axios.post(BACKEND_URL + '/api/v1/batch', {
                        name: responseCard.data.part
                    }, {
                        timeout: 2000,
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem("token")
                        }
                    })
                    const card: IModelsCardFull = {
                        id: responseCard.data.id,
                        mark: responseCard.data.mark,
                        diameter: responseCard.data.diameter,
                        packing: responseCard.data.packing,
                        date: responseCard.data.date,
                        comment: responseCard.data.comment,
                        part: responseCard.data.part,
                        plav: responseCard.data.plav,
                        manufacturer: responseCard.data.manufacturer,
                        weight: responseCard.data.weight,
                        status: responseCard.data.status,
                        location: responseCard.data.location,
                        type: responseCard.data.type,
                        standards: responseCard.data.standards,
                        createdFrom: responseCard.data.createdFrom,
                        pack: responseCard.data.pack,
                        positions: responseCard.data.positions,
                        storageLocation: responseCard.data.storageLocation,
                        chemicals: responsePlav.data.chemicals,
                        mechanicalProperties: responsePart.data.mechanicalProperties,
                    }
                    const places: IStoragePlace[] = responsePlaces.data
                    return {card, places}
                } catch (e) {}
                const card: IModelsCardPlus = {
                    id: responseCard.data.id,
                    mark: responseCard.data.mark,
                    diameter: responseCard.data.diameter,
                    packing: responseCard.data.packing,
                    date: responseCard.data.date,
                    comment: responseCard.data.comment,
                    part: responseCard.data.part,
                    plav: responseCard.data.plav,
                    manufacturer: responseCard.data.manufacturer,
                    weight: responseCard.data.weight,
                    status: responseCard.data.status,
                    location: responseCard.data.location,
                    type: responseCard.data.type,
                    standards: responseCard.data.standards,
                    createdFrom: responseCard.data.createdFrom,
                    pack: responseCard.data.pack,
                    positions: responseCard.data.positions,
                    storageLocation: responseCard.data.storageLocation,
                    chemicals: responsePlav.data.chemicals
                }
                const places: IStoragePlace[] = responsePlaces.data
                return {card, places}
            } catch (e) {}
        }
        const card: IModelsCard = {
            id: responseCard.data.id,
            mark: responseCard.data.mark,
            diameter: responseCard.data.diameter,
            packing: responseCard.data.packing,
            date: responseCard.data.date,
            comment: responseCard.data.comment,
            part: responseCard.data.part,
            plav: responseCard.data.plav,
            manufacturer: responseCard.data.manufacturer,
            weight: responseCard.data.weight,
            status: responseCard.data.status,
            location: responseCard.data.location,
            type: responseCard.data.type,
            standards: responseCard.data.standards,
            createdFrom: responseCard.data.createdFrom,
            pack: responseCard.data.pack,
            positions: responseCard.data.positions,
            storageLocation: responseCard.data.storageLocation
        }
        return {card, places: realPlaces}
    }
}

type ContextType = {card: IModelsCard | IModelsCardPlus | IModelsCardFull, places: IStoragePlace[]}

export function useCard() {
    return useOutletContext<ContextType>()
}

// @ts-ignore
export function PositionPage() {

    const {card, places} = useLoaderData() as PositionPageProps

    const {setToken} = useToken()
    const navigate = useNavigate()

    useEffect(() => {
        if (!localStorage.getItem('token') || Number(localStorage.getItem('expireTime')!) * 1000 < Date.now()) {
            exitHandler()
            navigate('/login')
        }
    })

    return (
        <div className='sklad-container'>
            <div className='container'>
                <div className='sidebar' id='mySidebar'>
                    <div>
                        <div className='a-container'>
                            <a href='javascript:void(0)' className="closebtn" onClick={() => {
                                closeNav()
                            }}>&#8592;</a>
                        </div>
                        <img className='image' src={icon} alt="Ферротрейд" onClick={() => {
                            navigate('/')
                        }}/>
                        <PositionNavigation/>
                    </div>
                    <div className='sidebar-buttons-container'>
                        <button className='btn-exit' style={{margin: '0 0 20px 0'}} onClick={() => {
                            setToken('')
                            exitHandler()
                        }}>
                            <svg className='svg-exit' fill="#fff" width="800px" height="800px" viewBox="0 0 24 24"
                                 id="sign-out-2"
                                 data-name="Flat Line" xmlns="http://www.w3.org/2000/svg">
                                <polyline id="primary" points="18 9 21 12 18 15"></polyline>
                                <line id="primary-2" data-name="primary" x1="21" y1="12" x2="7" y2="12"></line>
                                <path id="primary-3" data-name="primary"
                                      d="M14,16v3a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V5A1,1,0,0,1,4,4h9a1,1,0,0,1,1,1V8"></path>
                            </svg>
                        </button>
                    </div>
                </div>
                <div className='mainbar' id='myMainbar'>
                    <button className="openbtn" id='btnOpenNav' onClick={() => {
                        openNav()
                    }}>&#9776;</button>
                    <Outlet context={{card, places}}/>
                </div>
            </div>
        </div>
    )
}