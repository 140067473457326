import React, {useState} from "react";
import '../css/DispatchCalendarPage.css'
import {IPlannedDispatch} from "../interfaces/exportedInterfaces";
import {Link} from "react-router-dom";
import {ModalWindowAddPlannedDispatch} from "../components/ModalWindowAddPlannedDispatch";
import {useCalendar} from "../hooks/useCalendar";
import {Loader} from "../components/Loader";
import {CollapsibleDispatches} from "../components/CollapsibleDispatches";
import {CustomContextMenu} from "../components/CustomContextMenu";
import useContextMenu from "../hooks/useCustomContextMenu";

export const Aligner: React.FC<{ children: React.ReactNode }> = (props) => {
    return (
        <div className='device-status__aligner'>
            {props.children}
        </div>
    );
}

export const StatusAligner: React.FC<{ children: React.ReactNode }> = (props) => {
    return (
        <div className='device-status__aligner-status'>
            {props.children}
        </div>
    );
}

interface DispatchDetailsPopupProps {
    customer: string,
    bill: string,
    status: string,
    date: string
}

export function DispatchDetailsPopup({customer, bill, status, date}: DispatchDetailsPopupProps) {
    return (
        <div className='device-failed-checks__container'>
            <div className='device-failed-checks__label'><strong>Покупатель:</strong> {customer}</div>
            <div className='device-failed-checks__label'><strong>Счёт:</strong> {bill}</div>
            <div className='device-failed-checks__label'><strong>Статус:</strong> {status}</div>
            <div className='device-failed-checks__label'><strong>Дата:</strong> {date}</div>
        </div>
    );
}

interface DispatchDetailsProps {
    event: IPlannedDispatch,
    idx: number
}

export const backgroundColor = (status: string) => {
    if (status === 'В процессе') {
        return 'rgb(255,236,177)'
    } else if (status === 'Подготовлен') {
        return 'rgb(187,255,177)'
    } else if (status === 'Отгружен') {
        return 'rgb(213,213,213)'
    } else if (status === 'Отменен') {
        return 'rgb(255,177,177)'
    } else {
        return '#efefef'
    }
}

export function DispatchDetails({event, idx}: DispatchDetailsProps) {

    const [overlayVisible, setOverlayVisible] = useState<boolean>(false)

    return <>
        {
            localStorage.getItem('actions')?.includes('VIEW_PLANNED_DISPATCH') ?
                <Link target="_blank" to={`/planned-dispatch/${event.id}`} style={{textDecoration: 'none', color: 'black'}}>
                    <div style={{backgroundColor: backgroundColor(event.status)}} key={idx} className='planner-event' onMouseEnter={() => setOverlayVisible(true)} onMouseLeave={() => setOverlayVisible(false)}>
                        {event.customer}
                    </div>
                </Link>
                :
                <div key={idx} className='planner-event' onMouseEnter={() => setOverlayVisible(true)} onMouseLeave={() => setOverlayVisible(false)}>
                    {event.customer}
                </div>
        }
        {
            overlayVisible &&
            <Aligner>
                <DispatchDetailsPopup customer={event.customer} bill={event.bill} status={event.status} date={event.date}/>
            </Aligner>
        }
    </>
}

export function UnplannedDispatchDetails({event, idx}: DispatchDetailsProps) {
    return (
        <>
            {
                localStorage.getItem('actions')?.includes('VIEW_PLANNED_DISPATCH') ?
                    <Link target="_blank" to={`/planned-dispatch/${event.id}`} style={{textDecoration: 'none', color: 'black', height: 'fit-content'}}>
                        <div style={{backgroundColor: backgroundColor(event.status)}} key={idx}
                             className='planner-event'>
                            <span><strong>Покупатель: </strong>{event.customer}</span> <br/>
                            <span><strong>Счёт: </strong>{event.bill}</span> <br/>
                            <span><strong>Локация: </strong>{event.location}</span>
                        </div>
                    </Link>
                    :
                    <div key={idx} className='planner-event'>
                        <span><strong>Покупатель: </strong>{event.customer}</span> <br/>
                        <span><strong>Счёт: </strong>{event.bill}</span> <br/>
                        <span><strong>Локация: </strong>{event.location}</span>
                    </div>
            }
        </>
    )
}

export function DispatchCalendarPage() {

    const [currentDate, setCurrentDate] = useState<Date>(new Date())
    const [modal, setModal] = useState<boolean>(false)
    const [flag, setFlag] = useState<boolean>(false)
    const [isOpen, setIsOpen] = useState(false);
    const daysOfWeek = ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница']

    const [dateForCargo, setDateForCargo] = useState<string>('')

    const {dispatches, unplannedDispatches, error, loading} = useCalendar(currentDate, flag)
    const [ulFocus, setUlFocus] = useState<boolean>(false)

    const {showMenu, hideMenu, menuPosition, menuVisible} = useContextMenu()

    const startOfWeek = (): Date => {
        const date = new Date(currentDate)
        const day = date.getDay()
        const diff = date.getDate() - day + (day === 0 ? -6 : 1)
        return new Date(date.setDate(diff))
    }

    const getWeekDates = (): string[] => {
        const start = startOfWeek()
        return Array.from({length: 5}, (_, i) => {
            const date = new Date(start)
            date.setDate(start.getDate() + i)
            return date.toISOString().split('T')[0]
        })
    }

    const weekDates = getWeekDates()

    const handleNextWeek = (): void => {
        setCurrentDate(prevDate => {
            const nextWeek = new Date(prevDate)
            nextWeek.setDate(prevDate.getDate() + 7)
            return nextWeek
        })
    }

    const handlePreviousWeek = (): void => {
        setCurrentDate(prevDate => {
            const prevWeek = new Date(prevDate)
            prevWeek.setDate(prevDate.getDate() - 7)
            return prevWeek
        })
    }

    const toggleCollapse = () => {
        setIsOpen(prevState => !prevState);
    };

    return (
        <>
            {
                localStorage.getItem('actions')?.includes('DISPATCH_CALENDAR') &&
                <div className='planner-mainbar' style={{marginTop: '80px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    {
                        modal && <ModalWindowAddPlannedDispatch openModal={setModal} flag={flag} setFlag={setFlag}/>
                    }
                    <div className='cancel-changedate-container'>
                        <button type='button' onClick={() => {
                            handlePreviousWeek()
                        }}>Предыдущая неделя</button>
                        <button type='button' onClick={() => {
                            handleNextWeek()
                        }}>Следующая неделя</button>
                    </div>
                    {
                        error && <h2 style={{color: 'crimson'}}>{error}</h2>
                    }
                    {
                        loading && <Loader/>
                    }
                    {
                        !error && !loading &&
                        <table className='planner-table'>
                            <thead>
                            <tr>
                                <th className='table-dates'>Дата и время</th>
                                {
                                    weekDates.map((date, index) => (
                                        <th className={new Date(date).toLocaleDateString() === new Date().toLocaleDateString() ? 'current-day' : 'table-dates'} key={date}>{new Date(date).toLocaleDateString()} <br/>
                                            {daysOfWeek[index]}
                                        </th>
                                    ))
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {
                                Array.from(['Утро', 'День', 'Вечер'], (day, index) => (
                                    <tr className='planner-row' key={index}>
                                        <td className='time-interval'>{day}</td>
                                        {weekDates.map((date, index) => (
                                            <td
                                                className='event-cell'
                                                key={index}
                                                onMouseOver={() => {
                                                    if (!menuVisible) {
                                                        setDateForCargo(date)
                                                    }
                                                }}
                                            >
                                                <CollapsibleDispatches hideMenu={hideMenu} showMenu={showMenu} dispatches={dispatches} date={date} day={day} ulFocus={ulFocus} setUlFocus={setUlFocus}/>
                                                {dispatches.map((event, idx) => {
                                                    if (event.date === date && event.partOfDay === day && !event.byCarrier) {
                                                        return (
                                                            <>
                                                                <DispatchDetails event={event} idx={idx}/>
                                                            </>
                                                        )
                                                    } else {
                                                        return null
                                                    }
                                                })}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    }
                    {
                        !loading && !error &&
                        <div className='collapsible' style={{marginTop: '15px', width: '95%'}} onClick={toggleCollapse}>
                            <div className="collapsible-header" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <h3 style={{color: '#555', margin: '15px 0', fontSize: '18px'}}>Отгрузки без даты</h3>
                                <span id='arrow' style={{marginLeft: '8px', marginRight: '0', borderColor: '#555'}}
                                      className={isOpen ? 'arrow down up' : 'arrow down adown'}></span>
                            </div>

                            <div className={`collapsible-content ${isOpen ? 'open' : ''}`}>
                                <div className='new-card-container'>
                                    {unplannedDispatches.map((dispatch, index) => <>
                                        <UnplannedDispatchDetails event={dispatch} idx={index}/>
                                    </>)}
                                </div>
                            </div>
                        </div>
                    }
                    {
                        localStorage.getItem('actions')?.includes('ADD_PLANNED_DISPATCH') &&
                        <div className='cancel-changedate-container' style={{marginBottom: '15px', marginTop: '8px'}}>
                            <button type='button' className='save-btn' onClick={() => setModal(true)}>Запланировать отгрузку</button>
                        </div>
                    }
                    {
                        menuVisible && <CustomContextMenu position={menuPosition} hideMenu={hideMenu} setFlag={setUlFocus} mode={'CARGO'} date={dateForCargo}/>
                    }
                </div>
            }
        </>
    )
}