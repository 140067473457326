import '../css/SchemePage.css'
import React, {useState} from "react";
import {useScheme} from "../hooks/useScheme";
import {Loader} from "../components/Loader";
import {ModalWindowScheme} from "../components/ModalWindowScheme";

export function SchemePage() {

    const {loading, error, scheme} = useScheme()
    const [chosenType, setChosenType] = useState<string>('')
    const [chosenRow, setChosenRow] = useState<string>('')
    const [chosenFloor, setChosenFloor] = useState<string>('')

    const [modal, setModal] = useState(false)

    // const [rackView, setRackView] = useState<boolean>(false)

    return (
        <>
            {
                localStorage.getItem('actions')?.includes('STORAGE_LOCATION_VIEW') &&
                <div className='mainbar-container'>
                    {modal && <ModalWindowScheme scheme={scheme} openModal={setModal} chosenType={chosenType} chosenRow={chosenRow} chosenFloor={chosenFloor}/>}
                    {loading &&
                        <div style={{height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Loader/>
                        </div>
                    }
                    {error &&
                        <div style={{marginTop: '90px'}}>
                            <h2 style={{color: 'red'}}>{error}</h2>
                        </div>
                    }
                    {!loading && !error &&
                        <div className='main-rectangle'>
                            <div className='default-rack long-rack' onClick={() => {
                                setChosenFloor('1')
                                setChosenType('Стеллажи')
                                setModal(true)
                            }}>СТ1</div>
                            <div className='default-rack-afterimage long-rack'></div>
                            <div className='default-row small-row first-row' onClick={() => {
                                setChosenRow('1')
                                setChosenType('Мотки')
                                setModal(true)
                            }}>
                                <div className='row-number'>1</div>
                                <div className='subrow-container'></div>
                            </div>
                            <div className='default-row-afterimage small-row first-row'></div>
                            <div className='default-row small-row second-row' onClick={() => {
                                setChosenRow('2')
                                setChosenType('Мотки')
                                setModal(true)
                            }}>
                                <div className='row-number'>2</div>
                                <div className='subrow-container'></div>
                            </div>
                            <div className='default-row-afterimage small-row second-row'></div>
                            <div className='default-row small-row third-row' onClick={() => {
                                setChosenRow('3')
                                setChosenType('Мотки')
                                setModal(true)
                            }}>
                                <div className='row-number'>3</div>
                                <div className='subrow-container'></div>
                            </div>
                            <div className='default-row-afterimage small-row third-row'></div>
                            <div className='default-row big-row' onClick={() => {
                                setChosenRow('4')
                                setChosenType('Стойки')
                                setModal(true)
                            }}>
                                <div className='row-number'>4</div>
                                <div className='subrow-container'></div>
                            </div>
                            <div className='default-row-afterimage big-row'></div>
                            <div className='default-rack short-rack' onClick={() => {
                                setChosenFloor('2')
                                setChosenType('Стеллажи')
                                setModal(true)
                            }}>СТ2</div>
                            <div className='default-rack-afterimage short-rack'></div>
                        </div>
                    }
                </div>
            }
        </>
    )
}