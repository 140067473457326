import {useCard} from "../pages/PositionPage";
import React, {useRef, useState} from "react";
import {useReactToPrint} from "react-to-print";
import {PrintableCard} from "./PrintableCard";

export function PositionPartialPrint() {

    const {card} = useCard()

    let printRef = useRef(null)
    const smallRef = useRef(null)
    const bigRef = useRef(null)

    const printHandler = useReactToPrint({
        content: () => printRef.current
    })

    const noStyles = {
        borderRadius: '',
        boxShadow: ''
    }

    const selectedStyles = {
        borderRadius: '16px',
        boxShadow: '0 0 5px 5px #5b38a3'
    }

    const object = {
        mark: card.mark,
        standards: card.standards,
        diameter: card.diameter,
        packing: card.packing,
        part: card.part,
        plav: card.plav,
        weight: card.weight,
        id: card.id
    }

    const [stylesSmall, setStylesSmall] = useState(noStyles)
    const [stylesBig, setStylesBig] = useState(noStyles)
    const [clicked, setClicked] = useState(false)
    const [selectedClass, setSelectedClass] = useState('')

    const [customWeight, setCustomWeight] = useState<string>('')
    const [customQuantity, setCustomQuantity] = useState<string>('1')

    return (
        <>
            {
                localStorage.getItem('actions')?.includes('PRINT_CUSTOM_WEIGHT') &&
                <div className='printable-container'>
                    {
                        (((card.type === 'Позиция' || card.type === 'позиция') && !printRef.current) ||
                            ((card.type === 'Поддон' || card.type === 'поддон') && selectedClass === '')) &&
                        clicked && <h2 style={{color: 'red'}}>Выберите размер</h2>
                    }
                     <div>
                        <div className='printable'>
                            <div className='print-block margin-top'>
                                <div id='printChoiceSmall' style={stylesSmall} className='small-card' onClick={() => {
                                    printRef.current = smallRef.current
                                    setStylesSmall(selectedStyles)
                                    setStylesBig(noStyles)
                                }}>
                                    <PrintableCard ref={smallRef} object={object} classname='small' customWeight={customWeight}/>
                                </div>
                            </div>
                            <div className='print-block'>
                                <div id='printChoiceBig' style={stylesBig} className='big-card' onClick={() => {
                                    printRef.current = bigRef.current
                                    setStylesBig(selectedStyles)
                                    setStylesSmall(noStyles)
                                }}>
                                    <PrintableCard ref={bigRef} object={object} classname='big' customWeight={customWeight}/>
                                </div>
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '35px'}}>
                            <label htmlFor="custom-weight">Введите ваш вес</label>
                            <input className='partial-input' id='custom-weight' name='custom-weight' type="text" value={customWeight} onChange={event => setCustomWeight(event.target.value.replace(/[^.1234567890]+/g, ''))}/>
                        </div>
                        <div className='print-button-container'>
                            <button className='printButton' onClick={() => {
                                setClicked(true)
                                printHandler()
                            }}>Печать карточки
                            </button>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}