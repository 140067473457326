import ReactDom from "react-dom";
import {toast, ToastContainer} from "react-toastify";
import React, {ChangeEvent, useState} from "react";
import {IStandardIdInfo} from "../interfaces/exportedInterfaces";
import axios from "axios";
import {BACKEND_URL} from "../ConstConfig";
import {SelectFields} from "../pages/RegisterPage";

interface NewInputFieldsProps {
    name: string,
    type: string,
    min?: string,
    max?: string
}

interface MainInputFieldsProps {
    name: string,
    type: string,
    min?: number,
    max?: number
}

interface ThisModalWindowProps {
    selectedGrade: string,
    standard: IStandardIdInfo | string,
    openModal: (prop: boolean) => void
}

export function ModalWindowAddChemicalComposition({openModal, selectedGrade, standard}: ThisModalWindowProps) {

    const portalElement: HTMLElement = document.getElementById('portal')!

    const [inputFields, setInputFields] = useState<NewInputFieldsProps[]>([
        {name: '', type: '', min: '', max: ''}
    ])

    const options: SelectFields[] = [
        {value: 'RANGE', label: 'Диапазон'},
        {value: 'MIN', label: 'Не менее'},
        {value: 'MAX', label: 'Не более'},
        {value: 'MAIN', label: 'Основа'},
        {value: 'REST', label: 'Остаток'}
    ]

    const handleOnChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>, index: number) => {
        let data: NewInputFieldsProps[]  = [...inputFields]
        data[index][event.target.name as keyof NewInputFieldsProps] = event.target.value
        setInputFields(data)
    }

    const removeFields = (index: number) => {
        let data = [...inputFields]
        data.splice(index, 1)
        setInputFields(data)
    }

    const addFields = () => {
        let newField = {name: '', type: '', min: '', max: ''}
        setInputFields([...inputFields, newField])
    }

    async function sendRequest(event: { preventDefault: () => void; }) {
        event.preventDefault()
        if (typeof standard !== 'string') {
            try {
                let temp: MainInputFieldsProps[] = []
                inputFields.map(item => {
                    if (item.type === 'MIN') {
                        return temp = [...temp, {name: item.name, type: item.type, min: Number(item.min)}]
                    } else if (item.type === 'MAX') {
                        return temp = [...temp, {name: item.name, type: item.type, max: Number(item.max)}]
                    } else if (item.type === 'RANGE') {
                        return temp = [...temp, {name: item.name, type: item.type, min: Number(item.min), max: Number(item.max)}]
                    } else {
                        return temp = [...temp, {name: item.name, type: item.type}]
                    }
                })
                const response = await toast.promise(axios.put(BACKEND_URL + '/api/v1/grade-standard/chemical-composition', temp, {
                    params: {
                        gradeId: selectedGrade,
                        standardId: standard.id
                    },
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                }), {
                    pending: 'Добавляем состав..',
                    success: 'Состав успешно добавлен',
                    error: 'Не удалось добавить состав'
                })
                if (response.status === 200) {
                    window.location.reload()
                }
            } catch (e: unknown) {
                return
            }
        } else {
            return
        }
    }

    return ReactDom.createPortal(
        <form className='modalWindow' onSubmit={sendRequest}>
            <div className='modalBackground' id='bg' onClick={() => openModal(false)}/>
            <div className='modalContainer' id='container' style={{height: '400px'}}>
                <div className='titleCloseBtn'>
                    <button type='button' id='closeBtn' onClick={() => openModal(false)}>X</button>
                </div>
                <div className='title'>
                    <h1 style={{color: '#555', marginTop: '0'}}>Добавление хим. состава</h1>
                </div>
                <div className='body'>
                    {inputFields.map((field, index) => <div key={index} className='modalInputPlav'>
                        <input required name='name' type="text" placeholder='Элемент' style={{width: '90px'}} className='requirements-input new-design-input'
                               value={field.name} onChange={event => handleOnChange(event, index)}/>
                        <select required name="type" placeholder='Тип' value={field.type} style={{width: '120px', color: '#555'}} className='requirements-input new-design-select'
                                onChange={event => handleOnChange(event, index)}>
                            <option selected disabled value=''>Тип</option>
                            {options.map((option, newIndex) => <option value={option.value} key={newIndex}>{option.label}</option>)}
                        </select>
                        {
                            field.type === 'RANGE' &&
                            <>
                                <input required name='min' type="text" placeholder='От' style={{width: '120px'}} className='requirements-input new-design-input'
                                       value={field.min} onChange={event => handleOnChange(event, index)}/>
                                -
                                <input required name='max' type="text" placeholder='До' style={{width: '120px'}} className='requirements-input new-design-input'
                                       value={field.max} onChange={event => handleOnChange(event, index)}/>
                            </>
                        }
                        {
                            field.type === 'MIN' &&
                            <input required name='min' type="text" placeholder='Не менее' style={{width: '120px'}} className='requirements-input new-design-input'
                                   value={field.min} onChange={event => handleOnChange(event, index)}/>
                        }
                        {
                            field.type === 'MAX' &&
                            <input required name='max' type="text" placeholder='Не более' style={{width: '120px'}} className='requirements-input new-design-input'
                                   value={field.max} onChange={event => handleOnChange(event, index)}/>
                        }
                        <button type='button' className='remove-requirements-field-btn' style={{width: '34px', height: '34px'}} onClick={() => {
                            if (inputFields.length > 1) {
                                removeFields(index)
                            }
                        }}>X
                        </button>
                    </div>)}
                    <div className='cancel-changedate-container' style={{justifyContent: 'center'}}>
                        <button type='button' className='new-design-btn' onClick={addFields}>Добавить поле</button>
                    </div>

                </div>
                <div className='footer'>
                {/*<div className='cancel-changedate-container'>*/}
                    {/*    <button type='button' className='save-btn' style={{marginRight: '5px'}}*/}
                    {/*            onClick={addPositions}>Добавить*/}
                    {/*    </button>*/}
                    {/*    <button type='button' style={{marginLeft: '5px'}}*/}
                    {/*            onClick={() => setAddPositionsMode(false)}>Назад*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                    <div className='cancel-changedate-container'>
                        <button type='button' className='new-design-btn' style={{marginRight: '10px', width: '170px', fontSize: '20px', height: '40px'}} onClick={() => openModal(false)}>Отменить</button>
                        <button type='submit' className='save-btn new-design-btn' style={{marginLeft: '10px', width: '170px', fontSize: '20px', height: '40px'}}>Добавить</button>
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </form>,
        portalElement
    )
}