import {useEffect, useState} from "react";
import {IStoragePlace} from "../interfaces/exportedInterfaces";
import axios from "axios";
import {BACKEND_URL} from "../ConstConfig";

export function useScheme() {

    const [error, setError] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [scheme, setScheme] = useState<IStoragePlace[]>([])

    async function fetchScheme() {
        setError('')
        setLoading(true)
        try {
            const response = await axios.get(BACKEND_URL + '/api/v1/storage-location', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            setScheme(response.data)
            setLoading(false)
        } catch (e) {
            setLoading(false)
            setError('Не удалось загрузить карту хранения')
        }
    }

    useEffect(() => {
        fetchScheme()
    }, [])

    return {loading, error, scheme}
}