import React, {useState} from "react";
import {IGrade} from "../interfaces/exportedInterfaces";
import {toast} from "react-toastify";
import axios from "axios";
import {BACKEND_URL} from "../ConstConfig";
import {SelectFields} from "../pages/RegisterPage";
import Select from "react-select";
import makeAnimated from "react-select/animated";

interface MarksDirectoryTableRowProps {
    index: number;
    mark: IGrade,
    refetchFlag: boolean,
    setRefetchFlag: (prop: boolean) => void,
    markInput: string,
    setMarkInput: (prop: string) => void,
    standardInput: SelectFields[],
    setStandardInput: (prop: SelectFields[]) => void,
    setOverallFlag: (prop: boolean) => void,
    miniFlag: boolean,
    setChosenId: (prop: string) => void,
    standardsOptions: SelectFields[]
}

export function MarksDirectoryTableRow({index, mark, refetchFlag, setRefetchFlag, markInput, setMarkInput, standardInput, setStandardInput, setOverallFlag, setChosenId, standardsOptions}: MarksDirectoryTableRowProps): JSX.Element {

    const [flag, setFlag] = useState<boolean>(false);
    const [ulFocus, setUlFocus] = useState<boolean>(false)

    const animatedComponents = makeAnimated()

    async function deleteMark(id: number) {
        try {
            const response = await toast.promise(axios.delete(BACKEND_URL + '/api/v1/grades/' + id, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }), {
                pending: 'Удаление марки..',
                success: 'Марка удалена',
                error: 'Не удалось удалить марку'
            })
            if (response.status === 200 || response.status === 201 || response.status === 204) {
                setRefetchFlag(!refetchFlag)
            }
        } catch (e) {
            return
        }
    }

    return (
        <tr key={index}
        //     onClick={() => {
        //     setMarkInput(mark.grade)
        //     if (!ulFocus) {
        //         let temp: SelectFields[] = []
        //         mark.standards.map(std => temp = [...temp, {value: std, label: std}])
        //         setStandardInput(temp)
        //     }
        //     setOverallFlag(true)
        //     setFlag(true)
        //     setChosenId(String(mark.id))
        // }} tabIndex={0} onBlur={() => {
        //     if (!ulFocus) {
        //         setFlag(false)
        //     }
        // }}
        >
            <td>{mark.id}</td>
            <td>{mark.grade}</td>
            {/*{*/}
            {/*    flag && localStorage.getItem('actions')?.includes('EDIT_GRADES') ?*/}
            {/*        <td>*/}
            {/*            <input className='requirements-input' style={{width: '100%'}} type="text" value={markInput}*/}
            {/*                   onChange={(event) => setMarkInput(event.target.value)}*/}
            {/*                   onMouseOver={() => setUlFocus(true)} onMouseOut={() => setUlFocus(false)}/>*/}
            {/*        </td>*/}
            {/*        :*/}
            {/*        <td>{mark.grade}</td>*/}
            {/*}*/}
            {/*{*/}
            {/*    flag && localStorage.getItem('actions')?.includes('EDIT_GRADES') ?*/}
            {/*        <td>*/}
            {/*            <div onMouseOver={() => setUlFocus(true)} onMouseOut={() => setUlFocus(false)}>*/}
            {/*                <Select placeholder='Выберите ГОСТ' isMulti id='gost' value={standardInput} name='gost'*/}
            {/*                        className='basic-multi-select'*/}
            {/*                        classNamePrefix='select' options={standardsOptions} closeMenuOnSelect={false}*/}
            {/*                        components={animatedComponents} defaultValue={standardInput} required*/}
            {/*                        noOptionsMessage={() => 'Нет подходящих ГОСТов'}*/}
            {/*                        isClearable //@ts-ignore*/}
            {/*                        onChange={setStandardInput}/>*/}
            {/*            </div>*/}
            {/*        </td>*/}
            {/*        :*/}
            <td>{mark.standards.join(', ')}</td>
            {/*}*/}
            {
                localStorage.getItem('actions')?.includes('EDIT_GRADES') &&
                <td>
                    <button type='button' className='remove-requirements-field-btn'
                            onClick={() => deleteMark(mark.id)}>X
                    </button>
                </td>
            }
        </tr>
    )
}