import React, {useState} from "react";
import {IModelsCard, IModelsCardForPackage} from "../interfaces/exportedInterfaces";
import useToken from "../hooks/useToken";
import axios, {AxiosError} from "axios";
import {BACKEND_URL} from "../ConstConfig";
import {ErrorMessage} from "../components/ErrorMessage";
import {CardView} from "../components/CardView";
import {Card} from "../components/Card";
import {PositionPrint} from "../components/PositionPrint";
import {DefaultCardPrint} from "../components/DefaultCardPrint";
import {Link} from "react-router-dom";

export function InventorizationPage() {
    const [id, setId] = useState<string>('')
    const [error, setError] = useState('')
    const [requestError, setRequestError] = useState<string>('')
    const [card, setCard] = useState<IModelsCard[] | undefined>(undefined)
    const [checked, setChecked] = useState(false)
    const [newWeight, setNewWeight] = useState<string>()
    const [changedWeight, setChangedWeight] = useState<boolean>(false)
    const [newCard, setNewCard] = useState<IModelsCard>()
    const [successRequest, setSuccessRequest] = useState<string>('')
    const {token} = useToken()

    const submitHandler = async (event: { preventDefault: () => void; }) => {
        event.preventDefault()
        setRequestError('')
        setSuccessRequest('')
        setChangedWeight(false)
        const request = id.replace(/[^,1234567890]+/g, '')
            .split(',')
            .filter((v) => v.length !== 0)
        if (request.length > 0) {
            try {
                setError('')
                const response = await axios.post(BACKEND_URL + '/api/v1/search', {
                    positions: request
                }, {
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                setCard(response.data)
                setNewWeight(response.data[0].weight)
            } catch (e: unknown) {
                const error = e as AxiosError
                setError(error.message)
                setCard(undefined)
            }
        }
        if (checked) {
            setId(id.concat(','))
        } else {
            setId('')
        }
    }

    async function confirmWeights() {
        setRequestError('')
        setSuccessRequest('')
        setChangedWeight(false)
        if (card !== undefined) {
            try {
                const response = await axios.post(BACKEND_URL + '/api/v1/rebase', {
                    id: card[0].id,
                    weight: newWeight === card[0].weight ? null : Number(newWeight)
                }, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                })
                if (newWeight === card[0].weight && response.status === 200) {
                    setSuccessRequest('Запрос успешно выполнен')
                } else {
                    setSuccessRequest('Запрос успешно выполнен')
                    setNewCard({
                        mark: card[0].mark,
                        id: card[0].id,
                        diameter: card[0].diameter,
                        packing: card[0].packing,
                        date: card[0].date,
                        comment: card[0].comment,
                        part: card[0].part,
                        plav: card[0].plav,
                        manufacturer: card[0].manufacturer,
                        weight: newWeight!,
                        status: card[0].status,
                        location: card[0].location,
                        type: card[0].type,
                        standards: card[0].standards,
                        createdFrom: card[0].createdFrom,
                        pack: card[0].pack,
                        positions: card[0].positions,
                        storageLocation: card[0].storageLocation
                    })
                    setChangedWeight(true)
                }
            } catch (e) {
                const error = e as AxiosError
                setRequestError('При отправке запроса произошла непредвиденная ошибка')
            }
        }
    }

    return (
        <>
            {
                localStorage.getItem('actions')?.includes('REBASE') &&
                <div className='card-id-confirm-scan'>
                    <div className='input-block'>
                        <form onSubmit={submitHandler}>
                            <input type="text" style={{fontSize: '16px'}} placeholder='Введите ID' value={id}
                                   onChange={event => setId(event.target.value.replace(/[^,1234567890]+/g, ''))} />
                            <button type='submit' style={{fontSize: '16px'}}>Найти</button>
                        </form>
                        {error && <ErrorMessage error={error}/>}
                    </div>
                    {card !== undefined && card[0].location !== 'Солнечногорск' && <h2 style={{color: 'rgb(253, 185, 0)'}}>Внимание: по базе эта позиция находится не в Солнечногорске</h2>}
                    {requestError && <h2 style={{color: 'crimson'}}>{requestError}</h2>}
                    {successRequest && <h2 style={{color: 'green'}}>{successRequest}</h2>}
                    {!error && card !== undefined &&
                        <div className='card-item'>
                                <p className="card-item__title">{card[0].type}</p>
                                <p className="card-item__text"><span>ID:</span> {card[0].id}</p>
                                <p className="card-item__text"><span>Марка:</span> {card[0].mark}</p>
                                <p className="card-item__text"><span>Диаметр:</span> {card[0].diameter}</p>
                                <p className="card-item__text"><span>Упаковка:</span> {card[0].packing}</p>
                                <p className="card-item__text"><span>Партия:</span> {card[0].part}</p>
                                <p className="card-item__text"><span>Плавка:</span> {card[0].plav}</p>
                                <p className="card-item__text"><span>Вес:</span> <input type="text" value={newWeight} onChange={event => setNewWeight(event.target.value.replace(/[^.1234567890]+/g, ''))}/></p>
                                {card[0].comment && <p className="card-item__text"><span>Комментарий:</span> {card[0].comment}</p>}
                                <p className="card-item__text"><span>Статус:</span> {card[0].status}</p>
                                <p className="card-item__text"><span>Склад:</span> {card[0].location}</p>
                        </div>
                    }
                    {!error && card !== undefined && card[0].status === 'Отгружено' && <h2 style={{color: 'crimson'}}>Позиция уже отгружена</h2>}
                    {!error && card !== undefined && card[0].status !== 'Отгружено' &&
                        <div style={{marginTop: '20px'}}>
                            <Link target="_blank" to={'/position/' + card[0].id + '/info'}>
                                <button type='button' className='price-admin-btn' style={{marginBottom: '20px'}} onClick={confirmWeights}>Подтвердить вес</button>
                            </Link>
                        </div>
                    }
                    {changedWeight && <div style={{marginBottom: '50px'}}>
                        <DefaultCardPrint card={newCard!}/>
                    </div>}
                </div>
            }
        </>
    )
}